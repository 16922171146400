import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";
import { ToastContainer, toast } from 'react-toastify';


export const getAssetStatuses = createAsyncThunk('getAssetStatuses', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}asset-statuses`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnStatus = createAsyncThunk('getAnStatus', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}asset-statuses/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateAssetStatus = createAsyncThunk('updateAssetStatus', async (arg, {
    rejectWithValue
}) => {
    console.log('data',)

    try {
        const { data } = await axios.put(`${baseUrl}asset-statuses/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`,
                
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const deleteAssetsStatus = createAsyncThunk('deleteAssetsStatus ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}asset-statuses/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const assetsStatusSlice = createSlice({
    name: 'AssetStatuses',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {

        builder.addCase(getAssetStatuses.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getAssetStatuses.fulfilled, (state, action) => {
            state.loading = false;
            state.value = action.payload.data;
        });
        builder.addCase(getAssetStatuses.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            
        });
        


       
        builder.addCase(updateAssetStatus.pending, (state, action) => {
            toast.loading('Updating Asset Status');
            state.loading = true;
        });
        builder.addCase(updateAssetStatus.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Asset Status Sucessfully');
            state.loading = false;
         });
         builder.addCase(updateAssetStatus.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to Update Asset Status');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(deleteAssetsStatus.pending, (state, action) => {
            toast.loading('Deleting Asset Status');
            state.loading = true;
        });
        builder.addCase(deleteAssetsStatus.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Asset Status Sucessfully');
            state.loading = false;
         });
         builder.addCase(deleteAssetsStatus.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to Delete Asset Status');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
         

        
    },
});


export default assetsStatusSlice;


 