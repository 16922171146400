
import React, { Component, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import bsCustomFileInput from "bs-custom-file-input";
import { addMachine } from "../Store/Features/Machine";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getAnMachine, updateMachine } from "../Store/Features/Machine";
import { getProductType } from "../Store/Features/ProductType/ProductType";
import { Box } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TimezoneSelect from 'react-timezone-select'
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from "@mui/material/CircularProgress";

import { getManufacturer } from "../Store/Features/Manufacture";
import { useSelector } from 'react-redux';
import { getMachineModel } from "../Store/Features/Machinemodel";
import { getMachinecurrency } from "../Store/Features/Machinecurrency";
import { getMachineEstablishment } from "../Store/Features/Machine-Establishments";
import { getMachineSector } from "../Store/Features/MachineSector";
import { getMachinePaymentMethod } from "../Store/Features/MachinePaymentMethod";
import { getMachineCommisionperiod } from "../Store/Features/MachineCommisionPeriod";
import { getMachinePercentageOrFixed } from "../Store/Features/MachinePercentageOrFixed";


export const AddMachine = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [apiCall_Loader, setapiCall_Loader] = useState(false);
  const [productType, setProductType] = useState({ value: "", error: "" });
  const [manufacturer, setManufacturer] = useState({ value: "", error: "" });
  const [machineModel, setMachineModel] = useState({ value: "", error: "" });
  const [noOfMotors, setNoOfMotors] = useState({ value: "", error: "" });
  const [SerialNumber, setSerialNumber] = useState({ value: "", error: "" });
  const [ProvisionalSerialNumber, setProvisionalSerialNumber] = useState({ value: "", error: "" });
  const [Icc, setIcc] = useState({ value: "", error: "" });
  const [MachineCode, setMachineCode] = useState({ value: "", error: "" });
  const [YearOfManufacturing, setYearOfManufacturing] = useState({ value: "", error: "" });
  const [Npvr, setNpvr] = useState({ value: "", error: "" });
  const [LicenseExpirationDate, setLicenseExpirationDate] = useState({ value: "", error: "" });
  const [MachineCurrency, setMachineCurrency] = useState({ value: "", error: "" });
  const [TimeZone, setTimeZone] = useState({ value: "", error: "" });
  const [Establishment, setEstablishment] = useState({ value: "", error: "" });
  const [AssignTheMachineToAGmbosClient, setAssignTheMachineToAGmbosClient] = useState({ value: "", error: "" });
  const [MachineManagement, setMachineManagement] = useState({ value: "", error: "" });
  const [Sector, setSector] = useState({ value: "", error: "" });
  const [MappingCodeForExternalBillingSystems, setMappingCodeForExternalBillingSystems] = useState({ value: "", error: "" });
  const [Distributor, setDistributor] = useState({ value: "", error: "" });
  const [PaymentMethod, setPaymentMethod] = useState({ value: "", error: "" });
  const [AssociateTheMachineWithOneOfMyClients, setAssociateTheMachineWithOneOfMyClients] = useState({ value: "", error: "" });
  const [CommisionSettlementPeriod, setCommisionSettlementPeriod] = useState({ value: "", error: "" });
  const [FirstCommissionSettlementDate, setFirstCommissionSettlementDate] = useState({ value: "", error: "" });
  const [PercentageOrFixedValue, setPercentageOrFixedValue] = useState({ value: "", error: "" });
  const [CommissionSettlementQuantityPerUnit, setCommissionSettlementQuantityPerUnit] = useState({ value: "", error: "" });
  const [AutomaticCommissionSettlements, setAutomaticCommissionSettlements] = useState({ value: "", error: "" });
  const [activeStep, setActiveStep] = useState(1);
  const [error, setError] = useState("")
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [licsenceExpiryDate, setLicsenceExpiryDate] = useState(dayjs(''));
  // const [firstcommissionsettlementDate, setFirstcommissionsettlementDate] = useState(dayjs(' '));
  const [firstcommissionsettlementDate, setFirstcommissionsettlementDate] = useState({ value: "", error: "" });
  
  // const [selectedTimezone, setSelectedTimezone] =useState(
  //   Intl.DateTimeFormat().resolvedOptions().timeZone,
  // )
  const [selectedTimezone, setSelectedTimezone] = useState('Select time Zone');
   
   



  const ProductType = useSelector(state => state.ProductTypeSlice.value);
  const Manufacture = useSelector(state => state.manufacturer.value);
  const Machine_Model = useSelector(state => state.machinemodel.value);
  const Machine_Currency = useSelector(state => state.machinecurrency.value);
  const Machine_Establishment = useSelector(state => state.machineEstablishments.value);
  const Machine_Sector = useSelector(state => state.machineSectors.value);
  const Machine_PaymentMethod = useSelector(state => state.MachinePaymentMethod.value);
  const Machine_CommisionPeriod = useSelector(state => state.MachineCommisionperiod.value);
  const Machine_PercentageorFixed = useSelector(state => state.MachinePercentageOrFixed.value);
  const [steps] = useState([
    { id: 1, name: "MODEL OF THE MACHINE" },
    { id: 2, name: "TECHNICAL DATA OF THE MACHINE" },
    { id: 3, name: "LOCATION OF THE MACHINE" },
    { id: 4, name: "CUSTOMER AND INVOICE DATA" },
  ]);

  useEffect(() => {
    dispatch(getProductType());
    dispatch(getManufacturer());
    dispatch(getMachineModel());
    dispatch(getMachinecurrency());
    dispatch(getMachineEstablishment());
    dispatch(getMachineSector());
    dispatch(getMachinePaymentMethod());
    dispatch(getMachineCommisionperiod());
    dispatch(getMachinePercentageOrFixed());
  }, []);
  useEffect(() => {
    if(!slug) {
      
      setIsNextDisabled(true)
      console.log("ghjghg")
    }
    
  }, [activeStep])


  useEffect(() => {
    if (activeStep === 1) {
      if (productType.value.length > 0 && manufacturer.value.length > 0 && machineModel.value.length > 0 && noOfMotors.value.length > 0) {
        
        setIsNextDisabled(false)
      } else {
        
      }
    }

  }, [productType, manufacturer, machineModel, noOfMotors])
  useEffect(() => {
    

    if (activeStep === 2) {
      
      if (SerialNumber.value.length > 0 && ProvisionalSerialNumber.value.length > 0 && Icc.value.length > 0 && MachineCode.value.length > 0 && YearOfManufacturing.value.length > 0 && Npvr.value.length > 0 && (LicenseExpirationDate.value.length > 0 || LicenseExpirationDate.value.hasOwnProperty('$D')) && MachineCurrency.value.length > 0) {
        console.log("check",SerialNumber.value.length > 0 && ProvisionalSerialNumber.value.length > 0 && Icc.value.length > 0 && MachineCode.value.length > 0 && YearOfManufacturing.value.length > 0 && Npvr.value.length > 0 && (LicenseExpirationDate.value.length > 0 || LicenseExpirationDate.value.hasOwnProperty('$D')) && MachineCurrency.value.length > 0)
        setIsNextDisabled(false)
      } else {
        console.log("check1",MachineCurrency.value.length > 0 )
        setIsNextDisabled(true)
      }
    }


  }, [SerialNumber, ProvisionalSerialNumber, Icc, MachineCode, YearOfManufacturing, Npvr, LicenseExpirationDate, MachineCurrency])

  useEffect(()=>{
    console.log('SerialNumber',typeof SerialNumber.value)
  },[SerialNumber])

  useEffect(() => {
    if (activeStep === 3) {
      if (TimeZone.value.length > 0 && Establishment.value.length > 0) {
        setIsNextDisabled(false)
      } else {
        setIsNextDisabled(true)
      }
    }
  }, [TimeZone, Establishment])

  useEffect(() => {

    if(activeStep === 4){
      
      if (AssignTheMachineToAGmbosClient.value.length > 0 && MachineManagement.value.length > 0 && Sector.value.length > 0 && MappingCodeForExternalBillingSystems.value.length > 0 && Distributor.value.length > 0 && PaymentMethod.value.length > 0
        && AssociateTheMachineWithOneOfMyClients.value.length > 0 && CommisionSettlementPeriod.value.length > 0 && (FirstCommissionSettlementDate.value.length > 0 || FirstCommissionSettlementDate.value.hasOwnProperty('$D'))  && PercentageOrFixedValue.value.length > 0
        && CommissionSettlementQuantityPerUnit.value.length > 0 && AutomaticCommissionSettlements.value.length > 0) {
        setIsNextDisabled(false)
      } else {
       
        setIsNextDisabled(true)
      }
    }
   

  }, [AssignTheMachineToAGmbosClient, MachineManagement, Sector, MappingCodeForExternalBillingSystems, Distributor, PaymentMethod, AssociateTheMachineWithOneOfMyClients, CommisionSettlementPeriod,
    FirstCommissionSettlementDate, PercentageOrFixedValue, CommissionSettlementQuantityPerUnit, AutomaticCommissionSettlements])

  

  const checkForm = () => {
    
    if (activeStep === 1) {
      if (productType.value.length == 0 || manufacturer.value.length == 0 || machineModel.value.length == 0 || noOfMotors.value.length == 0) {
        if (productType.value.length == 0) {
          setProductType({ productType, error: "Please Enter Product Type" })

        }
        if (manufacturer.value.length == 0) {
          setManufacturer({ manufacturer, error: "Please Enter Manufacturer" })
        }
        if (machineModel.value.length == 0) {
          setMachineModel({ machineModel, error: "Please Enter Machine Model" })
        }
        if (noOfMotors.value.length == 0) {
          setNoOfMotors({ noOfMotors, error: "Please Enter the number of motors" })
        }
        setError("Please fill the form")
        return true
      }
    }
    return false
  }
  
  useEffect(()=>{
    console.log("IsNextDisabled",isNextDisabled)
  },[isNextDisabled])
  const technicalCheck = () => {
    if (activeStep === 2) {
      if (SerialNumber.length == 0 || ProvisionalSerialNumber.length == 0 || Icc.length == 0 || MachineCode.length == 0 || YearOfManufacturing.length == 0 || Npvr.length == 0 || (LicenseExpirationDate.value.length === 0 || !LicenseExpirationDate.value.hasOwnProperty('$D' || (LicenseExpirationDate.value.length === 0 || !LicenseExpirationDate.value)) )|| MachineCurrency.length == 0) {
        setError("Please fill the form")
        setIsNextDisabled(true)
        return true
      }
    }
    setIsNextDisabled(false)
    return false
  }

  const locationCheck = () => {
    if (activeStep === 3) {
      if (TimeZone.length == 0 || Establishment.length == 0) {
        setError("Please fill the form")
        setIsNextDisabled(true)
        return true
      }
    }
    setIsNextDisabled(false)
    return false

  }
  const invoiceCheck = () => {
    if (activeStep === 4) {
      if (AssignTheMachineToAGmbosClient.length == 0 || MachineManagement.length == 0 || Sector.length == 0 || MappingCodeForExternalBillingSystems.length == 0 || Distributor.length == 0 || PaymentMethod.length == 0 ||
        AssociateTheMachineWithOneOfMyClients.length == 0 || CommisionSettlementPeriod.length == 0 || FirstCommissionSettlementDate.length == 0 || PercentageOrFixedValue.length == 0
        || CommissionSettlementQuantityPerUnit.length == 0 || AutomaticCommissionSettlements.length == 0) {
        setError("Please fill the form")
        setIsNextDisabled(true)
        return true
      }
    }
    setIsNextDisabled(false)
    return false
  }
 

  useEffect(() => {
  
    if (slug) {
      setapiCall_Loader(true);
      console.log('inside update....')
      dispatch(getAnMachine(slug)).then((data) => {
        console.log('update api data',data)
        setProductType({...productType,value: data.payload.data.product_type_id});
        setNoOfMotors({...noOfMotors, value: data.payload.data.no_of_motors});
        setSerialNumber({...SerialNumber,value:data.payload.data.serial_number});
        setProvisionalSerialNumber({...ProvisionalSerialNumber,value:data.payload.data.provisional_serial_number});
        setIcc({...Icc,value:data.payload.data.icc});
        setMachineCode({...MachineCode,value:data.payload.data.machine_code});
        setYearOfManufacturing({...YearOfManufacturing,value:data.payload.data.year_of_manufacturing});
        setNpvr({...Npvr,value:data.payload.data.npvr});
        // for just displaying licence date picker value
        console.log('licence expiry data in different formate.........',dayjs(data.payload.data.license_expiration_date))
        setLicenseExpirationDate({...LicenseExpirationDate,value:dayjs(data.payload.data.license_expiration_date)});
        setTimeZone({...TimeZone,value:data.payload.data.time_zone});
        setAssignTheMachineToAGmbosClient(
          {...AssignTheMachineToAGmbosClient,value:data.payload.data.assign_the_machine_to_a_gmbos_client}
        );
        setMachineManagement({...MachineManagement,value:data.payload.data.machine_management});
        setMappingCodeForExternalBillingSystems(
          {...MappingCodeForExternalBillingSystems,value:data.payload.data.mapping_code_for_external_billing_systems}
        );
        setAssociateTheMachineWithOneOfMyClients(
          {...AssociateTheMachineWithOneOfMyClients,value: data.payload.data.associate_the_machine_with_one_of_my_clients}
        );
        setFirstCommissionSettlementDate(
          {...FirstCommissionSettlementDate,value:dayjs(data.payload.data.first_commission_settlement_date)}
        );
        setCommissionSettlementQuantityPerUnit(
          {...CommissionSettlementQuantityPerUnit,value:data.payload.data.commission_settlement_quantity_per_unit}
        );
        setAutomaticCommissionSettlements(
          {...AutomaticCommissionSettlements,value:data.payload.data.automatic_commission_settlements}
        );
        console.log('manufacturer date in updatre.........',data.payload.data.manufacturer_id)
        setManufacturer({...manufacturer,value:data.payload.data.manufacturer_id});
        setMachineModel({...machineModel,value:data.payload.data.machine_model_id});
        setMachineCurrency({...MachineCurrency,value:data.payload.data.machine_currency_id});
        setEstablishment({...Establishment,value:data.payload.data.establishment_id});
        setSector({...Sector,value:data.payload.data.sector_id});
        setDistributor({...Distributor,value:data.payload.data.distributor_id});
        setPaymentMethod({...PaymentMethod,value:data.payload.data.payment_method_id});
        setCommisionSettlementPeriod(
          {...CommisionSettlementPeriod,value:data.payload.data.commision_settlement_period_id}
        );
        setPercentageOrFixedValue(
          {...PercentageOrFixedValue,value:data.payload.data.percentage_or_fixed_value_id}
        );
        setapiCall_Loader(false);
      });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
   
      var date = new Date(LicenseExpirationDate.value)
      var licenceExpiryDate= date.getFullYear() + '-' + getMonth(date) + '-' + getDay(date)

      var date = new Date(FirstCommissionSettlementDate.value)
      var firstcommissionSettlementdate= date.getFullYear() + '-' + getMonth(date) + '-' + getDay(date)
      
     const data = {
      product_type_id: productType.value,
      no_of_motors: noOfMotors.value,
      serial_number: SerialNumber.value,
      provisional_serial_number: ProvisionalSerialNumber.value,
      icc: Icc.value,
      machine_code: MachineCode.value,
      year_of_manufacturing: YearOfManufacturing.value,
      npvr: Npvr.value,
      license_expiration_date: licenceExpiryDate,
      time_zone: TimeZone.value,
      assign_the_machine_to_a_gmbos_client: AssignTheMachineToAGmbosClient.value,
      machine_management: MachineManagement.value,
      mapping_code_for_external_billing_systems:
        MappingCodeForExternalBillingSystems.value,
      associate_the_machine_with_one_of_my_clients:
        AssociateTheMachineWithOneOfMyClients.value,
      first_commission_settlement_date: firstcommissionSettlementdate,
      commission_settlement_quantity_per_unit:
        CommissionSettlementQuantityPerUnit.value,
      automatic_commission_settlements: AutomaticCommissionSettlements.value,
      manufacturer_id: manufacturer.value,
      machine_model_id: machineModel.value,
      machine_currency_id: MachineCurrency.value,
      establishment_id: Establishment.value,
      sector_id: Sector.value,
      distributor_id: Distributor.value,
      payment_method_id: PaymentMethod.value,
      commision_settlement_period_id: CommisionSettlementPeriod.value,
      percentage_or_fixed_value_id: PercentageOrFixedValue.value,
    };
    if (slug) {
      console.log('here is data obj in update',data)
      dispatch(updateMachine({ slug, data })).then(() => {
        navigate("/admin/machine");
      });
    } else {
      dispatch(addMachine(data)).then(() => {
        navigate("/admin/machine");
      });
    }
  };

  const handleNextStep = () => {
    
    setError("")
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };


  const handleChange = (e) => {
    
    switch (e.target.name) {
      case 'productType':
        setProductType({ ...productType, value: e.target.value });
        break;
      case 'manufacturer':
        console.log("e",e.target.value)
        setManufacturer({ ...manufacturer, value: e.target.value });
        break;
      case 'machineModel':
        setMachineModel({ ...machineModel, value: e.target.value });
        break;
      case 'noOfMotors':
        setNoOfMotors({ ...noOfMotors, value: e.target.value });
        break;
      case 'SerialNumber':

        setSerialNumber({ ...SerialNumber, value: e.target.value });
        break;
      case 'ProvisionalSerialNumber':
        setProvisionalSerialNumber({ ...ProvisionalSerialNumber, value: e.target.value });
        break;
      case 'Icc':
        setIcc({ ...Icc, value: e.target.value });
        break;
      case 'MachineCode':
        setMachineCode({ ...MachineCode, value: e.target.value });
        break;
      case 'YearOfManufacturing':
        setYearOfManufacturing({ ...YearOfManufacturing, value: e.target.value });
        break;
      case 'Npvr':
        setNpvr({ ...Npvr, value: e.target.value });
        break;
      case 'LicenseExpirationDate':
        setLicenseExpirationDate({ ...LicenseExpirationDate, value: e.target.value });
        break;
      case 'MachineCurrency':
        setMachineCurrency({ ...MachineCurrency, value: e.target.value });
        break;
      case 'TimeZone':
        setTimeZone({ ...TimeZone, value: e.target.value });
        break;
      case 'Establishment':
        setEstablishment({ ...Establishment, value: e.target.value });
        break;
      case 'AssignTheMachineToAGmbosClient':
        setAssignTheMachineToAGmbosClient({ ...AssignTheMachineToAGmbosClient, value: e.target.value });
        break;
      case 'MachineManagement':
        setMachineManagement({ ...MachineManagement, value: e.target.value });
        break;
      case 'Sector':
        setSector({ ...Sector, value: e.target.value });
        break;
      case 'MappingCodeForExtrnalBillingSystems':
        console.log("Mapping Code For External Billing Systems",e.target.value)
        setMappingCodeForExternalBillingSystems({ ...MappingCodeForExternalBillingSystems, value: e.target.value });
        break;
      case 'Distributor':
        console.log("e.t",e.target.value)
        setDistributor({ ...Distributor, value: e.target.value });
        break;
      case 'PaymentMethod':
        console.log("e.t",e.target.value)
        setPaymentMethod({ ...PaymentMethod, value: e.target.value });
        break;
      case 'AssociateTheMachineWithOneOfMyClients':
        setAssociateTheMachineWithOneOfMyClients({ ...AssociateTheMachineWithOneOfMyClients, value: e.target.value });
        break;
      case 'CommisionSettlementPeriod':
        setCommisionSettlementPeriod({ ...CommisionSettlementPeriod, value: e.target.value });
        break;
      case 'FirstCommissionSettlementDate':
        setFirstCommissionSettlementDate({ ...FirstCommissionSettlementDate, value: e.target.value });
        break;
      case 'PercentageOrFixedValue':
        setPercentageOrFixedValue({ ...PercentageOrFixedValue, value: e.target.value });
        break;
      case 'CommissionSettlementQuantityPerUnit':
        setCommissionSettlementQuantityPerUnit({ ...CommissionSettlementQuantityPerUnit, value: e.target.value });
        break;
      case 'AutomaticCommissionSettlements':
        setAutomaticCommissionSettlements({ ...AutomaticCommissionSettlements, value: e.target.value });
        break;
      default:
        break;
    }
  };

 
useEffect(()=>{

},[])
  // const handleChangeDate = (e) => {
  //   console.log(e)
  //   var date = new Date(e)
    
  //   var licenceDate= date.getFullYear() + '-' + getMonth(date) + '-' + getDay(date)
  //   console.log(licenceDate)
  //   setLicenseExpirationDate({...LicenseExpirationDate,value: licenceDate})
  // }
  const getMonth =(date) =>{
    if((date.getMonth() + 1) >= 10) {
      return (date.getMonth() + 1)
    } else {
      return "0" + (date.getMonth()+1)
    }

  }
  const getDay =(date) =>{
    if(date.getDate() >= 10) {
      return date.getDate()
    } else {
      return "0" + date.getDate()
    }

  }

  const handleFirstCommitionDate = (e) => {
    var date = new Date(e)
    var firstCommisionDate= date.getFullYear() + '-' + getMonth(date) + '-' + getDay(date)
    console.log('firstCommisionDate',firstCommisionDate)
    setFirstCommissionSettlementDate({...FirstCommissionSettlementDate,value: firstCommisionDate})
  }   
  const handleTimezoneChange = (e) => {
    var timezone=e
    console.log('Time Zone.....',timezone)
    setSelectedTimezone(timezone)
    setTimeZone({...TimeZone,value: timezone.value})
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Machine</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine</a></li>
            <li className="breadcrumb-item active" aria-current="page">Add Machine</li>
          </ol>
        </nav>
      </div>
     
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "rgba(224, 224, 224, 1)",
          borderRadius: "5px",
        }}
      >
        <div style={{ display: "flex", gap: "170px", flexWrap: "wrap", backgroundColor: "#f2edf3", padding: "10px", justifyContent: "center", marginBottom: "10px", }}>
          {steps.map((step) => (
            <div
              key={step.id}
              style={{
                fontWeight: step.id === activeStep ? "bold" : "normal",
                textDecoration: step.id === activeStep ? "none" : "none",
                marginRight: "10px",
              }}
            >
              {step.name}
            </div>
          ))}
        </div>
        <div style={{ backgroundColor: "white", padding: "20px", borderStyle: "solid", borderWidth: "1px", borderColor: "rgba(224, 224, 224, 1)", borderRadius: "5px", }}>

          {activeStep === 1 && (
            <div>
              <Box sx={{ display: 'flex', gap: '10px', width: '100%' }}>
                <Form.Group style={{ width: '100%' }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">Product Type</label>
                    <Tooltip title="Type of Product" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <select value={productType.value} onChange={handleChange} name="productType" className="form-control">
                    <option value={0} >Select the Following Option</option>
                    {ProductType?.map(item => (
                      <option value={item.id}>{item.title}</option>))}
                  </select>

                </Form.Group>
                <Form.Group style={{ width: '100%' }}>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <label htmlFor="exampleInputEmail1">Manufacturer</label>
                      <Tooltip title="Selete the brand of the machine" arrow placement="top">
                        <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                      </Tooltip>
                    </div>
                    <select value={manufacturer.value} onChange={handleChange} name="manufacturer" className="form-control">
                      <option value={0}>Select the Following Option</option>
                      {Manufacture?.map(item => (
                      <option value={item.id}>{item.title}</option>
                      ))}
                    </select>
                  </div>

                </Form.Group>
              </Box>
              
              <Form.Group style={{ width: '100%' }}>
                  <div>
                  {apiCall_Loader ? (
                    <Box sx={{ height: "20px",alignContent:'center' }} >
                    <CircularProgress />
                      </Box >
                      ) : (null)
                   }
                  </div>

                </Form.Group>
              <Box sx={{ display: 'flex', gap: '10px', width: '100%' }}>
                <Form.Group style={{ width: '100%' }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">Machine Model</label>
                    <Tooltip title="Manufacturer Machine Model" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <select value={machineModel.value} onChange={handleChange} name="machineModel" className="form-control">
                    <option value={0}>Select the Following Option</option>
                    {Machine_Model?.map(item => (
                      <option value={item.id}>{item.title}</option>
                    ))
                    }
                  </select>

                </Form.Group>
                <Form.Group style={{ width: '100%' }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">No. Of Motors</label>
                    <Tooltip title="The total no. of motor machine have" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="No. Of Motors"
                    name="noOfMotors"
                    value={noOfMotors.value}
                    onChange={handleChange}
                    style={{ borderColor: noOfMotors.value.length == 0 && "red" }}

                  />
                </Form.Group>
              </Box>
            </div>
          )}
          {/* Step 2 */}
          {activeStep === 2 && (
            <div>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">Serial Number</label>
                    <Tooltip title="Write the Serial Number Without Space" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="Serial Number"
                    name="SerialNumber"
                    value={SerialNumber.value}
                    onChange={handleChange}
                    style={{ borderColor: SerialNumber.value.length == 0 && "red" }}
                  />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">
                      Provisional Serial Number
                    </label>
                    <Tooltip title="Write the Provisional Serial Number Without Space" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="ProvisionalSerialNumber"
                    placeholder="Provisional Serial Number"
                    value={ProvisionalSerialNumber.value}
                    onChange={handleChange}
                    style={{ borderColor: ProvisionalSerialNumber.value.length == 0 && "red" }}
                  />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">Icc</label>
                    <Tooltip title="It is the number of SIM Card" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="Icc"
                    name="Icc"
                    value={Icc.value}
                    onChange={handleChange}
                    style={{ borderColor: Icc.value.length == 0 && "red" }}
                  />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">Machine Code</label>
                    <Tooltip title="Not Necessary, Only if you want to control with your own machine numbering" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="MachineCode"
                    placeholder="Machine Code"
                    value={MachineCode.value}
                    onChange={handleChange}
                    style={{ borderColor: MachineCode.value.length == 0 && "red" }}
                  />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">
                      Year Of Manufacturing
                    </label>
                    <Tooltip title="Not Required, It allow to have control of the aging of your machine" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                  type="text"
                  id="exampleInputUsername1"
                  name="YearOfManufacturing"
                  placeholder="Year Of Manufacturing"
                  value={YearOfManufacturing.value}
                  onChange={handleChange}
                  style={{ borderColor: YearOfManufacturing.value.length == 0 && "red" }}
                />
                  
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">Npvr</label>
                    <Tooltip title="Point of the sale number, appear on the licence end and start with 020 (Spain)" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Currency Icon"
                    name="Npvr"
                    value={Npvr.value}
                    onChange={handleChange}
                    style={{ borderColor: Npvr.value.length == 0 && "red" }}
                  />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">
                      License Expiration Date
                    </label>
                    <Tooltip title="Date on the point sale permit expire" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  {/* <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    name="LicenseExpirationDate"
                    placeholder="License Expiration Date 2023-12-31"
                    value={LicenseExpirationDate.value}
                    onChange={handleChange}
                    style={{ borderColor: LicenseExpirationDate.value.length == 0 && "red" }}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker
                      className="form-control"
                      sx={{ height: '47' }}
                      name="LicenseExpirationDate"
                      format="YYYY-MM-DD"
                      
                      value={LicenseExpirationDate.value}
                      // value={'{YYYY} MM-DDTHH:mm:ss SSS [Z] A'}
                       onChange={(newValue) =>  setLicenseExpirationDate({ ...LicenseExpirationDate, value: newValue })}
                      style={{ borderColor: LicenseExpirationDate.value.length == 0 && "red" }}
                    />
                  </LocalizationProvider>
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">Machine Currency</label>
                    <Tooltip title="It is machine currency you will use" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <select value={MachineCurrency.value} onChange={handleChange} name="MachineCurrency" className="form-control">
                    <option >Select the Following Option</option>
                    {Machine_Currency?.map(item => (
                      <option value={item.id}>{item.currency_name}</option>
                    ))
                    }

                  </select>

                </Form.Group>
              </Box>
            </div>
          )}
          {/* Step 3 */}
          {activeStep === 3 && (
            <div>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">Time Zone</label>
                    <Tooltip title="You will use your time zone" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  {/* <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="Time Zone 08:30:00"
                    name="TimeZone"
                    value={TimeZone.value}
                    onChange={handleChange}
                    style={{ borderColor: TimeZone.value.length == 0 && "red" }}
                  /> */}
                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={handleTimezoneChange}
                   />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">Establishment</label>
                    <Tooltip title="Name of the establishment where the machine is located" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <select value={Establishment.value} onChange={handleChange} name="Establishment" className="form-control">
                    <option>Select the Following Option</option>
                    {Machine_Establishment?.map(item => (
                      <option value={item.id}>{item.title}</option>
                    ))
                    }

                  </select>

                </Form.Group>
              </Box>
            </div>
          )}
          {/* Step 4 */}
          {activeStep === 4 && (
            <div>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">
                      Assign The Machine To A Gmbos Client
                    </label>
                    <Tooltip title="Indicate which client can access the machine in addition to the current user" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    name="AssignTheMachineToAGmbosClient"
                    placeholder="Assign The Machine To A Gmbos Client"
                    value={AssignTheMachineToAGmbosClient.value}
                    onChange={handleChange}
                    style={{ borderColor: AssignTheMachineToAGmbosClient.value.length == 0 && "red" }}
                  />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">
                      Machine Management
                    </label>
                    <Tooltip title="Indicate Either it is direct management or any other" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="MachineManagement"
                    placeholder="Machine Management"
                    value={MachineManagement.value}
                    onChange={handleChange}
                    style={{ borderColor: MachineManagement.value.length == 0 && "red" }}
                  />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">Sector</label>
                    <Tooltip title="Indicate the type of the business of the establishement" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <select value={Sector.value} onChange={handleChange} name="Sector" className="form-control">
                    <option >Select the Following Option</option>
                    {Machine_Sector?.map(item => (
                      <option value={item.id}>{item.sector_name}</option>
                    ))
                    }

                  </select>

                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">
                      Mapping Code For External Billing Systems
                    </label>
                    <Tooltip title="To finalize the linking process GMBOSS user who are linked to stratro management program must enter here VMID" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="MappingCodeForExtrnalBillingSystems"
                    placeholder="Mapping Code For External Billing Systems"
                    value={MappingCodeForExternalBillingSystems.value}
                    onChange={handleChange}
                    style={{ borderColor: MappingCodeForExternalBillingSystems.value.length == 0 && "red" }}
                  />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">Distributor</label>
                    <Tooltip title="For third party billing system, indicate in this who is distributor supply of the product" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <select value={Distributor.value} onChange={handleChange} name="Distributor" className="form-control">
                    <option >Select the Following Option</option>
                    {Machine_Sector?.map(item => (
                      <option value={item.id}>{item.sector_name}</option>
                    ))
                    }
                  </select>
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">Payment Method</label>
                    <Tooltip title="I payment method used for the cutomers" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <select alue={PaymentMethod.value} onChange={handleChange} name="PaymentMethod" className="form-control">
                    <option >Select the Following Option</option>
                    {Machine_PaymentMethod?.map(item => (
                      <option value={item.id}>{item.payment_method_name}</option>
                    ))
                    }

                  </select>

                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">
                      Associate The Machine With One Of My Clients
                    </label>
                    <Tooltip title="Tax Clients (old machine tax date)" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="Associate The Machine With One Of My Clients"
                    name="AssociateTheMachineWithOneOfMyClients"
                    value={AssociateTheMachineWithOneOfMyClients.value}
                    onChange={handleChange}
                    style={{ borderColor: AssociateTheMachineWithOneOfMyClients.value.length == 0 && "red" }}
                  />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">
                      Commision Settlement Period
                    </label>
                    <Tooltip title="Commision Settlement Period" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <select value={CommisionSettlementPeriod.value} onChange={handleChange} name="CommisionSettlementPeriod" className="form-control">
                    <option >Select the Following Option</option>
                    {Machine_CommisionPeriod?.map(item => (
                      <option value={item.id}>{item.period_name}</option>
                    ))
                    }
                  </select>
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">
                      First Commission Settlement Date
                    </label>
                    <Tooltip title="Indicate when the first settlement date will take place" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  {/* <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    name="FirstCommissionSettlementDate"
                    placeholder="First Commission Settlement Date 2023-01-15"
                    value={FirstCommissionSettlementDate.value}
                    onChange={handleChange}
                    style={{ borderColor: FirstCommissionSettlementDate.value.length == 0 && "red" }}
                  /> */}
                   <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker
                      className="form-control"
                      sx={{ height: '47' }}
                      name="LicenseExpirationDate"
                      format="YYYY-MM-DD"
                      
                      value={FirstCommissionSettlementDate.value}
                      onChange={(newValue) =>  setFirstCommissionSettlementDate({ ...FirstCommissionSettlementDate, value: newValue })}
                      // onChange={(newValue) => handleFirstCommitionDate(newValue)}
                      
                      style={{ borderColor: LicenseExpirationDate.value.length == 0 && "red" }}
                    />
                  </LocalizationProvider>
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">
                      Percentage Or Fixed Value
                    </label>
                    <Tooltip title="Indicate if you a percentage or fixed vlaue" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <select value={PercentageOrFixedValue.value} onChange={handleChange} name="PercentageOrFixedValue" className="form-control">
                    <option value={""} >Select the Following Option</option>
                    {Machine_PercentageorFixed?.map(item => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    }
                  </select>
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputUsername1">
                      Commission Settlement Quantity Per Unit
                    </label>
                    <Tooltip title="Indicate Commission Settlement Quantity Per Unit" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    name="CommissionSettlementQuantityPerUnit"
                    placeholder="Commission Settlement Quantity Per Unit"
                    value={CommissionSettlementQuantityPerUnit.value}
                    onChange={handleChange}
                    style={{ borderColor: CommissionSettlementQuantityPerUnit.value.length == 0 && "red" }}
                  />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <label htmlFor="exampleInputEmail1">
                      Automatic Commission Settlements
                    </label>
                    <Tooltip title="Check this option, if you want to generate commission settlement automatically" arrow placement="top">
                      <HelpRoundedIcon sx={{ color: "#337AB6", fontSize: "20px" }} />
                    </Tooltip>
                  </div>
                  <Form.Control
                    type="email"
                    className="form-control"
                    name="AutomaticCommissionSettlements"
                    id="exampleInputEmail1"
                    placeholder="Automatic Commission Settlements"
                    value={AutomaticCommissionSettlements.value}
                    onChange={handleChange}
                    style={{ borderColor: AutomaticCommissionSettlements.value.length == 0 && "red" }}
                  />
                </Form.Group>
              </Box>
            </div>
          )}
          {/* Next and Previous buttons */}
          {activeStep > 1 && <button className="btn btn-gradient-secondary " style={{ border: 'none', color: 'white', padding: '10px 20px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '16px', paddingLeft: '30px', paddingRight: '30px', margin: '4px 2px', cursor: 'pointer' }} onClick={handlePreviousStep}>Previous</button>}
          {activeStep < 4 ? (
            <button className="btn btn-gradient-success " disabled={isNextDisabled} style={{ border: 'none', color: 'white', padding: '10px 20px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '16px', paddingLeft: '80px', paddingRight: '80px', margin: '4px 2px', cursor: 'pointer' }} onClick={handleNextStep}>Next</button>
          ) : (
            <button className="btn btn btn-gradient-primary " disabled={isNextDisabled} style={{ border: 'none', color: 'white', padding: '10px 20px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '16px', paddingLeft: '80px', paddingRight: '80px', margin: '4px 2px', cursor: 'pointer' }} onClick={onSubmit}>Submit</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddMachine;
