
import React,{useState,useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { getMachineDistributor, deleteMachineDistributor } from '../Store/Features/MachineDistributor';


function CustomToolbar() {
    return (
      <GridToolbarContainer>
         <h4 className="card-title" style = {{margin: 20}}>All Machine Distributor </h4>
      </GridToolbarContainer>
    );
  }

  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
export function MachineDistributor() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleView = (row) => {
    console.log('View:', row);
    // Implement view functionality here
  };

  const handleEdit = (row) => {
    navigate(`/admin/machine/distributor/${row.id}`)
    console.log('Edit:', row);
    // Implement edit functionality here
  };

  const handleDelete = (row) => {
    dispatch(deleteMachineDistributor(row.id)).then(() => {
      dispatch(getMachineDistributor())
    });
    console.log('Delete:', row);
    // Implement delete functionality here
  };

  const machineDistributors = useSelector(state => state.machineDistributors.value)
 useEffect(()=>{
  dispatch(getMachineDistributor())
 },[])
 const columns = [
    
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'distributor_name', headerName: 'Distributor Name',flex: 1 },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1 ,
      renderCell: (params) => (
        <div>
          {/* <button style={buttonStyle} onClick={() => handleView(params.row)}>View</button> */}
          <button style={buttonStyle1} onClick={() => handleEdit(params.row)}>Edit</button>
          <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
        </div>
      ),
    },
  ];

  return (
    
    <div style={{ height: 400, width: '100%' }}>
        <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> Machine Distributor  </h3>

          <button type="button" class="btn btn-gradient-success " onClick = {()=>navigate("/admin/machine/distributor/add")}>Add Machine Distributor</button>
        </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine Distributor</a></li>
              <li className="breadcrumb-item active" aria-current="page">All Machine Distributor </li>
            </ol>
          </nav>
        </div>
            <DataGrid
                rows={machineDistributors}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                sx={{ backgroundColor: "white" }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                slots={{
                    toolbar: CustomToolbar,
                  }}
            />
        </div>
  );
}

