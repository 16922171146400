import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";
import { ToastContainer, toast } from 'react-toastify';


export const getOrders = createAsyncThunk('getOrders', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}orders`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addOrder = createAsyncThunk('addOrders', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}orders`, arg, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnOrder = createAsyncThunk('getAnOrder', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}orders/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateOrder = createAsyncThunk('updateOrder', async (arg, {
    rejectWithValue
}) => {
    console.log('data',)

    try {
        const { data } = await axios.put(`${baseUrl}orders/${arg.slug}`, arg.formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`,
                
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const deleteOrder = createAsyncThunk('deleteOrder ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}orders/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
// export const uploadImage = createAsyncThunk('UploadImage', async (arg, {
//     rejectWithValue
// }) => {

//     try {
//         const { data } = await axios.post(`${baseUrl}new-products/media`, arg ,{
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("auth")}`
            
//             }
//         });

//         return data;
//     }
//     catch (error) {

//         return rejectWithValue(error.response.data.message);

//     }
// });

export const orderSlice = createSlice({
    name: 'Order',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {

        builder.addCase(getOrders.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getOrders.fulfilled, (state, action) => {
            state.loading = false;
            state.value = action.payload.data;
        });
        builder.addCase(getOrders.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            
        });
        


       
        builder.addCase(addOrder.pending, (state, action) => {
            toast.loading('Creating order');
            state.loading = true;
        });
        builder.addCase(addOrder.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Order Sucessfully');
            state.loading = false;
         });
         builder.addCase(addOrder.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to add Order');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(deleteOrder.pending, (state, action) => {
            toast.loading('Deleting order');
            state.loading = true;
        });
        builder.addCase(deleteOrder.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Order Sucessfully');
            state.loading = false;
         });
         builder.addCase(deleteOrder.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to Delete Order');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
         

        
    },
});


export default orderSlice;


 