import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";
import { ToastContainer, toast } from 'react-toastify';


export const getAssets = createAsyncThunk('getAssets', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}get-all-assets`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnAsset = createAsyncThunk('getAnAsset', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}get-asset/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

 

 
 

export const publicAssetsSlice = createSlice({
    name: 'Assets',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {

        builder.addCase(getAssets.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getAssets.fulfilled, (state, action) => {
            state.loading = false;
            state.value = action.payload.data;
        });
        builder.addCase(getAssets.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        
    },
});


export default publicAssetsSlice;


 