import React, { Component,useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import {addMachinePercentageOrFixed} from "../Store/Features/MachinePercentageOrFixed"
import {useDispatch} from "react-redux"
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { getAnMachinePercentageOrFixed,updateMachinePercentageOrFixed } from '../Store/Features/MachinePercentageOrFixed';

export const AddMachinePercentageOrFixed =()=>{
  const dispatch =useDispatch()
  const {slug} = useParams()
  const navigate = useNavigate()
//   state = {

//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
const [MachinePercentageOrFixed,setMachinePercentageOrFixed] = useState("")
useEffect(()=>{
  if(slug) {
    dispatch(getAnMachinePercentageOrFixed(slug)).then((data)=>{
      setMachinePercentageOrFixed(data.payload.data.name)
    })
  }

},[])

const onSubmit = (e) =>{
  e.preventDefault()
 
  const data = {name:  MachinePercentageOrFixed}
  if(slug) {
    dispatch(updateMachinePercentageOrFixed({slug,data})).then(()=>{
      navigate("/admin/machine/percentageorfixed")
    })
  }else {
    dispatch(addMachinePercentageOrFixed(data)).then(()=>{
      navigate("/admin/machine/percentageorfixed")
    })
  }  
}  
 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Machine Percentage Or Fixed</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine Percentage Or Fixed</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Machine Percentage Or Fixed</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add</h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Name</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Name" value = {MachinePercentageOrFixed} onChange = {e=>setMachinePercentageOrFixed(e.target.value)} />
                  </Form.Group>
                  
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>navigate("/admin/machine/percentageorfixed")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}