import React, { Component } from 'react';
import './App.scss';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { AllRoutes } from './Routes/routes'
import { ThemeProvider, createTheme } from '@mui/material/styles';

import 'react-toastify/dist/ReactToastify.css';
const theme = createTheme({
  palette: {
    primary: {
      main: '#1e88e5',
    },
    secondary: {
      main: '#f50057',
    },
  },
});
function App() {

 
   
    return (
      <ThemeProvider theme={theme}>
         <AllRoutes />
       
      </ThemeProvider>
    );
  

}

export default App;
