import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../Configuration/config";

import { toast } from 'react-toastify';
export const getMachineEstablishment = createAsyncThunk('getMachineEstablishment', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-establishments`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addMachineEstablishment = createAsyncThunk('addMachineEstablishment', async (arg, {
    rejectWithValue
}) => {
    console.log('addMachineModal', arg);
    try {
        const { data } = await axios.post(`${baseUrl}machine-establishments`,arg,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const getAnMachineEstablishment = createAsyncThunk('getAnMachineEstablishment', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-establishments/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateMachineEstablishment = createAsyncThunk('updateMachineEstablishment', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.put(`${baseUrl}machine-establishments/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const deleteMachineEstablishment = createAsyncThunk('deleteMachineEstablishment ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}machine-establishments/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const machineEstablishmentsSlice = createSlice({
    name: 'machineEstablishments',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getMachineEstablishment.pending, (state, action) => {
            //   toast.loading('Logging in');
            state.loading = true;
        });
        builder.addCase(getMachineEstablishment.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Logged In');
            state.loading = false;
            state.value = action.payload.data;



        });
        builder.addCase(getMachineEstablishment.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Promise Not Resolved');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(addMachineEstablishment.pending, (state, action) => {
            //   toast.loading('Logging in');
            state.loading = true;
        });
        builder.addCase(addMachineEstablishment.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Logged In');
            state.loading = false;
            



        });
        builder.addCase(addMachineEstablishment.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Promise Not Resolved');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(updateMachineEstablishment.pending, (state, action) => {
            toast.loading('Updating Machine Establishment');
            state.loading = true;
        });
        builder.addCase(updateMachineEstablishment.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Machine Establishment');
            state.loading = false;




        });
        builder.addCase(updateMachineEstablishment.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to update Machine Establishment');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(deleteMachineEstablishment.pending, (state, action) => {
            toast.loading('Deleting Machine Establishment');
            state.loading = true;
        });
        builder.addCase(deleteMachineEstablishment.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Machine Establishment');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(deleteMachineEstablishment.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to delete Machine Establishment');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        // builder.addCase(logout.pending, (state, action) => {
        //   toast.loading('Logging Out...');
        //   state.loading = true;
        // });
        // builder.addCase(logout.fulfilled, (state, action) => {
        //   toast.dismiss();

        //   state.loading = false;

        //   state.loading = false;
        //   if (action.payload.message) {
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   }

        //   localStorage.removeItem("auth");
        //   localStorage.removeItem("role");
        //   toast.success('Logged out');

        // });
        // builder.addCase(logout.rejected, (state, action) => {
        //   toast.dismiss();
        //   toast.error('Promise rejected');
        //   if (action.payload == "Network Error") {
        //     localStorage.removeItem("auth");
        //     localStorage.removeItem("role");

        //     state.loading = false;
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   } else {
        //     state.loading = false;
        //     state.isSuccess = true;
        //     state.isAuthenticated = true;
        //   }

        // });
    },
});


export default machineEstablishmentsSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };