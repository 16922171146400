
import React,{useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import { getProductType , addProductType , deleteProductType} from "../Store/Features/ProductType/ProductType";


function CustomToolbar() {
    return (
      <GridToolbarContainer>
         <h4 className="card-title" style = {{margin: 20}}>All Product Strator Code </h4>
      </GridToolbarContainer>
    );
  }

export function ProductType() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const ProductType = useSelector(state => state.ProductTypeSlice.value);
  const Loading = useSelector(state => state.ProductTypeSlice.loading);
  const handleView = (row) => {
    console.log('View:', row);
    // Implement view functionality here
  };
  useEffect(()=>{
    dispatch(getProductType())
  },[])

  const handleEdit = (row) => {
    navigate(`/admin/product/product-type/edit/${row.id}`)
  };

  const handleDelete = (row) => {
    dispatch(deleteProductType(row.id)).then(()=>{
      dispatch(getProductType())
    })
  };
  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
 
 const columns = [
    
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'title', headerName: 'Title',flex: 1 },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1 ,
      renderCell: (params) => (
        <div>
          {/* <button style={buttonStyle} onClick={() => handleView(params.row)}>View</button> */}
          <button style={buttonStyle1} onClick={() => handleEdit(params.row)}>Edit</button>
          <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
        </div>
      ),
    },
  ];

  return (
    
    <div style={{ height: 400, width: '100%' }}>
        <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> Product Type  </h3>
          <button type="button" class="btn btn-gradient-success" onClick = {()=>navigate("/admin/product/product-type/add")}> Add Product-Type</button>
        </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Product</a></li>
              <li className="breadcrumb-item active" aria-current="page">Product Type</li>
            </ol>
          </nav>
        </div>
            <DataGrid
                rows={ProductType}
                columns={columns}
                loading={Loading}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                sx={{ backgroundColor: "white" }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                slots={{
                    toolbar: CustomToolbar,
                  }}
            />
        </div>
  );
}