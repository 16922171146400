import React, { Component,useState,useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import {useDispatch} from 'react-redux'
import { addMachineSector } from '../Store/Features/MachineSector';
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { getAnMachineSector,updateMachineSector } from '../Store/Features/MachineSector'; 

export const AddMachineSector =()=>{
//   state = {
//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
  const dispatch = useDispatch()
  const {slug} = useParams()
  const navigate = useNavigate()
  const [sectorName,setSectorName] = useState()

  useEffect(()=>{
    if(slug) {
      dispatch(getAnMachineSector(slug)).then((data)=>{
        setSectorName(data.payload.data.sector_name)
      })
    }
  
  },[])

  const onSubmit = (e) =>{
    e.preventDefault();
    const data = {sector_name: sectorName}
    if(slug) {
      dispatch(updateMachineSector({slug,data})).then(()=>{
        navigate("/admin/machine/sector")
      })
    }else {
      dispatch(addMachineSector(data)).then(()=>{
        navigate("/admin/machine/sector")
      })
    }
  }
 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Machine Sector</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine Sector</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Machine Sector</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add </h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Sector Name</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Sector Name" size="lg" value = {sectorName} onChange = {(e)=>setSectorName(e.target.value)} />
                  </Form.Group>
                 
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>navigate("/admin/machine/sector")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}