import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import { Box, Button, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate , useParams } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { getUsers , updateUser , getSingleUser } from "../Store/Features/UsersSlice";


export const UpdateUser=()=>{
 
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { slug } = useParams();
  const UserData = useSelector(state => state.users.value)
  const Loading = useSelector(state => state.users.loading)
  const [user, setUser] = useState();
  const [userRole, setUserRole] = useState();
  const [apiCall_Loader, setapiCall_Loader] = useState(false);

  useEffect(() => {
    console.log('inside fun')
    if(slug){
    setapiCall_Loader(true)
    console.log('inside fun')
    dispatch(getSingleUser(slug)).then((resp) => {
      console.log('here is sigle user res',resp.payload.data)
      setapiCall_Loader(false)
      setUser(resp.payload.data);
      let temp=resp.payload.data
      console.log('user role in slugh',temp.roles[0].id)
      setUserRole(temp.roles[0].id)
      
   });
  
  }
 
  }, [UserData])

  const handleRoles = (e) => {
    console.log('here is user role',e.target.value)
    setUserRole(e.target.value)
      setUser({...user,roles:[userRole] })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('user upadet ',user)
    
    const userInfo={
      slug:slug,
      data: {...user,roles:[userRole]}
    }
    
    dispatch(updateUser(userInfo)).then((res) => {
     
      navigate("/admin/allUsers");
   });
  }
 
    return (
      <>
     
        <div>
        <div className="page-header">
          <h3 className="page-title">Users</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Users</a></li>
              <li className="breadcrumb-item active" aria-current="page">Update User </li>
            </ol>
          </nav>
        </div>
        <div>
            {apiCall_Loader ? (
            <Box sx={{height:"20px",textAlign:'center'}} > 
                <CircularProgress />
                </Box >
            ):(null)
            }

        </div>
        {user &&
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Update User</h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample" onSubmit={handleSubmit}>
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Username</label>
                    <Form.Control type="text" id="exampleInputUsername1" value={user.name} placeholder="Username" size="lg" onChange={e => setUser({...user,name:e.target.value })} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <Form.Control type="email" className="form-control" value={user.email} id="exampleInputEmail1" placeholder="Email"  onChange={e => setUser({...user,email:e.target.value })} />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <Form.Control type="password" className="form-control" value={user.password} id="exampleInputPassword1" placeholder="Password" onChange={e => setUser({...user,password:e.target.value })} />
                  </Form.Group>
                  {/* <Form.Group>
                    <label htmlFor="exampleInputConfirmPassword1">Confirm Password</label>
                    <Form.Control type="password" className="form-control" id="exampleInputConfirmPassword1" placeholder="Password" />
                  </Form.Group> */}
                  <Form.Group>
                  <label htmlFor="exampleInputConfirmPassword1">
                    Role
                  </label>
                  {/* <select value={userRole}   onChange={e => setUser({...user,roles:[e.target.value] })} name="productType" className="form-control">
                      <option value={0} >Select User Role</option>
                      <option value={1}>Admin</option> 
                      <option value={2}>User</option>
                  </select> */}
                   <select value={userRole}   onChange={handleRoles} name="productType" className="form-control">
                      <option value={0} >Select User Role</option>
                      <option value={1}>Admin</option> 
                      <option value={2}>User</option>
                  </select>
                </Form.Group>
                  {/* <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input"/>
                      <i className="input-helper"></i>
                      Remember me
                    </label>
                  </div> */}
                  <button type="submit" className="btn btn-gradient-primary mr-2">Submit</button>
                  <button className="btn btn-light" onClick={() => navigate('/admin/allusers')}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>}
      </div>
      
      </>
    )
   
 }



