import React, { Component,useEffect,useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import { useDispatch } from 'react-redux';
import { addProductFamily,updateProductFamily,getAnProductFamily } from '../Store/Features/Product/ProductFamily';
import { getProductType , addProductType,getAnProductType,updateProductType} from "../Store/Features/ProductType/ProductType";
import { useParams,useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";

export const AddProductType =()=>{

const dispatch = useDispatch()
const navigate = useNavigate()
const {slug} = useParams()
const [product_type,setProduct_type] = useState("")
const [apiCall_Loader, setapiCall_Loader] = useState(false);

useEffect(()=>{
  if(slug) {
    setapiCall_Loader(true)
    dispatch(getAnProductType(slug)).then((data)=>{
        setProduct_type(data.payload.data.title)
        setapiCall_Loader(false)
    })
  }

},[])

const onSubmit = (e) =>{
  e.preventDefault()
 
  const data = {
    title:product_type
    }
    console.log('here is product type',data)
  if(slug) {
    setapiCall_Loader(true)
    dispatch(updateProductType({slug,data})).then(()=>{
    setapiCall_Loader(false)
    navigate("/admin/product/product-type")
    })
  }else {
    setapiCall_Loader(true)
    dispatch(addProductType(data)).then(()=>{
    setapiCall_Loader(false)
    navigate("/admin/product/product-type")
    })
  }
}
   
 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Product Type</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Product</a></li>
              <li className="breadcrumb-item active" aria-current="page">c</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add Product Type</h4>
                {apiCall_Loader ? (
                    <Box sx={{height:"20px",textAlign:'center'}} > 
                    <CircularProgress />
                    </Box >
                 ):(null)
               }
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Product Type Name</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Add Product Type Name" value = {product_type} onChange={e=> setProduct_type(e.target.value)}  />
                  </Form.Group>
                  
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>navigate("/admin/product/product-type")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}