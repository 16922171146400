import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { getTransactions,deleteTransaction } from "../Store/Features/Transactions/Transactions";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h4 className="card-title" style={{ margin: 20 }}>All Payments </h4>
    </GridToolbarContainer>
  );
}
 
export const AllTransactions = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { slug } = useParams();
  const Transactions = useSelector(state => state.TransactionSlice.value)
  const Loading = useSelector(state => state.TransactionSlice.loading)

  useEffect(() => {
    dispatch(getTransactions())
  }, [])

  useEffect(() => {
    console.log('here is transactions data',Transactions)
  }, [Transactions])
  
  const handleDelete = (row) => {
    dispatch(deleteTransaction(row.id)).then(() => {
       dispatch(getTransactions())
    });
  }
 
const downloadPdfDocument = () => {
    var sample='sample'
    const input = document.getElementById('testId');
    html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0);
            pdf.save(`${sample}.pdf`);
        })
}

 
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: 'rgb(53 185 220)',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };

    const buttonStyle2 = {
      marginRight: '5px',
      padding: '5px 10px',
      backgroundColor: '#DC3545',
      color: 'white',
      border: 'none',
      borderRadius: '3px',
      cursor: 'pointer',
    };

  const columns = [
    { field: 'id', headerName: 'Order-ID', flex: 1 },
    { field: 'amount', headerName: 'Total Payment', flex: 1 },
    { field: 'transaction_date', headerName: 'Transaction Date', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <div>
          <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
          <button style={buttonStyle1} onClick={() => navigate("/admin/viewtransaction/"+params.row.id)}>View Invoice</button>
        </div>
      ),
    },
  ];
  
  return (

    <div style={{ height: 400, width: '100%' }}>
      <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> Payments  </h3>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Payments </a></li>
            <li className="breadcrumb-item active" aria-current="page">All Payments </li>
          </ol>
        </nav>
      </div>
          <div id="testId">
             <DataGrid
             rows={Transactions}
             columns={columns}
             loading = {Loading}
             initialState={{
               pagination: {
                 paginationModel: { page: 0, pageSize: 20 },
               },
             }}
             sx={{ backgroundColor: "white" }}
             checkboxSelection
             slots={{
               toolbar: CustomToolbar,
             }}
           />
           </div>
    </div>
  );
 
}



