import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";
import { ToastContainer, toast } from 'react-toastify';


export const getAssets = createAsyncThunk('getAssets', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}assets`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnAsset = createAsyncThunk('getAnAsset', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}assets/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const updateAsset = createAsyncThunk('updateAsset', async (arg, {
    rejectWithValue
}) => {
    console.log('data',arg)

    try {
        const { data } = await axios.put(`${baseUrl}assets/${arg.slug}`, arg.assetData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`,
                
            }
        });
        console.log('here is update asset data =====123==================',data)

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

// export const getMachineProducts = createAsyncThunk('getMachineProducts', async (arg, {
//     rejectWithValue
// }) => {

//     try {
//         const { data } = await axios.get(`${baseUrl}get-machine-products/${arg}`, {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("auth")}`
//             }
//         });

//         return data;
//     }
//     catch (error) {

//         return rejectWithValue(error.response.data.message);

//     }
// });

export const getCategories = createAsyncThunk('getCategories', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}asset-categories`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAssetStatuses = createAsyncThunk('getAssetStatuses', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}asset-statuses`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const addAssetStatus = createAsyncThunk('addAssetStatus', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}asset-statuses`, arg, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnStatus = createAsyncThunk('getAnStatus', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}asset-statuses/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateAssetStatus = createAsyncThunk('updateAssetStatus', async (arg, {
    rejectWithValue
}) => {
    console.log('data',)

    try {
        const { data } = await axios.put(`${baseUrl}asset-statuses/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`,
                
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addAsset = createAsyncThunk('addAsset', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}assets`, arg, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addAssetCategory = createAsyncThunk('addAssetCategory', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}asset-categories`, arg, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnCategory = createAsyncThunk('getAnCategory', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}asset-categories/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateCategory = createAsyncThunk('updateCategory', async (arg, {
    rejectWithValue
}) => {
    console.log('data',)

    try {
        const { data } = await axios.put(`${baseUrl}asset-categories/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`,
                
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const deleteAssets = createAsyncThunk('deleteAssets ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}assets/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const uploadAssetImage = createAsyncThunk('uploadAssetImage', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}assets/media`, arg ,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const assetsSlice = createSlice({
    name: 'Assets',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {

        builder.addCase(getAssets.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getAssets.fulfilled, (state, action) => {
            state.loading = false;
            state.value = action.payload.data;
        });
        builder.addCase(getAssets.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(addAsset.pending, (state, action) => {
            toast.loading('Adding Asset');
            state.loading = true;
        });
        builder.addCase(addAsset.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Asset Sucessfully');
            state.loading = false;

        });
        builder.addCase(addAsset.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed While updating Asset');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(updateAsset.pending, (state, action) => {
            toast.loading('Updating Asset');
            state.loading = true;
        });
        builder.addCase(updateAsset.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Asset');
            state.loading = false;

        });
        builder.addCase(updateAsset.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed updating Asset');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });


        builder.addCase(addAssetCategory.pending, (state, action) => {
            toast.loading('Adding Assets Category');
            state.loading = true;
        });
        builder.addCase(addAssetCategory.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Assets Category ');
            state.loading = false;

        });
        builder.addCase(addAssetCategory.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to add Assets Category');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(updateCategory.pending, (state, action) => {
            toast.loading('updating Assets Category');
            state.loading = true;
        });
        builder.addCase(updateCategory.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('updated Assets Category ');
            state.loading = false;

        });
        builder.addCase(updateCategory.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to updat Assets Category');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(deleteAssets.pending, (state, action) => {
            toast.loading('Deleting Asset');
            state.loading = true;
        });
        builder.addCase(deleteAssets.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Asset');
            state.loading = false;

        });
        builder.addCase(deleteAssets.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to Delete Asset');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(addAssetStatus.pending, (state, action) => {
            toast.loading('Adding Assets Status');
            state.loading = true;
        });
        builder.addCase(addAssetStatus.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Assets Status ');
            state.loading = false;

        });
        builder.addCase(addAssetStatus.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to add Assets Status');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(updateAssetStatus.pending, (state, action) => {
            toast.loading('updating Assets Status');
            state.loading = true;
        });
        builder.addCase(updateAssetStatus.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('updated Assets Status Sucessfully ');
            state.loading = false;

        });
        builder.addCase(updateAssetStatus.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to updat Assets Status');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });


       
        builder.addCase(uploadAssetImage.pending, (state, action) => {
            toast.loading('Uploading Asset Image');
            state.loading = true;
        });
        builder.addCase(uploadAssetImage.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Uploaded Asset Image');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(uploadAssetImage.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Error while Uploading Asset Image');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        

        // builder.addCase(logout.pending, (state, action) => {
        //   toast.loading('Logging Out...');
        //   state.loading = true;
        // });
        // builder.addCase(logout.fulfilled, (state, action) => {
        //   toast.dismiss();

        //   state.loading = false;

        //   state.loading = false;
        //   if (action.payload.message) {
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   }

        //   localStorage.removeItem("auth");
        //   localStorage.removeItem("role");
        //   toast.success('Logged out');

        // });
        // builder.addCase(logout.rejected, (state, action) => {
        //   toast.dismiss();
        //   toast.error('Promise rejected');
        //   if (action.payload == "Network Error") {
        //     localStorage.removeItem("auth");
        //     localStorage.removeItem("role");

        //     state.loading = false;
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   } else {
        //     state.loading = false;
        //     state.isSuccess = true;
        //     state.isAuthenticated = true;
        //   }

        // });
    },
});


export default assetsSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };