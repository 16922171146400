import React, { Component,useState,useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import {useDispatch } from "react-redux"
import { addMachineEstablishment } from '../Store/Features/Machine-Establishments';
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { getAnMachineEstablishment,updateMachineEstablishment } from '../Store/Features/Machine-Establishments';

export const AddMachineEstablishment =()=>{
//   state = {
//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
  const {slug} = useParams()
  const dispatch = useDispatch()
const navigate = useNavigate()
const [title,setTitle] = useState("")

useEffect(()=>{
  if(slug) {
    dispatch(getAnMachineEstablishment(slug)).then((data)=>{
      setTitle(data.payload.data.title)
    })
  }

},[])

const onSubmit = (e) =>{ 
  e.preventDefault()
  const data = {title: title}
  if(slug) {
    dispatch(updateMachineEstablishment({slug,data})).then(()=>{
      navigate("/admin/machine/establishment")
    })
  }else {
    dispatch(addMachineEstablishment(data)).then(()=>{
      navigate("/admin/machine/establishment")
    })
  }
}

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Machine Establishment</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine Establishment</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Machine Establishment</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add </h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Title</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Username" size="lg" value = {title} onChange = {(e)=>setTitle(e.target.value)} />
                  </Form.Group>
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick={onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>navigate("/admin/machine/establishment")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}