import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../Configuration/config";

import { toast } from 'react-toastify';
export const getMachinePaymentMethod = createAsyncThunk('getMachinePaymentMethod', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-payment-methods`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addMachinePaymentMethod= createAsyncThunk('addMachinePaymentMethod', async (arg, {
    rejectWithValue
}) => {
    console.log('addMachinePaymentMethod', arg);
    try {
        const { data } = await axios.post(`${baseUrl}machine-payment-methods`,arg,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const getAnMachinePaymentMethod = createAsyncThunk('getAnMachinePaymentMethod', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-payment-methods/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateMachinePaymentMethod = createAsyncThunk('updateMachinePaymentMethod', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.put(`${baseUrl}machine-payment-methods/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const deleteMachinePaymentMethod = createAsyncThunk('deleteMachinePaymentMethod ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}machine-payment-methods/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const MachinePaymentMethodSlice = createSlice({
    name: 'MachinePaymentMethod',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getMachinePaymentMethod.pending, (state, action) => {
            //   toast.loading('Logging in');
            state.loading = true;
        });
        builder.addCase(getMachinePaymentMethod.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Logged In');
            state.loading = false;
            state.value = action.payload.data;



        });
        builder.addCase(getMachinePaymentMethod.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Promise Not Resolved');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(addMachinePaymentMethod.pending, (state, action) => {
              toast.loading('Adding Machine Payment Method');
            state.loading = true;
        });
        builder.addCase(addMachinePaymentMethod.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Machine Payment Method');
            state.loading = false;
            



        });
        builder.addCase(addMachinePaymentMethod.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to add Machine Payment Method');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(updateMachinePaymentMethod.pending, (state, action) => {
            toast.loading('Updating Machine Payment Method');
            state.loading = true;
        });
        builder.addCase(updateMachinePaymentMethod.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Machine Payment Method');
            state.loading = false;




        });
        builder.addCase(updateMachinePaymentMethod.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to update Machine Payment Method');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(deleteMachinePaymentMethod.pending, (state, action) => {
            toast.loading('Deleting Machine Payment Method');
            state.loading = true;
        });
        builder.addCase(deleteMachinePaymentMethod.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Machine Payment Method');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(deleteMachinePaymentMethod.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to delete Machine Payment Method');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        // builder.addCase(logout.pending, (state, action) => {
        //   toast.loading('Logging Out...');
        //   state.loading = true;
        // });
        // builder.addCase(logout.fulfilled, (state, action) => {
        //   toast.dismiss();

        //   state.loading = false;

        //   state.loading = false;
        //   if (action.payload.message) {
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   }

        //   localStorage.removeItem("auth");
        //   localStorage.removeItem("role");
        //   toast.success('Logged out');

        // });
        // builder.addCase(logout.rejected, (state, action) => {
        //   toast.dismiss();
        //   toast.error('Promise rejected');
        //   if (action.payload == "Network Error") {
        //     localStorage.removeItem("auth");
        //     localStorage.removeItem("role");

        //     state.loading = false;
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   } else {
        //     state.loading = false;
        //     state.isSuccess = true;
        //     state.isAuthenticated = true;
        //   }

        // });
    },
});


export default MachinePaymentMethodSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };