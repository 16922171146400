import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {
  useDispatch, useSelector
} from "react-redux";
import { getAuth } from "../Store/Features/AuthSlice";
// import { toast } from 'react-toastify';
// import { ToastContainer } from 'react-toastify';


export  const  Login = () => {

  const dispatch = useDispatch();
  const [errorMessages, setErrorMessages] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    console.log('inside submit')
    event.preventDefault();

    var { email, password } = document.forms[0];

    console.log('email and password',email.value,password.value)

    if (email && password) {
      dispatch(getAuth({
        email: email.value,
        password: password.value
      })).then((data) => {
      
        navigate('/admin/dashboard');
    
      }).catch((err)=>{
        console.log('here is login data err')
      })
   } 
  }

  return (
    <div style={{marginTop:'150px'}} >
    <div className="d-flex align-items-center auth px-0">
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="brand-logo">
              {/* <img src={require("../assets/images/logo.svg")} alt="logo" /> */}
              <h5>Vending Machine</h5>
            </div>
            {/* <h4>Sign in to continue.</h4> */}
            <h6 className="font-weight-light">Sign in to continue</h6>
            <Form className="pt-3" onSubmit={handleSubmit}>
              <Form.Group className="d-flex search-field">
                <Form.Control type="email" placeholder="Username" size="lg" name="email" className="h-auto" />
              </Form.Group>
              <Form.Group className="d-flex search-field">
                <Form.Control type="password" placeholder="Password" size="lg" name="password" className="h-auto" />
              </Form.Group>
              <div className="mt-3">
                {/* <Link type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/">Log In</Link> */}
                <input type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" label="Login" value="Login" />
              </div>
              {/* <div className="my-2 d-flex justify-content-between align-items-center">
                <div className="form-check">
                  <label className="form-check-label text-muted">
                    <input type="checkbox" className="form-check-input"/>
                    <i className="input-helper"></i>
                    Keep me signed in
                  </label>
                </div>
                <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-black">Forgot password?</a>
              </div> */}
               
              {/* <div className="text-center mt-4 font-weight-light">
              <Link to="/signup" className="text-primary">Sign Up</Link>
              </div> */}
              <div className="text-center mt-4 font-weight-light">
                    Create New account? <Link to="/signup" className="text-primary">Sign up</Link>
                  </div>
            </Form>
          </div>
        </div>
      </div>
    </div>  
  </div>
  
  );

} ; 

