import React, {useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {getProduct,deleteProduct} from "../Store/Features/Product/Product"
import {getAssets,deleteAssets} from '../Store/Features/MachinesAssets/MachinesAssets';
import {useDispatch,useSelector} from "react-redux"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { width } from '@mui/system';
import parse from 'html-react-parser';



function CustomToolbar() {
    return (
      <GridToolbarContainer>
         <h4 className="card-title" style = {{margin: 20}}>All Assets </h4>
      </GridToolbarContainer>
    );
  }

const rows = [
  { id: 1, title: 'Snow', manufacturertitle: 'Jon' },
  { id: 2, title: 'Lannister', manufacturertitle: 'Cersei' },
  { id: 3, title: 'Lannister', manufacturertitle: 'Jaime' },
];


export function AllAssests() {
  const dispatch  = useDispatch()
  const navigate = useNavigate()
  const assets = useSelector(state => state.Assets.value)
  const loading = useSelector(state => state.Assets.loading)

  useEffect(()=>{
    dispatch(getAssets())
  },[])


  useEffect(()=>{
     console.log('here is assets data',assets)
  },[assets])

 

  const handleActions=(event,row)=>{
     if(event.target.value=='view'){
        handleView(row)
     }else if(event.target.value=='edit'){
      handleEdit(event,row)
     }else if(event.target.value=='delete'){
      handleDelete(row)
     }else{

     }
  }

  const handleView = (row) => {
    console.log('View:', row);
  };

  const handleEdit = (e,row) => {
    console.log('handle click event:', e.target.value,row.id);
    navigate(`/admin/assets/edit/${row.id}`)
    console.log('Edit:', row);
  };

  const handleDelete = (row) => {
    dispatch(deleteAssets(row.id)).then(() => {
      dispatch(getAssets())
    });
  };

  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
 
 const columns = [
    
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Asset Name', flex: 1  },
    { field: 'machine_id', headerName: 'Machine_ID',flex: 1 },
    { field: 'category', headerName: 'Category', flex: 1,
     renderCell: (params) => {
      return <a>{params.row.category?.name}</a>
     }
    },
    { field: 'status', headerName: 'Status', flex: 1,
     renderCell: (params) => {
      return <a>{params.row.status?.name}</a>
     }
    },
    { field: 'serial_number', headerName: 'Serial_number', flex: 1,},
    { field: 'notes', headerName: 'Descriptions', flex: 1,
    renderCell: (params) => {
    //  return <a>{parse(params.row.notes)}</a>
     return <h1>{parse(params.row.notes)}</h1>
    }
   },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1 ,
      renderCell: (params) => (
       
        <div>
           <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
            <InputLabel  >Actions</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label= "Actions"
              onChange={e => handleActions(e,params.row)}
              className={buttonStyle}
            >
              {/* <MenuItem value={'view'}>View</MenuItem> */}
              <MenuItem value={'edit'}>Edit</MenuItem>
              <MenuItem value={'delete'}>Delete</MenuItem>
            </Select>
          </FormControl>
        </div>
      ),
    },
  ];

  return (
    
    <div style={{ height: 400, width: '100%' }}>
        <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> All Assets  </h3>

          <button type="button" class="btn btn-gradient-success " onClick = {()=>navigate("/admin/assets/add")}>Add Asset</button>
        </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Assets </a></li>
              <li className="breadcrumb-item active" aria-current="page">All Assets </li>
            </ol>
          </nav>
        </div>
            <DataGrid
                rows={assets}
                columns={columns}
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                sx={{ backgroundColor: "white" }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                slots={{
                    toolbar: CustomToolbar,
                  }}
            />
        </div>
  );
}