import React, { useState, useEffect } from "react";
import './AssetsTabs.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { getAnAsset } from "../Store/Features/PublicApis/Assets";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import parse from 'html-react-parser';

export const AssetsInfo = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [assetInfo, setAssetInfo] = useState({});
    const [apiCall_Loader, setapiCall_Loader] = useState(false);

    useEffect(() => {
        setapiCall_Loader(true)
        dispatch(getAnAsset(slug)).then((res) => {
            setapiCall_Loader(false)
            console.log("here is Asset detail data...........", res);
            setAssetInfo(res.payload.data);
        });
    }, []);

    useEffect(() => {
        console.log("here is Asset Info..........", assetInfo);
    }, [assetInfo]);

    const images = [
        "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
        "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
        "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    ];


    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Asset Detail</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                {/* <li className="breadcrumb-item"><a href="/admin/dashboard">Dashboard</a></li> */}
                                <li className="breadcrumb-item"><a href="/login">Login</a></li>
                                {/* <li className="breadcrumb-item active">DataTables</li> */}
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="card card-solid">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <h3 className="d-inline-block d-sm-none">Asset Detail with Machine Info</h3>
                                <div className="col-12">
                                    <button onClick={() => navigate("/")} id="submitBtn" className="btn btn-primary">
                                        Back
                                    </button>
                                </div>

                                {/* <div className="col-12" id="image-container">
                                    <img src='/images/vapora.png' className="product-image main-product-image main-imgStyle" alt="" />
                                    <div id="loader" className="loading" style={{ display: 'none' }}>Loading</div>
                                </div> */}
                                <div className="col-12" id="image-container">
                                    {apiCall_Loader ? (
                                    <Box sx={{height:"20px",textAlign:'center'}} > 
                                        <CircularProgress />
                                        </Box >
                                    ):(null)
                                    }
                                    <Carousel showThumbs={true} showIndicators={true}>
                                        
                                          {assetInfo?.photos?.map((img) => (
                                            <div>
                                                <img src={img?.original_url} alt="Product Image" />
                                            </div>
                                        ))}

                                        {/* <div>
                                        <img src="/images/vapora.png" alt="Product Image" />
                                        </div>
                                        <div>
                                        <img src="/images/vapora.png" alt="Product Image" />
                                        </div>
                                        <div>
                                        <img src="/images/vapora.png" alt="Product Image" />
                                        </div> */}
                                    </Carousel>
                                    </div>

                                {/* <div className="col-12 product-image-thumbs">
                                    <div className="product-image-thumb active"><img src="/images/vapora.png" className="product-image" alt="" /></div>
                                    <div className="product-image-thumb"><img src="/images/vapora.png" alt="Product Image" /></div>
                                    <div className="product-image-thumb"><img src="/images/vapora.png" alt="Product Image" /></div>
                                    <div className="product-image-thumb"><img src="/images/vapora.png" alt="Product Image" /></div>
                                    <div className="product-image-thumb"><img src="/images/vapora.png" alt="Product Image" /></div>
                                    
                                </div> */}
                            </div>

                            <div className="col-12 col-sm-6">
                                <h3 className="my-3">Asset Detail with Machine Info</h3>

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">{assetInfo.name}</h3>
                                                </div>
                                                {/* <div className="card-body">
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Asset Name</th>
                                                                <th>Machine Code</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{assetInfo?.id}</td>
                                                                <td>{assetInfo?.name}</td>
                                                                <td>{assetInfo?.machine?.machine_code}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div> */}
                                            </div>
                                            <div className="description-detail">
                                                <h3 className="description-title">Description</h3>
                                                <h1 className="description-text description"><h1>{assetInfo?.notes ? parse(assetInfo.notes) : null}</h1></h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
