import React  from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux/es/exports';

const Sidebar=()=>{

  const [state, setState] = useState({});
  let location = useLocation().pathname;
  const user = useSelector(state => state.user.value)
  useEffect(() => {
    // onRouteChanged()
  });
  const toggleMenuState=(menuState)=> {
    if (state[menuState]) {
      setState({[menuState] : false});
    } else if(Object.keys(state).length === 0) {
      setState({[menuState] : true});
    } else {
      Object.keys(state).forEach(i => {
        setState({[i]: false});
      });
      setState({[menuState] : true});
    }
  }
  const isPathActive=(path)=> {
   
  // console.log('here is user inside protected routes',location)
  // console.log('path ',path)
      // console.log('location',location);
      if(location === path) return true;
  //   // return location.startsWith(path);
  }

  
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="!#" className="nav-link" onClick={evt =>evt.preventDefault()}>
              <div className="nav-profile-image">
                {/* <img src={ require("../assets/images/faces/face1.jpg") } alt="profile" /> */}
                <img className='logo' src={'/images/vapora.png'} />
                <span className="login-status online"></span> {/* change to offline or busy as needed */}
              </div>
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2"><Trans>{user.data.user.name}</Trans></span>
                <span className="text-secondary text-small"><Trans>{user.data.user.roles[0].title}</Trans></span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>
          <li className={ state.dashboard ? 'nav-item active' : 'nav-item' } onClick={ () => toggleMenuState('dashboard') }>
            <Link className="nav-link" to="/admin/dashboard">
              <span className="menu-title"><Trans>Dashboard</Trans></span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          <li className={ state.users ? 'nav-item active' : 'nav-item' }>
            <div className={ state.users ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('users') } data-toggle="collapse">
              <span className="menu-title"><Trans>Users</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={ state.users }>
              <ul className="nav flex-column sub-menu">
                {/* <li className="nav-item"> <Link className={ isPathActive('/adduser') ? 'nav-link active' : 'nav-link' } to="/adduser"><Trans>Add User</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/UpdateUser') ? 'nav-link active' : 'nav-link' } to="/UpdateUser"><Trans>Update Users</Trans></Link></li> */}
                <li className="nav-item"> <Link className={ isPathActive('/admin/allusers') ? 'nav-link active' : 'nav-link' } to="/admin/allusers"><Trans>All Users</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ state.machines ? 'nav-item active' : 'nav-item' }>
            <div className={ state.machines ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('machines') } data-toggle="collapse">
              <span className="menu-title"><Trans>Machines</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={ state.machines }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine') ? 'nav-link active' : 'nav-link' } to="/admin/machine"><Trans>All Machines</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine/manufacturer') ? 'nav-link active' : 'nav-link' } to="/admin/machine/manufacturer"><Trans>Manufacturer</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine/model') ? 'nav-link active' : 'nav-link' } to="/admin/machine/model"><Trans>Machine Model</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine/currency') ? 'nav-link active' : 'nav-link' } to="/admin/machine/currency"><Trans>Machine Currency</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine/establishment') ? 'nav-link active' : 'nav-link' } to="/admin/machine/establishment"><Trans>Machine Establishment</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine/sector') ? 'nav-link active' : 'nav-link' } to="/admin/machine/sector"><Trans>Machine Sector</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine/distributor') ? 'nav-link active' : 'nav-link' } to="/admin/machine/distributor"><Trans>Machine Distributor</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine/commisionperiod') ? 'nav-link active' : 'nav-link' } to="/admin/machine/commisionperiod"><Trans>Machine Commision Period</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine/paymentmethod') ? 'nav-link active' : 'nav-link' } to="/admin/machine/paymentmethod"><Trans>Machine Payment Method</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/machine/percentageorfixed') ? 'nav-link active' : 'nav-link' } to="/admin/machine/percentageorfixed"><Trans>Machine Percentage Or Fixed </Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ state.product ? 'nav-item active' : 'nav-item' }>
            <div className={ state.product ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('product') } data-toggle="collapse">
              <span className="menu-title"><Trans>Product</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-store menu-icon"></i>
            </div>
            <Collapse in={ state.product }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/admin/Product') ? 'nav-link active' : 'nav-link' } to="/admin/product"><Trans>All Products</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/product/family') ? 'nav-link active' : 'nav-link' } to="/admin/product/family"><Trans>Product Family</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/product/company') ? 'nav-link active' : 'nav-link' } to="/admin/product/company"><Trans>Product Company</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/product/stratorcode') ? 'nav-link active' : 'nav-link' } to="/admin/product/stratorcode"><Trans>Product Strator Code</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/product/product-type') ? 'nav-link active' : 'nav-link' } to="/admin/product/product-type"><Trans>Product Type</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/product/allproducts-stock') ? 'nav-link active' : 'nav-link' } to="/admin/product/allproducts-stock"><Trans>All Products Stock</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ state.order ? 'nav-item active' : 'nav-item' }>
            <div className={ state.order ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('order') } data-toggle="collapse">
              <span className="menu-title"><Trans>Order</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-store menu-icon"></i>
            </div>
            <Collapse in={ state.order }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/admin/allorders') ? 'nav-link active' : 'nav-link' } to="/admin/allorders"><Trans>All Orders</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/addorder') ? 'nav-link active' : 'nav-link' } to="/admin/addorder"><Trans>Add Order</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/alltransactions') ? 'nav-link active' : 'nav-link' } to="/admin/alltransactions"><Trans>Payments</Trans></Link></li>
                {/* <li className="nav-item"> <Link className={ isPathActive('/updateorder') ? 'nav-link active' : 'nav-link' } to="/updateorder"><Trans>Update Order</Trans></Link></li> */}
              </ul>
            </Collapse>
          </li>
          {/* <li className={ state.payment ? 'nav-item active' : 'nav-item' }>
            <div className={ state.payment ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('payment') } data-toggle="collapse">
              <span className="menu-title"><Trans>Payments</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-store menu-icon"></i>
            </div>
            <Collapse in={ state.payment }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/alltransactions') ? 'nav-link active' : 'nav-link' } to="/alltransactions"><Trans>ALL Payments</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
          <li className={ state.assets ? 'nav-item active' : 'nav-item' }>
            <div className={ state.assets ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('assets') } data-toggle="collapse">
              <span className="menu-title"><Trans>Assets</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-store menu-icon"></i>
            </div>
            <Collapse in={ state.assets }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/admin/assets') ? 'nav-link active' : 'nav-link' } to="/admin/assets"><Trans>All Assets</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/assets/allcategories') ? 'nav-link active' : 'nav-link' } to="/admin/assets/allcategories"><Trans>Assets categories</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/admin/assets/allassetstatus') ? 'nav-link active' : 'nav-link' } to="/admin/assets/allassetstatus"><Trans>Assets Status</Trans></Link></li>
               
              </ul>
            </Collapse>
          </li>
           

          {/* <li className={ isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
            <div className={ state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Form Elements</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </div>
            <Collapse in={ state.formElementsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements"><Trans>Basic Elements</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <div className={ state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Tables</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-table-large menu-icon"></i>
            </div>
            <Collapse in={ state.tablesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <div className={ state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Icons</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-contacts menu-icon"></i>
            </div>
            <Collapse in={ state.iconsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi"><Trans>Material</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
            <div className={ state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Charts</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-chart-bar menu-icon"></i>
            </div>
            <Collapse in={ state.chartsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link' } to="/charts/chart-js"><Trans>Chart Js</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/user-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>User Pages</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-lock menu-icon"></i>
            </div>
            <Collapse in={ state.userPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-1"><Trans>Login</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-1"><Trans>Register</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/user-pages/lockscreen') ? 'nav-link active' : 'nav-link' } to="/user-pages/lockscreen"><Trans>Lockscreen</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Error Pages</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-security menu-icon"></i>
            </div>
            <Collapse in={ state.errorPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ isPathActive('/general-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ state.generalPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('generalPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>General Pages</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-medical-bag menu-icon"></i>
            </div>
            <Collapse in={ state.generalPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/general-pages/blank-page') ? 'nav-link active' : 'nav-link' } to="/general-pages/blank-page"><Trans>Blank Page</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="http://bootstrapdash.com/demo/purple-react-free/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <span className="menu-title"><Trans>Documentation</Trans></span>
              <i className="mdi mdi-file-document-box menu-icon"></i>
            </a>
          </li> */}
        </ul>
      </nav>
    );




  // useEffect(() => {
  //   onRouteChanged()
  //    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
  //    const body = document.querySelector('body');
  //    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
       
  //      el.addEventListener('mouseover', function() {
  //        if(body.classList.contains('sidebar-icon-only')) {
  //          el.classList.add('hover-open');
  //        }
  //      });
  //      el.addEventListener('mouseout', function() {
  //        if(body.classList.contains('sidebar-icon-only')) {
  //          el.classList.remove('hover-open');
  //        }
  //      });
  //    });
  //  })
  
}

export default Sidebar;