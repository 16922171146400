import React, {useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {getProduct,deleteProduct} from "../Store/Features/Product/Product"
import {deleteAssets} from '../Store/Features/MachinesAssets/MachinesAssets';
import {getCategories,deleteCategory} from '../Store/Features/MachinesAssets/AllCategories';
import {useDispatch,useSelector} from "react-redux"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { width } from '@mui/system';



function CustomToolbar() {
    return (
      <GridToolbarContainer>
         <h4 className="card-title" style = {{margin: 20}}>All Categories </h4>
      </GridToolbarContainer>
    );
  }

const rows = [
  { id: 1, title: 'Snow', manufacturertitle: 'Jon' },
  { id: 2, title: 'Lannister', manufacturertitle: 'Cersei' },
  { id: 3, title: 'Lannister', manufacturertitle: 'Jaime' },
];


export function AllCategory() {
  const dispatch  = useDispatch()
  const navigate = useNavigate()
  const assetCategories = useSelector(state => state.CategorySlice.value)
  const loading = useSelector(state => state.CategorySlice.loading)

  useEffect(()=>{
    dispatch(getCategories()).then((resp)=>{
      console.log('getCategories',resp)
    })
  },[])


  useEffect(()=>{
     console.log('here is assets data',assetCategories)
  },[assetCategories])

 

  // const handleActions=(event,row)=>{
  //    if(event.target.value=='view'){
  //       handleView(row)
  //    }else if(event.target.value=='edit'){
  //     handleEdit(event,row)
  //    }else if(event.target.value=='delete'){
  //     handleDelete(row)
  //    }else{

  //    }
  // }

  const handleView = (row) => {
    console.log('View:', row);
  };

  const handleEdit = (row) => {
    navigate(`/admin/assets/addcategory/${row.id}`)
  };

  const handleDelete = (row) => {
    dispatch(deleteCategory(row.id)).then(() => {
      dispatch(getCategories())
    });
  };

  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
 
 const columns = [
    
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Category Name', flex: 1  },
     
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1 ,
      renderCell: (params) => (
        <div>
          <button style={buttonStyle1} onClick={() => handleEdit(params.row)}>Edit</button>
          <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
        </div>
      ),
    },
    
  ];

  return (
    
    <div style={{ height: 400, width: '100%' }}>
        <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> All Categories  </h3>

          <button type="button" class="btn btn-gradient-success " onClick = {()=>navigate("/admin/assets/addcategory")}>Add Category</button>
        </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Assets </a></li>
              <li className="breadcrumb-item active" aria-current="page">All Categories </li>
            </ol>
          </nav>
        </div>
            <DataGrid
                rows={assetCategories}
                columns={columns}
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                sx={{ backgroundColor: "white" }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                slots={{
                    toolbar: CustomToolbar,
                  }}
            />
        </div>
  );
}