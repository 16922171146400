import React, {useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {getProduct,deleteProduct} from "../Store/Features/Product/Product"
import {useDispatch,useSelector} from "react-redux"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { width } from '@mui/system';


function CustomToolbar() {
    return (
      <GridToolbarContainer>
         <h4 className="card-title" style = {{margin: 20}}>All Products </h4>
      </GridToolbarContainer>
    );
  }

const rows = [
  { id: 1, title: 'Snow', manufacturertitle: 'Jon' },
  { id: 2, title: 'Lannister', manufacturertitle: 'Cersei' },
  { id: 3, title: 'Lannister', manufacturertitle: 'Jaime' },
];

export function Product() {
  const dispatch  = useDispatch()
  const navigate = useNavigate()
  const product = useSelector(state => state.product.value)

  useEffect(()=>{
    dispatch(getProduct())
  },[])

 

  const handleActions=(event,row)=>{
     if(event.target.value=='view'){
        handleView(row)
     }else if(event.target.value=='edit'){
      handleEdit(event,row)
     }else if(event.target.value=='delete'){
      handleDelete(row)
     }else{

     }
  }

  const handleView = (row) => {
    console.log('View:', row);
  };

  const handleEdit = (e,row) => {
    console.log('handle click event:', e.target.value,row.id);
    navigate(`/admin/product/edit/${row.id}`)
    console.log('Edit:', row);
  };

  const handleDelete = (row) => {
    dispatch(deleteProduct(row.id)).then(() => {
      dispatch(getProduct())
    });
  };

  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
 
 const columns = [
    
    // { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'product_image', headerName: 'Product Image',flex: 1,renderCell: (params) => {
     return( <a style= {{cursor:"pointer"}} href = {params.row.product_image?.original_url} target = "_blank"  >
      <img style = {{height:"100px",width:"100px"}}  src={params.row.product_image?.original_url} /> </a>)
      // return 
    }   },
    { field: 'product_name', headerName: 'Title',flex: 1 },
    // { field: 'product_code', headerName: 'Product Code', flex: 1  },
    // { field: 'product_code_in_machine', headerName: 'Product Code In Machine',flex: 1 },
    // { field: 'barcode', headerName: 'Barcode', flex: 1  },
    // { field: 'product_code_company', headerName: 'Product Code Company',flex: 1 },
    // { field: 'matrix_product', headerName: 'Matrix Product', flex: 1  },
    // { field: 'units_from_matrix_product', headerName: 'Units From Matrix Product',flex: 1 },
    { field: 'purchase_price', headerName: 'Purchase Price', flex: 1  },
    // { field: 'segment', headerName: 'Segment',flex: 1 },
    { field: 'retail_price', headerName: 'Retail Price', flex: 1  },
    // { field: 'rounding_unit', headerName: 'Rounding Unit', flex: 1  },
    // { field: 'type_of_product_id', headerName: 'Type Of Product Id',flex: 1 },
    // { field: 'distributor_id', headerName: 'Distributor Id', flex: 1  },
    // { field: 'currency_id', headerName: 'Currency Id',flex: 1 },
    // { field: 'company_id', headerName: 'Company Id', flex: 1  },
    // { field: 'family_id', headerName: 'Family Id',flex: 1 },
    // { field: 'strator_code_id', headerName: 'Strator Code Id', flex: 1  },
    { field: 'type_of_product', headerName: 'Type Of Product', flex: 1,
     renderCell: (params) => {
      return <a>{params.row.type_of_product?.title}</a>
     }
    },
    // { field: 'type_of_product', headerName: 'Type Of Product', flex: 1,renderCell: (params) => {
    //   return <p>{params.row.type_of_product?.title}</p>
    // }  },
    { field: 'distributor', headerName: 'Distributor',flex: 1, renderCell: (params) => {
      return <a>{params.row.distributor?.distributor_name}</a> 
    }  },
    { field: 'currency', headerName: 'Currency', flex: 1,renderCell: (params) => {
      return <a>{params.row.currency?.currency_icon}</a>
    }   },
    // { field: 'company', headerName: 'Company',flex: 1,renderCell: (params) => {
    //   return <p>{params.row.company.company_name}</p>
    // }  },
    // { field: 'family', headerName: 'Family ', flex: 1 ,renderCell: (params) => {
    //   return <p>{params.row.family?.family_name}</p>
    // }  },
    // { field: 'strator_code', headerName: 'Strator Code',flex: 1,renderCell: (params) => {
    //   return <p>{params.row.strator_code?.strator_code_name}</p>
    // }  },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1 ,
      renderCell: (params) => (
       
        <div>
          {/* <select name="productType"  onChange={e => handleActions(e,params.row)} className="form-control">
            <option>Select Actions</option>
            <option value={'view'}>View</option>
            <option value={'edit'}>Edit</option>
            <option value={'delete'}>Delete</option>
          </select> */}
           <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
            <InputLabel  >Actions</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label= "Actions"
              onChange={e => handleActions(e,params.row)}
              className={buttonStyle}
            >
              {/* <MenuItem value={'view'}>View</MenuItem> */}
              <MenuItem value={'edit'}>Edit</MenuItem>
              <MenuItem value={'delete'}>Delete</MenuItem>
            </Select>
          </FormControl>

          {/* <FormControl >
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
            
              onChange={e => handleActions(e,params.row)}
              sx={{
                width:'30'
              }}
              
            >
              <MenuItem value={'view'}>View</MenuItem>
              <MenuItem value={'edit'}>Edit</MenuItem>
              <MenuItem value={'delete'}>Delete</MenuItem>
            </Select>
          </FormControl> */}
          {/* <button style={buttonStyle} onClick={() => handleView(params.row)}>View</button>
          <button style={buttonStyle1} onClick={() => handleEdit(params.row)}>Edit</button>
          <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button> */}
        </div>
      ),
    },
  ];

  return (
    
    <div style={{ height: 400, width: '100%' }}>
        <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> Product  </h3>

          <button type="button" class="btn btn-gradient-success " onClick = {()=>navigate("/admin/product/add")}>Add Product</button>
        </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Product </a></li>
              <li className="breadcrumb-item active" aria-current="page">All Product </li>
            </ol>
          </nav>
        </div>
            <DataGrid
                rows={product}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                sx={{ backgroundColor: "white" }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                slots={{
                    toolbar: CustomToolbar,
                  }}
            />
        </div>
  );
}