import React, { Component,useEffect,useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import { useDispatch } from 'react-redux';
import { addProductStrataCodeName,getAnProductStrataCodeName,updateProductStrataCodeName } from '../Store/Features/Product/ProductStrataCodeName';
import { useParams,useNavigate } from 'react-router-dom';
export const AddProductstratorcode =()=>{
//   state = {
//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
const dispatch = useDispatch()
const Navigate = useNavigate()
const {slug} = useParams()
const [stratorCode,setStratorCode] = useState("")
useEffect(()=>{
  if(slug) {
    dispatch(getAnProductStrataCodeName(slug)).then((data)=>{
      setStratorCode(data.payload.data.strator_code_name)
    })
  }

},[])

const onSubmit = (e) =>{
  e.preventDefault()
 
  const data = {strator_code_name:stratorCode}
  if(slug) {
    dispatch(updateProductStrataCodeName({slug,data})).then(()=>{
      Navigate("/admin/product/stratorcode")
    })
  }else {
    dispatch(addProductStrataCodeName(data)).then(()=>{
      Navigate("/admin/product/stratorcode")
    })
  }
}
   
 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Product Strator Code</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Product Strator Code</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Product Strator Code Name</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add </h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Family Name</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Family Strator Code Name" value = {stratorCode} onChange={e=> setStratorCode(e.target.value)}  />
                  </Form.Group>
                  
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>Navigate("/admin/product/stratorcode")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}