import React, { useEffect, useState } from "react";
import { ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
// import "react-datepicker/dist/react-datepicker.css";


import { getMachine } from '../Store/Features/Machine';
import { getProduct } from "../Store/Features/Product/Product";
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../Store/Features/UsersSlice';



export const Dashboard = () => {
  const dispatch = useDispatch();
  const machines = useSelector(state => state.Machine.value);
  const product = useSelector(state => state.product.value);
  const users = useSelector(state => state.users.value);

  useEffect(() => {
    dispatch(getMachine());
    dispatch(getProduct());
    dispatch(getUsers());
  }, [dispatch]);

  // Rest of the code


  // ...




  //   
  return (

    <div>

      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white mr-2">
            <i className="mdi mdi-home"></i>
          </span> Dashboard </h3>
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              <span></span>Overview <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-danger card-img-holder text-white">
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 className="font-weight-normal mb-3">Total Machines </h4>
                <PointOfSaleIcon sx={{ color: "white", }} />
              </div>
              <h2 className="mb-5">{machines.length}</h2>
            </div>
          </div>
        </div>
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-info card-img-holder text-white">
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 className="font-weight-normal mb-3">Total Products
                </h4>
                <ProductionQuantityLimitsIcon sx={{ color: "white", }} />
              </div>
              <h2 className="mb-5">{product.length}</h2>

            </div>
          </div>
        </div>
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-success card-img-holder text-white">
            <div className="card-body">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 className="font-weight-normal mb-3">Total Users 
              </h4>
              <PeopleAltIcon sx={{ color: "white", }}/> 
              </div>
              <h2 className="mb-5">{users.length}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="clearfix mb-4">
                <h4 className="card-title float-left">Visit And Sales Statistics</h4>
                <div id="visit-sale-chart-legend" className="rounded-legend legend-horizontal legend-top-right float-right">
                  <ul>
                    <li>
                      <span className="legend-dots bg-primary">
                      </span>CHN
                    </li>
                    <li>
                      <span className="legend-dots bg-danger">
                      </span>USA
                    </li>
                    <li>
                      <span className="legend-dots bg-info">
                      </span>UK
                    </li>
                  </ul>
                </div>
              </div>
              {/* <Bar ref='chart' className="chartLegendContainer" data={this.state.visitSaleData} options={this.state.visitSaleOptions} id="visitSaleChart" /> */}
            </div>
          </div>
        </div>
        <div className="col-md-5 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Traffic Sources</h4>
              {/* <Doughnut data={this.state.trafficData} options={this.state.trafficOptions} /> */}
              <div id="traffic-chart-legend" className="rounded-legend legend-vertical legend-bottom-left pt-4">
                <ul>
                  <li>
                    <span className="legend-dots bg-info"></span>Search Engines
                    <span className="float-right">30%</span>
                  </li>
                  <li>
                    <span className="legend-dots bg-success"></span>Direct Click
                    <span className="float-right">30%</span>
                  </li>
                  <li>
                    <span className="legend-dots bg-danger"></span>Bookmarks Click
                    <span className="float-right">40%</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );

};

