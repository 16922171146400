import React, { Component,useEffect,useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import { useDispatch } from 'react-redux';
import { addProductFamily,updateProductFamily,getAnProductFamily } from '../Store/Features/Product/ProductFamily';
import { addAsset,addAssetCategory} from '../Store/Features/MachinesAssets/MachinesAssets';
import {updateCategory,getAnCategory} from '../Store/Features/MachinesAssets/AllCategories';
import { useParams,useNavigate } from 'react-router-dom';
import { Category } from '@mui/icons-material';
export const AddAssetCategory =()=>{
//   state = {
//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
const dispatch = useDispatch()
const Navigate = useNavigate()

const {slug} = useParams()
const [category_name,setCategory] = useState("")
useEffect(()=>{
  if(slug) {
    dispatch(getAnCategory(slug)).then((data)=>{
        setCategory(data.payload.data.name)
    })
  }

},[])

const onSubmit = (e) =>{
  e.preventDefault()
 
  const data = {name:category_name}
  if(slug) {
    console.log('inside update',data)
    dispatch(updateCategory({slug,data})).then(()=>{
      Navigate("/admin/assets/allcategories")
    })
  }else {
    dispatch(addAssetCategory(data)).then(()=>{
      Navigate("/admin/assets/allcategories")
    })
  }
}
   
 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Asset Category</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Asset Category</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Asset Category</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add </h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Add Category</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Category Name" value = {category_name} onChange={e=> setCategory(e.target.value)}  />
                  </Form.Group>
                  
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>Navigate("/admin/assets")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}