import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authSlice from "./Features/AuthSlice";
import userSlice from "./Features/UsersSlice";
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import manufacturersSlice from "./Features/Manufacture"
import machinemodelSlice from "./Features/Machinemodel"
import machinecurrencySlice from "./Features/Machinecurrency"
import machineEstablishmentsSlice from "./Features/Machine-Establishments"
import machineSectorSlice from "./Features/MachineSector"
import machineDistributorSlice from "./Features/MachineDistributor";
import MachineCommisionperiodSlice from "./Features/MachineCommisionPeriod"
import MachinePaymentMethodSlice from "./Features/MachinePaymentMethod"
import MachinePercentageOrFixedSlice from "./Features/MachinePercentageOrFixed"
import productSlice from "./Features/Product/Product";
import productFamilySlice from "./Features/Product/ProductFamily";
import productCompanySlice from "./Features/Product/ProductCompany";
import productStrataCodeNameSlice from "./Features/Product/ProductStrataCodeName";
import machineSlice from "./Features/Machine"
import { Machine } from "../Machine/Machine";
import {productTypeSlice} from "./Features/ProductType/ProductType"
import {orderSlice} from "./Features/Order/Order"
import {transactionSlice} from "./Features/Transactions/Transactions"
import {assetsSlice} from "./Features/MachinesAssets/MachinesAssets"
import {machineOdersSlice} from './Features/MachineOrders'
import {machineProductsSlice} from './Features/MachineProducts'
import {machineAssetsSlice} from './Features/MachineAssets'
import {categorySlice} from "./Features/MachinesAssets/AllCategories"
import {assetsStatusSlice} from "./Features/MachinesAssets/AllAssetsStatus"
import {publicAssetsSlice} from "./Features/PublicApis/Assets"
import {publicCategorySlice} from "./Features/PublicApis/Categories"
 
 
const rootReducer = combineReducers({ 
  user: authSlice.reducer,
  users: userSlice.reducer,
  manufacturer: manufacturersSlice.reducer,
  machinemodel:machinemodelSlice.reducer,
  machinecurrency: machinecurrencySlice.reducer,
  machineEstablishments: machineEstablishmentsSlice.reducer,
  machineSectors: machineSectorSlice.reducer,
  machineDistributors: machineDistributorSlice.reducer, 
  MachineCommisionperiod: MachineCommisionperiodSlice.reducer,
  MachinePaymentMethod: MachinePaymentMethodSlice.reducer,
  MachinePercentageOrFixed: MachinePercentageOrFixedSlice.reducer,
  product: productSlice.reducer,
  productFamily: productFamilySlice.reducer,
  productCompany: productCompanySlice.reducer,
  productStrataCodeName: productStrataCodeNameSlice.reducer,
  Machine: machineSlice.reducer,
  ProductTypeSlice: productTypeSlice.reducer,
  OrderSlice:orderSlice.reducer,
  TransactionSlice:transactionSlice.reducer,
  Assets:assetsSlice.reducer,
  machineOders:machineOdersSlice.reducer,
  machineProducts:machineProductsSlice.reducer,
  machineAssets:machineAssetsSlice.reducer,
  CategorySlice:categorySlice.reducer,
  AssetsStatus:assetsStatusSlice.reducer,
  PublicAssets:publicAssetsSlice.reducer,
  PublicCategory:publicCategorySlice.reducer
  
})
const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer
});


export default store;