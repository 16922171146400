
import React, {useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { getMachine, deleteMachine } from "../Store/Features/Machine"
import { getProductType } from "../Store/Features/ProductType/ProductType";
import { getProduct} from "../Store/Features/Product/Product";
import { addOrder} from "../Store/Features/Order/Order";
import { addMachine } from "../Store/Features/Machine";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import "./orders.css";

export const UpdateOrder = () => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Machines = useSelector(state => state.Machine.value)
    const ProductType = useSelector(state => state.ProductTypeSlice.value)
    const Products = useSelector(state => state.product.value)
    const Loading = useSelector(state => state.Machine.loading)
    const [selected,setSelected] = useState([])

    const [emailError, setEmailError] = useState(false);
    const [order, setOrder] = useState({
        machine_id: ''
    });
    const [value, setValue] = React.useState('1');
    const [expandedAccordion1, setExpandedAccordion1] = useState(null);
    const [expandedAccordion2, setExpandedAccordion2] = useState(false);
    const [machine_id, setMachine_id] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);


    
    const handleAccordion1Change = (id) => {
        setExpandedAccordion1(id);
    };
    const handleAccordion2Change = () => {
        setExpandedAccordion2(!expandedAccordion2);
    };
    const handleDelete = (e,id) => {
        e.preventDefault()
        let temp = [...selected]
        const index = temp.findIndex(item => item.product_id == id);
        temp.splice(index, 1);
        setSelected(temp);
    }
    const buttonStyle2 = {
        marginRight: '5px',
        padding: '5px 10px',
        backgroundColor: '#DC3545',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
      };
   


    const columns = [
        { field: 'product_id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Product Name', flex: 1  },
        { field: 'price', headerName: 'Price', flex: 1  },
        { field: 'total_price', headerName: 'Total Price',flex: 1 },
        {
             field: 'quantity',
             headerName: 'Quantity',
            flex: 1 ,
             renderCell: (params,index) => (
                <input
                type="number"
                    className="form-control"
                    placeholder="Qty"
                    value={params.row.quantity}
                    onChange={e=>handleChangeQuanity(e.target.value,params.row.product_id)}
                  />
             )
        },
        {
            field: ' ',
            headerName: 'Actions',
            flex:1,
            renderCell: (params) => (
              <div>
                <button style={buttonStyle2}  onClick={(e) => handleDelete(e,params.row.product_id)}>Remove</button>
              </div>
            ),
          },
         
      ];

     


    useEffect(() => {
        dispatch(getMachine());
        dispatch(getProductType());
        dispatch(getProduct());

    }, []);

    useEffect(() => {
        console.log('here is machines data', Machines);
        console.log('here is product type data', ProductType)
        console.log('here is products data', Products)

    }, [Machines,ProductType]);

    const handleMachineChange = (event) => {
         setMachine_id(event.target.value)
    };

    const handleSubmit = (event) => {
         event.preventDefault()
         console.log('All selected data........',selected)
        // let totalPayment=0
        // selected.map((item) => {totalPayment = parseFloat(totalPayment) + parseFloat(item.total_price)});
        let products = []
        selected.map(item => {

            let temp = {
                product_id: item.product_id,
                price: item.price,
                quantity: item.quantity,
                total_price: item.total_price
              }
            products.push(temp);
        })

        let data={
            machine_id:machine_id,
            total:totalPayment,
            products:products
        }
      
        if(selected.length!=0){
            console.log(data)
            dispatch(addOrder(data)).then((res) => {
                console.log('here is add order data',res)
                // navigate("/machine");
              });
    
        }

        
         
    };

    const handleChangeQuanity = (e,id) => {
           let temp = [...selected]
           const i = selected.findIndex(item => item.product_id == id)
           temp[i].quantity = e;
           temp[i].total_price = temp[i].price * e;
           setSelected(temp);
           let total=0
           selected.map((item) => {total = parseFloat(total) + parseFloat(item.total_price)});
           setTotalPayment(total)
    }
    
    const checkSelected=(id)=>{
        console.log("selected",selected,id);
        const i = selected.findIndex(item => item.product_id == id)
        if(i!=-1){
         return <CheckCircleOutlineIcon  className='customicon' />
        }
        return  null
    }

    const handleSelect = (item) => {
        if(selected.length > 0 && selected.find(ele => ele.product_id == item.id)) {
           const i = selected.findIndex(ele => ele.product_id == item.id)
           let temp = [...selected]
           
           temp[i].quantity = parseFloat(temp[i].quantity) +1;
           temp[i].total_price = temp[i].price * temp[i].quantity
           setSelected(temp)

        } else {
            const temp = {
                product_id: item.id,
                name: item.product_name,
                price: item.retail_price,
                quantity: 1,
                total_price: item.retail_price
               }
            setSelected([...selected,temp]);
        }

        
    }
    
    useEffect(()=>{
        let total=0
        selected.map((item) => {total = parseFloat(total) + parseFloat(item.total_price)});
        setTotalPayment(total)
    },[selected])

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">Orders</h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="!#" onClick={(event) => event.preventDefault()}>
                                Orders
                            </a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Add Order
                        </li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Update Order</h4>
                            <form className="forms-sample" >
                                <Form.Group>
                                    <label htmlFor="exampleInputConfirmPassword1">
                                        Machine
                                    </label>
                                    {/* <select value={Machines.value} onChange={e => setOrder({ ...order, machine_id: e.target.value })} name="productType" className="form-control">
                                        <option value={0} >Select the Following Option</option>
                                        {Machines?.map(item => (
                                            <option value={item.id}>{item.machine_model.title}</option>))}
                                    </select> */}
                                     <select value={Machines.value} onChange={handleMachineChange} name="productType" className="form-control selectDropdown">
                                        <option value={0} >Select the Following Option</option>
                                        {Machines?.map(item => (
                                            <option value={item.id}>{item.machine_model.title}</option>))}
                                    </select>
                                </Form.Group>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        {ProductType?.map(item => (
                                            <Form.Group>
                                            <Accordion expanded={expandedAccordion1 == item.id} onChange={()=>handleAccordion1Change(item.id)}>
                                               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                               <h4>{item.title}</h4>
                                                   
                                               </AccordionSummary>
                                               <AccordionDetails>
                                                   <Typography>
                                                   <div className='row'>
                                                   {Products?.map(product => (
                                                    item.id == product.type_of_product_id  &&
                                                     <div className='col-lg-3 col-md-4 '>
                                                    <div  className='cardStyle'>
                                                       <Card sx={{ maxWidth: 345, marginBottom:'20px'  }}  onClick = {()=> handleSelect(product)}>
                                                           <CardMedia
                                                               sx={{ height: 140 }}
                                                               // image="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286"
                                                            //    image={'/images/perfume.webp'}
                                                               image={product.product_image.original_url}
                                                               title="green iguana"
                                                              
                                                           />
                                                           <CardContent>
                                                           <div className='card-content'>
                                                              <h4>{product.product_name}</h4>
                                                              <h4>{product.currency.currency_icon}{product.retail_price}</h4>
                                                              {/* <Typography>Price: {product.currency.currency_icon}{product.retail_price}</Typography>  */}
                                                              {/* <Typography>{product.currency.currency_icon}{product.retail_price}</Typography>  */}
                                                             
                                                           </div>
                                                           <div>
                                                            {checkSelected(product.id)}
                                                           </div>
                                                           {/* <div gutterBottom variant="h5" component="div" sx={{textAlign:'center'}}>
                                                                 <Typography>Price: {product.currency.currency_icon}{product.retail_price}</Typography> 
                                                           </div>
                                                                */}
                                                               
                                                           </CardContent>
                                                            
                                                       </Card>
                                                       </div>
                                                       </div>
                                                   ))
                                                   }
                                                    </div>
                                                   </Typography>
                                               </AccordionDetails>
                                           </Accordion>
                                       </Form.Group>
                                         ))
                                        }
                                       
                                    </div>
                                </div>
                                <Form.Group>
                                    <h4 className="card-title">Item Selected</h4>
                                    <div style={{ height: 400, width: '100%' }}>
                                                        <DataGrid
                                                            rows={selected}
                                                            columns={columns}
                                                            pageSizeOptions={[0, 0]}
                                                            getRowId = {row => row.product_id}

                                                        />
                                                        </div>
                                </Form.Group>

                                <button type="submit" className="btn btn-gradient-primary mr-2" onClick={handleSubmit}>
                                    Submit
                                </button>
                                <button className="btn btn-light" onClick={() => navigate('/allusers')}>Cancel</button>
                                <button className="btn btn-light totalbtn"   onClick={() => navigate('/allusers')}>Total: {totalPayment} </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


function deepClone(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }
    
    let clone;
    
    if (Array.isArray(obj)) {
      clone = [];
      for (let i = 0; i < obj.length; i++) {
        clone[i] = deepClone(obj[i]);
      }
    } else {
      clone = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          clone[key] = deepClone(obj[key]);
        }
      }
    }
    
    return clone;
  }