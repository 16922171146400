import React, { Component, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import { addProduct } from "../Store/Features/Product/Product"
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom"
import { getAnProduct, updateProduct } from '../Store/Features/Product/Product';
 
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import parse from 'html-react-parser'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getCategories } from '../Store/Features/MachinesAssets/MachinesAssets';
import { getAssetStatuses } from '../Store/Features/MachinesAssets/MachinesAssets';
import { addAsset,uploadAssetImage , getAnAsset ,updateAsset} from '../Store/Features/MachinesAssets/MachinesAssets';
import { getMachine } from "../Store/Features/Machine"


export const AddAsset = () => {
  const { slug } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [categories, setCategories] = useState([]);
  const [machines, setMachines] = useState([]);
  const [assetStatuses, setStatuses] = useState([]);
  // const productCompany = useSelector(state => state.productCompany.value)
  // const productStrataCodeName = useSelector(state => state.productStrataCodeName.value)
  // const productFamily = useSelector(state => state.productFamily.value)
  // const ProductType = useSelector(state => state.ProductTypeSlice.value)
  useEffect(() => {
    dispatch(getCategories()).then((res) => {
      console.log('here is categories data...........', res.payload.data)
      setCategories(res.payload.data)
    });
    dispatch(getMachine()).then((res) => {
      console.log('here is machines data...........', res.payload.data)
      setMachines(res.payload.data)
    });
    dispatch(getAssetStatuses()).then((res) => {
      console.log('here is Asset Status data...........', res.payload.data)
      setStatuses(res.payload.data)
    });



  }, [])

  useEffect(() => {
    console.log('here is productImage array...........', productImage)
  }, [])


  //   state = {
  //     startDate: new Date()
  //   };

  // const  handleChange = (date) => {
  //     this.setState({
  //       startDate: date
  //     });
  //   };
  const [images, setImages] = useState([]);
  const [manufacturerName, setManufacturerName] = useState("")
  const [manufacturerTitle, setManufacturerTitle] = useState("")
  // const [productImages, setProductImages] = useState()
  const [productImage, setProductImage] = useState([])
  const [product, setProduct] = useState({
    product_name: "",
    product_code: "",
    product_code_in_machine: "",
    barcode: "",
    product_image: "",
    type_of_product_id: "",
    product_code_company: "",
    units_from_matrix_product: "",
    matrix_product: "",
    distributor_id: "",
    currency_id: "",
    purchase_price: "",
    segment: "",
    retail_price: "",
    rounding_unit: "",
    company_id: "",
    family_id: "",
    strator_code_id: "",

  })
  const [assetData, setAssetData] = useState({
    name: "",
    machine_id: "",
    category_id: "",
    serial_number: "",
    photos:[],
    status_id: "",
    notes: ""
  })

  const submitImage = (e) => {
    e.preventDefault()
    handleFile(e)
    // let temp = [...images];
    // temp.push(e.target.files[0]);
    // setImages(temp);

    let formData = new FormData();
    formData.append("file", e.target.files[0])
    console.log('here is image data', formData)

    dispatch(uploadAssetImage(formData)).then((data) => {
      console.log(data.payload);
      
      let tempData = [...assetData.photos];
      tempData.push(data.payload.name);
      console.log('here is image data res', data.payload.name)
      setAssetData({ ...assetData, photos: tempData })
      // setAssetData({...assetData.photos.push(data.payload.name)})
    })


  }
  const handleFile = (e) => {
    console.log("path",e)
    const objectURL = URL.createObjectURL(e.target.files[0] ? e.target.files[0]  : e);
    console.log('objectURL',objectURL)
    let tempData = [...images];
    tempData.push(objectURL);
    setImages(tempData);
  };

  const handleImageDelete = (index)=> {
    let tempData = [...assetData.photos];
      tempData.splice(index,1);
    
      setAssetData({ ...assetData, photos: tempData });

    let temp = [...images];
    temp.splice(index,1);
    setImages(temp)

  }

  // const getImagePath = (path) => {
  //   if (path.original_url) {
  //     console.log("original_path", path.original_url);
  //     return path.original_url;
  //   } else {
      
  //     return handleFile(path);
  //   }
  // };

  // const handleFile = (e) => {
  //   console.log("here is file img url", e);
  //   const objectURL = URL.createObjectURL(e);
  //   console.log("here is file img url", objectURL);
  //   return objectURL;
  // };

  // useEffect(() => {
  //   if (slug) {
  //   //   dispatch(getAnProduct(slug)).then((data) => {
  //   //     console.log('data', data);
  //   //     let tempAsset = {
  //   //       name: data.payload.data?.product_name,
  //   //       machine_id: data.payload.data?.product_code,
  //   //       category_id: data.payload.data?.product_code_in_machine,
  //   //       serial_number: data.payload.data?.barcode,
  //   //       photos: data.payload.data?.product_image?.file_name,
  //   //       status_id: data.payload.data?.type_of_product_id,
  //   //       notes: data.payload.data?.product_code_company,
           
  //   //     }
  //   //     setProductImage(data.payload.data?.product_image?.original_url)
  //   //     setProduct(tempProduct);
  //   //   },

  //   //   )
  //   // }
  // }, [])


  const getImagePath = (path) => {
    console.log('here is image',path)
  
    if (path.original_url) {
      console.log("original_path", path.original_url);
      return path.original_url;
    } else {
      console.log("hello")
      // return handleFile(path);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault()

    console.log('here is add assets values', assetData)
    let formData = new FormData();

    Object.keys(assetData).forEach((key) => {
      if(key != 'photos')
        formData.append(key, assetData[key])
    });

    assetData.photos.map((ele,i)=>{
      formData.append('photos[]', ele)
    })
    dispatch(addAsset(assetData)).then((resp)=>{
      console.log('resp',resp)
      navigate("/admin/assets")
    })
     
  }


  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Assets</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Assets</a></li>
            <li className="breadcrumb-item active" aria-current="page">Add Assets</li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add </h4>
              {/* <p className="card-description"> Basic form layout </p> */}
              <form className="forms-sample">
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputUsername1">Asset Name</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Asset Name" value={assetData.name} onChange={(e) => setAssetData({ ...assetData, name: e.target.value })} />
                  </Form.Group>
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Serial_number</label>
                    <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Serial_number" value={assetData.serial_number} onChange={(e) => setAssetData({ ...assetData, serial_number: e.target.value })} />
                  </Form.Group>
                </Box>
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                  <Form.Group style={{ width: "100%" }}>
                    <div className="row">
                      <div className="col-sm-6">
                        <label style={{ widt: "100%" }} htmlFor="exampleInputUsername1">Assets Images </label>
                        <Form.Control type="file" multiple id="exampleInputUsername1" placeholder="Product Image " onChange={(e) => { submitImage(e) }} />
                      </div>
                      <div className="col-lg-12 col-sm-6">
                        {images.map((img,index) => {
                          return (
                            <div style={{marginLeft:'20px',position:"relative",marginBottom:'20px',marginTop:'34px',display:'inline-block' }}>
                              <div onClick = {()=>handleImageDelete(index)} style ={{position:"absolute",right:"-2px",padding:'3px',backgroundColor:'red',color:"white",borderRadius:'21px',fontSize:"14px",top:'-7px'}}>X</div>
                              <img
                                style={{ height: "70px", width: "70px",border:'1px solid lightgrey',padding:'10px' }}
                                src={img}
                              />
                            </div>
                          );
                        })}
                      </div>

                    </div>

                  </Form.Group>
                  
                </Box>
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Category </label>
                    <select value={assetData.category_id} name="productType" className="form-control" onChange={(e) => setAssetData({ ...assetData, category_id: e.target.value })}>
                      <option value={0} >Select the Following Option</option>
                      {categories?.map(item => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </Form.Group>
                  {/* <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Machine Status</label>
                  <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Matrix Product" value={product.matrix_product} onChange={(e) => setProduct({ ...product, matrix_product: e.target.value })} />
                </Form.Group> */}
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Machine Status</label>
                    {/* <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Type of Type Id" value={product.type_of_product_id} onChange={(e) => setProduct({ ...product, type_of_product_id: e.target.value })} /> */}
                    <select value={assetData.status_id} name="productType" className="form-control" onChange={(e) => setAssetData({ ...assetData, status_id: e.target.value })}>
                      <option value={0} >Select the Following Option</option>
                      {assetStatuses?.map(item => (
                        <option value={item.id}>{item?.name}</option>))}
                    </select>
                  </Form.Group>
                </Box>
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Machine</label>
                    {/* <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Type of Type Id" value={product.type_of_product_id} onChange={(e) => setProduct({ ...product, type_of_product_id: e.target.value })} /> */}
                    {slug ? (
                      <select value={slug} name="productType" className="form-control" onChange={(e) => setAssetData({ ...assetData, machine_id: e.target.value })}>
                        <option value={0} >Select the Following Option</option>
                        {machines?.map(item => (
                          <option value={item.id}>{item?.machine_model?.title}</option>
                        ))}
                      </select>
                    ) : (
                      <select value={assetData.machine_id} name="productType" className="form-control" onChange={(e) => setAssetData({ ...assetData, machine_id: e.target.value })}>
                        <option value={0} >Select the Following Option</option>
                        {machines?.map(item => (
                          <option value={item.id}>{item?.machine_model?.title}</option>
                        ))}
                      </select>
                    )
                    }
                  </Form.Group>
                  {/* <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Machine Status</label>
                  <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Matrix Product" value={product.matrix_product} onChange={(e) => setProduct({ ...product, matrix_product: e.target.value })} />
                </Form.Group> */}
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      className='ckeditor'
                      sx={{ height: "79px !important" }}
                      data="Enter your Assets Description Here!"
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const notesText = parse(data).toString(); // Make sure parse function is correct
                        console.log('Editors notes!----------------', notesText, event);
                        setAssetData({ ...assetData, notes: notesText });
                      }}
                      placeholder="Enter your Assets Description Here!"
                    />
                  </Form.Group>
                </Box>
                
                <button type="submit" className="btn btn-gradient-primary mr-2" onClick={onSubmit}>Submit</button>
                <button className="btn btn-light"  onClick={() => navigate(`/admin/assets`)}>Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

const editorConfiguration = {
  toolbar: ['bold', 'italic'],

};

const resolveImahe = (path) => {
  function isValidUrl(path) {
    try {
      new URL(path);
      return path;
    } catch (err) {
      return;
    }
  }
}