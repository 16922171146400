import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../Configuration/config";

import { toast } from 'react-toastify';
export const getMachineModel = createAsyncThunk('getMachineModel', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}modelnames`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addMachineModal = createAsyncThunk('addMachineModal', async (arg, {
    rejectWithValue
}) => {
    console.log('addMachineModal', arg);
    try {
        const { data } = await axios.post(`${baseUrl}modelnames`, arg, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnMachineModel = createAsyncThunk('getAnMachineModel', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}modelnames/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateMachineModal = createAsyncThunk('updateMachineModal', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.put(`${baseUrl}modelnames/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const deleteMachineModal = createAsyncThunk('deleteMachineModal ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}modelnames/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const machinemodelSlice = createSlice({
    name: 'MachineModel',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getMachineModel.pending, (state, action) => {
            //   toast.loading('Logging in');
            state.loading = true;
        });
        builder.addCase(getMachineModel.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Logged In');
            state.loading = false;
            state.value = action.payload.data;



        });
        builder.addCase(getMachineModel.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Promise Not Resolved');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(addMachineModal.pending, (state, action) => {
            toast.loading('Addiing Machine Modal');
            state.loading = true;
        });
        builder.addCase(addMachineModal.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Machine Modal');
            state.loading = false;




        });
        builder.addCase(addMachineModal.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to add Machine Modal');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(updateMachineModal.pending, (state, action) => {
            toast.loading('Updating Machine Modal');
            state.loading = true;
        });
        builder.addCase(updateMachineModal.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Machine Modal');
            state.loading = false;




        });
        builder.addCase(updateMachineModal.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to update Machine Modal');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(deleteMachineModal.pending, (state, action) => {
            toast.loading('Deleting Machine Modal');
            state.loading = true;
        });
        builder.addCase(deleteMachineModal.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Machine Modal');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(deleteMachineModal.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to delete Machine Modal');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        // builder.addCase(logout.pending, (state, action) => {
        //   toast.loading('Logging Out...');
        //   state.loading = true;
        // });
        // builder.addCase(logout.fulfilled, (state, action) => {
        //   toast.dismiss();

        //   state.loading = false;

        //   state.loading = false;
        //   if (action.payload.message) {
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   }

        //   localStorage.removeItem("auth");
        //   localStorage.removeItem("role");
        //   toast.success('Logged out');

        // });
        // builder.addCase(logout.rejected, (state, action) => {
        //   toast.dismiss();
        //   toast.error('Promise rejected');
        //   if (action.payload == "Network Error") {
        //     localStorage.removeItem("auth");
        //     localStorage.removeItem("role");

        //     state.loading = false;
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   } else {
        //     state.loading = false;
        //     state.isSuccess = true;
        //     state.isAuthenticated = true;
        //   }

        // });
    },
});


export default machinemodelSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };