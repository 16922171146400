import React, { Component,useState,useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import {useDispatch } from "react-redux"
import { addMachineDistributor } from '../Store/Features/MachineDistributor';
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { getAnMachineDistributor,updateMachineDistributor } from '../Store/Features/MachineDistributor';

export const AddMachineDistributor =()=>{
  const {slug} = useParams()

//   state = {
//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
const [distrubuorname,setDistrubuorname] = useState()


useEffect(()=>{
  if(slug) {
    dispatch(getAnMachineDistributor(slug)).then((data)=>{
      setDistrubuorname(data.payload.data.distributor_name)
    })
  }

},[])

const dispatch = useDispatch()
const navigate = useNavigate()
const onSubmit = (e) =>{
  e.preventDefault()
  const data = {distributor_name:distrubuorname}
  if(slug) {
    dispatch(updateMachineDistributor({slug,data})).then(()=>{
      navigate("/admin/machine/distributor")
    })
  }else {
    dispatch(addMachineDistributor(data)).then(()=>{
      navigate("/admin/machine/distributor")
    })
  }

}
   
 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Machine Distributor</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine Distributor</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Machine Distributor</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add </h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Distributor Name</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Distributor Name" value = {distrubuorname} onChange = {e=>setDistrubuorname(e.target.value)} />
                  </Form.Group>
                 
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick={onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>navigate("/admin/machine/distributor")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}