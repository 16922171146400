import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../Configuration/config";

import { toast } from 'react-toastify';

export const getMachine = createAsyncThunk('getMachine', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machines`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addMachine= createAsyncThunk('addMachine', async (arg, {
    rejectWithValue
}) => {
    console.log('addMachine', arg);
    try {
        const { data } = await axios.post(`${baseUrl}machines`,arg,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnMachine = createAsyncThunk('getAnMachine', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machines/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });
        console.log('here is update data ',data)
        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const updateMachine = createAsyncThunk('updateMachine', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.put(`${baseUrl}machines/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });
       
        return data;
    }
    catch (error) {
        console.log('here is update error ',error)
        return rejectWithValue(error.response.data.message);

    }
});
export const deleteMachine = createAsyncThunk('deleteMachine ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}machines/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const assignMachineProducts= createAsyncThunk('assignMachineProducts', async (arg, {
    rejectWithValue
}) => {
    console.log('addMachine', arg);
    try {
        const { data } = await axios.post(`${baseUrl}assign-machine-products`,arg,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const assignProductSock= createAsyncThunk('assignProductSock', async (arg, {
    rejectWithValue
}) => {
    console.log('assignProductSock', arg);
    try {
        const { data } = await axios.post(`${baseUrl}add-stock`,arg,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getMachineOders = createAsyncThunk('getMachineOders', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-orders/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });
        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getMachineProducts = createAsyncThunk('getMachineProducts', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-products/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });
        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);
    }
});

export const getMachineAssets = createAsyncThunk('getMachineAssets', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-assets/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });
        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);
    }
});


export const machineSlice = createSlice({
    name: 'Machine',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getMachine.pending, (state, action) => {
            //   toast.loading('Logging in');
            state.loading = true;
        });
        builder.addCase(getMachine.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Logged In');
            state.loading = false;
            state.value = action.payload.data;



        });
        builder.addCase(getMachine.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Promise Not Resolved');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(addMachine.pending, (state, action) => {
              toast.loading('Adding Machine');
            state.loading = true;
        });
        builder.addCase(addMachine.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Machine');
            state.loading = false;
            



        });
        builder.addCase(addMachine.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to add machine');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(updateMachine.pending, (state, action) => {
            toast.loading('Updating Machine Commision Period');
            state.loading = true;
        });
        builder.addCase(updateMachine.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Machine Commision Period');
            state.loading = false;




        });
        builder.addCase(updateMachine.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to update Machine Commision Period');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(deleteMachine.pending, (state, action) => {
            toast.loading('Deleting Machine Commision Period');
            state.loading = true;
        });
        builder.addCase(deleteMachine.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Machine Commision Period');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(deleteMachine.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to delete Machine Commision Period');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        //assign product

        builder.addCase(assignMachineProducts.pending, (state, action) => {
            toast.loading('Assigning Machine Product');
            state.loading = true;
        });
        builder.addCase(assignMachineProducts.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Assigned Product to Machine Sucessfully');
            state.loading = false;
         });
         builder.addCase(assignMachineProducts.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Error While Assigning Product to Machine');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(assignProductSock.pending, (state, action) => {
            toast.loading('Adding Machine Products stock');
            state.loading = true;
        });
        builder.addCase(assignProductSock.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Machine Products stock Sucessfully');
            state.loading = false;
         });
         builder.addCase(assignProductSock.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Error While Adding Machine Products');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        
    },
});


export default machineSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };