import React, { Component,useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import { getAuth } from "../Store/Features/AuthSlice";

export const SignUp =()=> {
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const navigate = useNavigate()
  const dispatch  = useDispatch()
  const handleSubmit = (event) => {
    console.log('inside submit')
    event.preventDefault();

    // var { email, password } = document.forms[0];

    console.log('email and password',email,password)

    if (email && password) {
      dispatch(getAuth({
        email: email.value,
        password: password.value
      })).then((data) => {
        console.log('here is login data')
        navigate('/dashboard');
      }).catch((err)=>{
        console.log('here is login data err')
      })
   } 
  }


    return (
      <div style={{marginTop:'150px'}}>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                  {/* <img src={require("../assets/images/logo.svg")} alt="logo" /> */}
                  <h5>Vending Machine</h5>
                </div>
                <h4>Sign up</h4>
                {/* <h6 className="font-weight-light">Signing up is easy. It only takes a few steps</h6> */}
                <form className="pt-3">
                  <div className="form-group">
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" className="form-control form-control-lg" id="exampleInputUsername1" placeholder="Email" />
                  </div>
                  <div className="form-group">
                    <input value={password} onChange={(e)=>setPassword(e.target.value)} type="password" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Password" />
                  </div>
                  {/* <div className="form-group">
                    <select className="form-control form-control-lg" id="exampleFormControlSelect2">
                      <option>Country</option>
                      <option>United States of America</option>
                      <option>United Kingdom</option>
                      <option>India</option>
                      <option>Germany</option>
                      <option>Argentina</option>
                    </select>
                  </div> */}
                  {/* <div className="form-group">
                    <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" />
                  </div> */}
                  {/* <div className="mb-4">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" />
                        <i className="input-helper"></i>
                        I agree to all Terms & Conditions
                      </label>
                    </div>
                  </div> */}
                  <div className="mt-3">
                    <Link type='submit' onClick={handleSubmit}  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN UP</Link>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Already have an account? <Link to="/login" className="text-primary">Login</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}

 
