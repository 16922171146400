import React, { useState,useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAssets} from '../Store/Features/PublicApis/Assets';
import { getCategories } from '../Store/Features/PublicApis/Categories';
import CircularProgress from '@mui/material/CircularProgress';

export const AssetsTabs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector((state) => state.PublicCategory.value);
  const assets = useSelector((state) => state.PublicAssets.value);
  const loading = useSelector((state) => state.PublicAssets.loading);
  const [apiCall_Loader, setapiCall_Loader] = useState(false);

  const [value, setValue] = React.useState();
  const [categoryId, setCategory] = React.useState(1);
  const [assetId, setAssetId] = React.useState(65);

  useEffect(() => {
    setapiCall_Loader(true)
    dispatch(getAssets());
    dispatch(getCategories()).then((res) => {
        setapiCall_Loader(false)
    })
  }, []);

  useEffect(() => {
    console.log('here is assets data', assets);
  }, [assets]);

  useEffect(() => {
    console.log('here is categories data', categories);
  }, [categories]);

  useEffect(() => {
    console.log('here is asset id..................', assetId);
  }, [assetId]);

  // Filter categories that have associated assets
  const categoriesWithAssets = categories.filter((category) =>
    assets.some((asset) => asset.category_id === category.id)
  );

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <img src='/images/vapora.png' className="logoImg" alt="" />
        </div>
      </section>

      <div className="container mt-3">
      {apiCall_Loader ? (
                        <Box sx={{height:"20px",textAlign:'center'}} > 
                            <CircularProgress />
                            </Box >
                        ):(null)
                        }
        <Box sx={{ width: '100%', marginBottom: '30px' }}>
          <Tabs
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              setCategory(newValue);
            }}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            {categoriesWithAssets.map((item) => (
              <Tab key={item.id} value={item.id} label={item.name} />
            ))}
          </Tabs>
           
          {/* <a href="#" class="previous round">&#8249;</a> */}
            
          <Box sx={{ width: '100%', marginTop: '30px' }}>
            
            <div className="row">
              {assets.map((item) => {
                if (item.photos[0]?.original_url && item.category_id === categoryId) {
                  return (
                    <div
                      className={`col-lg-3 col-md-4 col-sm-6 mb-4 ${
                        categoryId === item.category_id ? 'd-block' : 'd-none'
                      }`}
                      key={item.id}
                    >
                      <div className="card">
                        <img
                          src={item.photos[0].original_url}
                          className="card-img-top"
                          alt={item.id}
                          style={{ height: '200px', objectFit: 'cover' }}
                          onClick={() => navigate(`/admin/assetsInfo/${item.id}`)}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{item.name}</h5>
                          <p className="card-text">{item.description}</p>
                          {/* <button
                            className="btn btn-primary"
                            onClick={() => navigate(`/admin/assetsInfo/${item.id}`)}
                          >
                            View Details
                          </button> */}
                          <button
                            className="btn btnStyle"
                            onClick={() => navigate(`/admin/assetsInfo/${item.id}`)}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </Box>
        </Box>
      </div>

      <section className="content-header">
        <div className="container-fluid">
          <p>Footer</p>
        </div>
      </section>
    </div>
  );
};
