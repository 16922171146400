import React, {useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {getMachine, deleteMachine} from "../Store/Features/Machine"
import {useDispatch,useSelector} from "react-redux"
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function CustomToolbar() {
    return (
      <GridToolbarContainer>
         <h4 className="card-title" style = {{margin: 20}}>All Machine </h4>
      </GridToolbarContainer>
    );
  }



const rows = [
  { id: 1, title: 'Snow', manufacturertitle: 'Jon' },
  { id: 2, title: 'Lannister', manufacturertitle: 'Cersei' },
  { id: 3, title: 'Lannister', manufacturertitle: 'Jaime' },
];

export function Machine() {
  const navigate = useNavigate()
  const dispatch  = useDispatch()
  const Machine = useSelector(state => state.Machine.value)
  const Loading = useSelector(state => state.Machine.loading)
  
    useEffect(()=>{
      dispatch(getMachine())
    },[])


    const handleActions=(event,row)=>{
      if(event.target.value=='view'){
        handleViewMachine(row)
      }else if(event.target.value=='assignProduct'){
        handleAssignProduct(row)
      }else if(event.target.value=='addProductStock'){
        handleAddProductStock(row)
       }else if(event.target.value=='edit'){
       handleEdit(row)
      }else if(event.target.value=='delete'){
       handleDelete(row)
      }else{
 
      }
   }

  const handleAssignProduct = (row) => {
    navigate(`/admin/assignproduct/${row.id}`);
    console.log('View:', row);
  };
  

  const handleViewMachine = (row) => {
    navigate(`/admin/machine/view/${row.id}`);
  };
  const handleAddProductStock = (row) => {
    navigate(`/admin/assignproductstock/${row.id}`);
    console.log('View:', row);
  };

  const handleEdit = (row) => {
    navigate(`/admin/machine/${row.id}`)
    console.log('Edit:', row);
  };

  const handleDelete = (row) => {
    dispatch(deleteMachine(row.id)).then(() => {
      dispatch(getMachine())
    });
    console.log('Delete:', row);
  };
  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
 
 const columns = [
    
    { field: 'id', headerName: 'ID', flex: 1 },
    { 
      field: 'product_type', 
      headerName: 'Product Type', 
      flex: 1,
      valueGetter: (params) => params.row.product_type?.title
    },
    { field: 'manufacturer_id', headerName: 'Manufacturer', flex: 1  },
    { 
      field: 'machine_model', 
      headerName: 'Machine Model', 
      flex: 1,
      valueGetter: (params) => params.row.machine_model.title
    },
    // { field: 'machine_code', headerName: 'Machine Code', flex: 1  },
    { field: 'provisional_serial_number', headerName: 'Provisional Serial Number', flex: 1  },
    { field: 'icc', headerName: 'ICC', flex: 1  },
    { field: 'year_of_manufacturing', headerName: 'Year Of Manufacturing', flex: 1  },
    { field: 'establishment_id', headerName: 'Establishment Id', flex: 1  },

    {
      field: 'actions',
      headerName: 'Actions',
      flex:1.3,
      renderCell: (params) => (
        <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
            <InputLabel  >Actions</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label= "Actions"
              onChange={e => handleActions(e,params.row)}
              className={buttonStyle}
            >
              <MenuItem value={''}>None</MenuItem>
              <MenuItem value={'view'}>View</MenuItem>
              <MenuItem value={'assignProduct'}>Assign Product</MenuItem>
              <MenuItem value={'addProductStock'}>Add Product Stock</MenuItem>
              <MenuItem value={'edit'}>Edit</MenuItem>
              {/* <MenuItem value={'delete'}>Delete</MenuItem> */}
            </Select>
          </FormControl>
        // <div>
        //   <button style={buttonStyle} onClick={() => handleView(params.row)}>Assign Product</button>
        //   <button style={buttonStyle} onClick={() => navigate("/assignproductstock/"+params.row.id)}>Add Product Stock</button>
        //   <button style={buttonStyle1} onClick={() => handleEdit(params.row)}>Edit</button>
        //   <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
        // </div>
      ),
    },
  ];

  return (
    
    <div style={{ height: 400, width: '100%' }}>
        <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> Machine  </h3>

          <button type="button" className="btn btn-gradient-success " onClick = {()=>navigate("/admin/machine/add")}>Add Machine </button>
        </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine </a></li>
              <li className="breadcrumb-item active" aria-current="page">All Machine</li>
            </ol>
          </nav>
        </div>
       
            <DataGrid
                rows={Machine}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                loading = {Loading}
                sx={{ backgroundColor: "white" }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{
                    toolbar: CustomToolbar,
                  }}
            />
        
        </div>
  );
}