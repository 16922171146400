import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../Configuration/config";

// import { toast } from 'react-toastify';
export const getAuth = createAsyncThunk('auth/getData', async (arg, {
  rejectWithValue
}) => {
  try {
    const { data } = await axios.post(`${baseUrl}login`, arg);

    return data;
  }
  catch (error) {
    console.log('error in  base url',error)
    return rejectWithValue(error.response.data.message);
    

  }
});

export const addUser = createAsyncThunk('auth/addUser', async (arg, {
    rejectWithValue
  }) => {
    try {
      const { data } = await axios.post(`${baseUrl}login`, arg);
      console.log('base url',baseUrl)
      return data;
    }
    catch (error) {
      console.log('error in  base url',error)
      return rejectWithValue(error.response.data.message);
  
    }
  });

export const logout = createAsyncThunk('auth/logout', async (arg, {
  rejectWithValue
}) => {
  try {
    const { data } = await axios.get(`${baseUrl}logout`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`
      }
    });
    return data;
  }
  catch (error) {

    return rejectWithValue(error.message);

  }
});
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    value: '',
    loading: false,
    message: "",
    isSuccess: false,
    isAuthenticated: false,
    role: null,
    value: {}
  },
  reducers: {

  },

  extraReducers: (builder) => {
    builder.addCase(getAuth.pending, (state, action) => {
    //   toast.loading('Logging in');
      state.loading = true;
    });
    builder.addCase(getAuth.fulfilled, (state, action) => {
      // toast.dismiss();
      // toast.success('Logged In');
      state.loading = false;
      
      state.value = action.payload;
      state.role = action.payload.role;
      if (action.payload) {
        state.isSuccess = true;
        state.isAuthenticated = true;
      }
      // console.log('here is login payload value',action.payload.data.user)
      localStorage.setItem("user_id", action.payload.data.user.id)
      localStorage.setItem('auth', action.payload.access_token);
      localStorage.setItem("role", checkAdminRoles(action.payload.data.user.roles));

    });
    builder.addCase(getAuth.rejected, (state, action) => {
      // toast.dismiss();
      // toast.error('Promise Not Resolved');
      state.loading = false;
      state.message = action.payload;
      state.isSuccess = false;
    });
    // builder.addCase(logout.pending, (state, action) => {
    //   toast.loading('Logging Out...');
    //   state.loading = true;
    // });
    // builder.addCase(logout.fulfilled, (state, action) => {
    //   toast.dismiss();

    //   state.loading = false;

    //   state.loading = false;
    //   if (action.payload.message) {
    //     state.isSuccess = false;
    //     state.isAuthenticated = false;
    //   }

    //   localStorage.removeItem("auth");
    //   localStorage.removeItem("role");
    //   toast.success('Logged out');

    // });
    // builder.addCase(logout.rejected, (state, action) => {
    //   toast.dismiss();
    //   toast.error('Promise rejected');
    //   if (action.payload == "Network Error") {
    //     localStorage.removeItem("auth");
    //     localStorage.removeItem("role");

    //     state.loading = false;
    //     state.isSuccess = false;
    //     state.isAuthenticated = false;
    //   } else {
    //     state.loading = false;
    //     state.isSuccess = true;
    //     state.isAuthenticated = true;
    //   }

    // });
  },
});


export default authSlice;


const checkAdminRoles = (roles) => {
  const checkAdmin = roles.some(item => {
    return item.id == 1;
  });
  if (checkAdmin) {
    return 1;
  }
  else {
    return roles[0].id;
  }
};