// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from "react-redux";
import store from "./Store/Store";
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import "./index.css"
let persistor = persistStore(store)
// import "./i18n";
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter basename="/">
  
   <Provider store={store}>
   <PersistGate persistor={persistor}>
     <App />
     </PersistGate>
   </Provider>
   
  </BrowserRouter>
, document.getElementById('root'));

// serviceWorker.unregister();
