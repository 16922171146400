import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux"
import { addAssetStatus, updateAssetStatus, getAnStatus } from '../Store/Features/MachinesAssets/MachinesAssets';
import { useParams, useNavigate } from 'react-router-dom';
import { Category } from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AllOrders } from '../orders/AllOrders';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { getOrders, deleteOrder } from "../Store/Features/Order/Order";
import {getMachineOders} from '../Store/Features/MachineOrders';
import {getMachineAssets} from '../Store/Features/MachineAssets';
import {getMachineProducts} from '../Store/Features/MachineProducts';
import {deleteProduct} from "../Store/Features/Product/Product"
import {deleteAssets} from '../Store/Features/MachinesAssets/MachinesAssets';
 


export const ViewMachine = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { slug } = useParams()
    const MachineProduct = useSelector(state => state.machineProducts.value)
    const Orders = useSelector(state => state.machineOders.value)
    const assets = useSelector(state => state.Assets.value)
    const productLoader = useSelector(state => state.machineProducts.loading)
    const ordersLoader = useSelector(state => state.machineOders.loading)

    const [status, setStatus] = useState("")
    const [addbtn, setBtn] = useState("")
    const [value, setValue] = React.useState('products');
    const [loader, setLoader] = useState()
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])


    const buttonStyle2 = {
        marginRight: '5px',
        padding: '5px 10px',
        backgroundColor: '#DC3545',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    };


    function AddButton() {
        if(addbtn=='orders'){
            return (
              <button type="button" className="btn btn-gradient-success" onClick={() => navigate(`/admin/addorder/${slug}`)}>Add Orders </button>
            );
        }else if(addbtn=='products'){
            console.log('here is products ',addbtn)
            return (
                <button type="button" className="btn btn-gradient-success" onClick = {()=>navigate(`/admin/machine/product/add/${slug}`)}>Add Products </button>
              );
        }else if(addbtn=='assets'){
            return (
                <button type="button" className="btn btn-gradient-success" onClick = {()=>navigate(`/admin/assets/add/${slug}`)}>Add Assets </button>
              );
        } 
      }

      const handleActions=(event,row,type)=>{

         if(event.target.value=='edit'&& type=='products'){
          console.log('product row-------------->>', row);
          handleProductEdit(event,row)
        }else if(event.target.value=='edit'&& type=='assets'){
          handleAssetsEdit(event,row)
        }
        
        if(event.target.value=='delete'&& type=='products'){
          handleProductDelete(row)
        }else if(event.target.value=='delete'&& type=='assets'){
          handleAssetDelete(row)
        }
     }

     const handleView = (row) => {
      console.log('View:', row);
    };
  
    const handleProductEdit = (e,row) => {
      navigate(`/admin/product/edit/${row.product_id}`)
    };
    const handleAssetsEdit = (e,row) => {
      navigate(`/admin/assets/edit/${row.id}`)
    };
    
    const handleProductDelete = (row) => {
      dispatch(deleteProduct(row.id)).then(() => {
        dispatch(getMachineProducts(slug))
      });
    };

    const handleAssetDelete = (row) => {
      dispatch(deleteAssets(row.id)).then((res) => {
        console.log('here is delete asset resp',res)
        dispatch(getMachineAssets(slug))
      });
    };

    const handleDelete = (row) => {
      console.log('here is inside order ')
      dispatch(deleteOrder(row.id)).then((res) => {
        console.log('here is delete order resp',res)
        //  dispatch(getMachineOders())
      });
    }



    const handleChange = (event, newValue) => {
        if (newValue == 'orders') {
            setBtn('orders')
            const orderColumns =[
                { field: 'id', headerName: 'Order-ID', flex: 1 },
                { field: 'machine_id', headerName: 'Machine-ID', flex: 1 },
                { field: 'total', headerName: 'Total Payment', flex: 1 },
                // {
                //   field: 'actions',
                //   headerName: 'Actions',
                //   flex: 1,
                //   renderCell: (params) => (
                //     <div>
                //       <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
                //     </div>
                //   ),
                // },
              ] ;
              setLoader(ordersLoader)
              setRows(Orders)
              setColumns(orderColumns)
             

        } else if (newValue == 'assets') {
            setBtn('assets')
            const assetsColumns = [
                { field: 'id', headerName: 'ID', flex: 1 },
                { field: 'name', headerName: 'Asset Name', flex: 1  },
                { field: 'machine_id', headerName: 'Machine_ID',flex: 1 },
                { field: 'category', headerName: 'Category', flex: 1,
                 renderCell: (params) => {
                  return <a>{params.row.category?.name}</a>
                 }
                },
                { field: 'status', headerName: 'Status', flex: 1,
                 renderCell: (params) => {
                  return <a>{params.row.status?.name}</a>
                 }
                },
                { field: 'serial_number', headerName: 'Serial_number', flex: 1,
                 renderCell: (params) => {
                  return <a>{params.row.serial_number}</a>
                 }
                },
                {
                    field: 'notes',
                    headerName: 'Description',
                   flex: 1 ,
                    // renderCell: (params,index) => (
                    //    <input
                    //    type="text"
                    //        className="form-control"
                    //        placeholder="Description"
                    //        value={params.row.quantity}
                    //        readOnly
                    //      />
                    // )
                    
               },
                {
                  field: 'actions',
                  headerName: 'Actions',
                  flex: 1 ,
                  renderCell: (params) => (
                    <div>
                       <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
                        <InputLabel  >Actions</InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          label= "Actions"
                          onChange={e => handleActions(e,params.row,'assets')}
                          className={buttonStyle2}
                        >
                          <MenuItem value={'edit'}>Edit</MenuItem>
                          {/* <MenuItem value={'delete'}>Delete</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                  ),
                },
              ];

              setLoader(productLoader)
              setRows(assets)
              setColumns(assetsColumns)
    

        } else if (newValue == 'products') {
            setBtn('products')
            const productColumn = [
                { field: 'id', headerName: 'ID', flex: 1 },
                { field: 'product_code', headerName: 'Product_Code',flex: 1, renderCell: (params) => {
                    return <a>{params.row.product?.product_code}</a> 
                  }  
              },
                    
                    { field: 'product_name', headerName: 'Product_Name',flex: 1, renderCell: (params) => {
                          return <a>{params.row.product?.product_name}</a> 
                        }  
                    },
                    { field: 'distributor_id', headerName: 'Distributor_Id',flex: 1, renderCell: (params) => {
                        return <a>{params.row.product?.distributor_id}</a> 
                      }  
                     },
                    { field: 'purchase_price', headerName: 'Purchase Price', flex: 1 ,
                    renderCell: (params) => {
                        return <a>{params.row.product?.purchase_price}</a> 
                      } },
                    { field: 'retail_price', headerName: 'Retail Price', flex: 1 ,
                    renderCell: (params) => {
                        return <a>{params.row.product?.retail_price}</a> 
                      } },
                     
                    {
                      field: 'actions',
                      headerName: 'Actions',
                      flex: 1 ,
                      renderCell: (params) => (
                       
                        <div>
                           <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
                            <InputLabel  >Actions</InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              label= "Actions"
                              onChange={e => handleActions(e,params.row,'products')}
                              className={buttonStyle2}
                            >
                              <MenuItem value={'edit'}>Edit</MenuItem>
                              {/* <MenuItem value={'delete'}>Delete</MenuItem> */}
                            </Select>
                          </FormControl>
                        </div>
                      ),
                    },
              ];
            
              setLoader(productLoader)
              setRows(MachineProduct)
              setColumns(productColumn)
              

        }
        console.log('here is tab value', newValue)
        setValue(newValue);
    };



    useEffect(() => {
      const productColumn = [
        { field: 'id', headerName: 'ID', flex: 1,renderCell: (params) => {
          return <a>{params.row.product?.id}</a> 
        }   },
        { field: 'product_code', headerName: 'Product_Code',flex: 1, renderCell: (params) => {
            return <a>{params.row.product?.product_code}</a> 
          }  
      },
            
            { field: 'product_name', headerName: 'Product_Name',flex: 1, renderCell: (params) => {
                  return <a>{params.row.product?.product_name}</a> 
                }  
            },
            { field: 'distributor_id', headerName: 'Distributor_Id',flex: 1, renderCell: (params) => {
                return <a>{params.row.product?.distributor_id}</a> 
              }  
             },
            { field: 'purchase_price', headerName: 'Purchase Price', flex: 1 ,
            renderCell: (params) => {
                return <a>{params.row.product?.purchase_price}</a> 
              } },
            { field: 'retail_price', headerName: 'Retail Price', flex: 1 ,
            renderCell: (params) => {
                return <a>{params.row.product?.retail_price}</a> 
              } },
            
            {
              field: 'actions',
              headerName: 'Actions',
              flex: 1 ,
              renderCell: (params) => (
               
                <div>
                   <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
                    <InputLabel  >Actions</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label= "Actions"
                      onChange={e => handleActions(e,params.row,'products')}
                      className={buttonStyle2}
                    >
                      <MenuItem value={'edit'}>Edit</MenuItem>
                      {/* <MenuItem value={'delete'}>Delete</MenuItem> */}
                    </Select>
                  </FormControl>
                </div>
              ),
            },
      ];
      setLoader(productLoader)
      setRows(MachineProduct)
      setColumns(productColumn)
  }, [])


    useEffect(() => {
        dispatch(getMachineProducts(slug)).then((data)=>{
            console.log('here is===============================================================')
            console.log('here is machine products....................',data)
        })
        dispatch(getMachineAssets(slug)).then((res)=>{
            console.log('here is machine Assets',res)
        })
        dispatch(getMachineOders(slug)).then((res)=>{
            console.log('here is orders',res)
        })
    }, [])

    useEffect(() => {
        // console.log('here is product data', product)
        console.log('here is order data...........', Orders)
        console.log('here is products data....123.......',MachineProduct)
        console.log('here is Assets data...........', Orders)
    }, [MachineProduct])




    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">View Machine</h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine</a></li>
                        <li className="breadcrumb-item active" aria-current="page">View Machine</li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Machine Info</h4>
                            <button type="button" className="btn btn-gradient-success " onClick={() => navigate(`/admin/assignproduct/${slug}`)}>Assign Product</button>
                            <button type="button" className="btn btn-gradient-success " onClick={() => navigate(`/admin/assignproductstock/${slug}`)}>Assign Product Stock</button>
                            <form className="forms-sample">
                                <Form.Group>
                                    <Box sx={{ width: '100%',marginBottom:'30px' }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            textColor="secondary"
                                            indicatorColor="secondary"
                                            aria-label="secondary tabs example"
                                        >
                                            <Tab value="products" label="Products" Selected />
                                            <Tab value="orders" label="orders" />
                                            <Tab value="assets" label="Assets" />

                                        </Tabs>

                                        <Box sx={{ width: '100%',marginTop:'30px' }}>
                                          {/* {addbtn=='orders' ? (
                                             <button type="button" className="btn btn-gradient-success">Add Products </button>
                                             ):(null)
                                            }
                                          
                                           {addbtn=='products' ? (
                                             <button type="button" className="btn btn-gradient-success">Add Orders </button>
                                             ):(null)
                                            } */}
                                            {AddButton()}
                                       
                                        </Box>
                                    </Box>
                                    <DataGrid
                                        loading={ordersLoader}
                                        rows={rows}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 20 },
                                            },
                                        }}
                                        sx={{ backgroundColor: "white" }}
                                        checkboxSelection
                                        slots={{
                                            // toolbar: CustomToolbar,
                                        }}
                                    />
                                </Form.Group>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}