import React, { Component ,useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import {addManufacturer} from "../Store/Features/Manufacture"
import {useDispatch} from "react-redux"
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { getAnManufacturer,updateManufacturer } from '../Store/Features/Manufacture';
export const AddManufacturer =()=>{
  const {slug} = useParams()
  const dispatch =useDispatch()
  const navigate = useNavigate()
  //   state = {
//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
const [manufacturerName,setManufacturerName] = useState("")
const [manufacturerTitle,setManufacturerTitle] = useState("")

useEffect(()=>{
  if(slug) {
    dispatch(getAnManufacturer(slug)).then((data)=>{
      setManufacturerName(data.payload.data.title)
      setManufacturerTitle(data.payload.data.manufacturer_title)
    })
  }

},[])

 

const onSubmit = (e) =>{
  e.preventDefault()

  const data = {title: manufacturerName, manufacturer_title: manufacturerTitle}
  // dispatch(addManufacturer(data)).then(()=>{
  //   navigate("/machine/manufacturer")
  // })
  if(slug) {
    dispatch(updateManufacturer({slug,data})).then(()=>{
      navigate("/admin/machine/manufacturer")
    })
  }else {
    dispatch(addManufacturer(data)).then(()=>{
      navigate("/admin/machine/manufacturer")
    })
  }
} 

 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Manufacturers</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Manufacturer</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Manufacturer</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add Manufacturer</h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Title</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Title " value = {manufacturerName} onChange={(e)=>setManufacturerName(e.target.value)}  />
                  </Form.Group>
                  {/* <Form.Group>
                    <label htmlFor="exampleInputEmail1">Manufacturer Title</label>
                    <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Manufacturer Title"  value = {manufacturerTitle} onChange={(e)=>setManufacturerTitle(e.target.value)} />
                  </Form.Group> */}
                
              
                  <button type="submit" className="btn btn-gradient-primary mr-2"  onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>navigate("/admin/machine/manufacturer")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}
