 
import React, { Component,useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import {useDispatch} from "react-redux"
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom";
import Select from '@mui/material/Select';
import { addUser } from '../Store/Features/UsersSlice';

export const AddUsers = () => {
  const {slug} = useParams()
  const dispatch =useDispatch()
  const navigate = useNavigate()
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [userRole, setUserRole] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email) {
      setEmailError(true);
    }

    if (password === confirmPassword) {
        setPasswordMatch(true); 
        } else {
        setPasswordMatch(false);
       }
     
     let data={
      name:username,
      password:password,
      email:email,
      roles:userRole
     }

     if(email && (password == confirmPassword)){
      console.log('validation check',passwordMatch,password)
      dispatch(addUser(data)).then(() => {
        navigate("/admin/allUsers");
      });
     }
  };

  const handleUsernameChange=(event)=>{
    setUsername(event.target.value)
  }
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false); 
  };
  const handleRoleChange =(event)=>{
    console.log('event value',event.target.value)
    console.log('userRole',userRole)
    setUserRole([event.target.value])
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Users</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="!#" onClick={(event) => event.preventDefault()}>
                Users
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Add User
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add User</h4>
              <form className="forms-sample" onSubmit={handleSubmit}>
                <Form.Group>
                  <label htmlFor="exampleInputUsername1">Username</label>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="Username"
                    size="lg"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <Form.Control
                    type="email"
                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                    id="exampleInputEmail1"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {emailError && (
                    <div className="invalid-feedback">Email is required.</div>
                  )}
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <Form.Control
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleInputConfirmPassword1">
                    Confirm Password
                  </label>
                  <Form.Control
                    type="password"
                    className="form-control"
                    id="exampleInputConfirmPassword1"
                    placeholder="Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  {!passwordMatch && (
                    <div className="text-danger">
                      Passwords do not match.
                    </div>
                  )}
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleInputConfirmPassword1">
                    Role
                  </label>
                  <select  onChange={handleRoleChange} name="productType" className="form-control">
                      <option value={0} >Select User Role</option>
                      <option value={1}>Admin</option> 
                      <option value={2}>User</option>
                  </select>
                </Form.Group>
                <button
                  type="submit"
                  className="btn btn-gradient-primary mr-2"
                 >
                  Submit
                </button>
                <button className="btn btn-light" onClick={() => navigate('/admin/allusers')}>Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
