import React, {useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {getMachinePercentageOrFixed, deleteMachinePercentageOrFixed} from "../Store/Features/MachinePercentageOrFixed"
import {useDispatch,useSelector} from "react-redux"

function CustomToolbar() {
    return (
      <GridToolbarContainer>
         <h4 className="card-title" style = {{margin: 20}}>All Machine Percentage Or Fixed</h4>
      </GridToolbarContainer>
    );
  }

const rows = [
  { id: 1, title: 'Snow', manufacturertitle: 'Jon' },
  { id: 2, name: 'Lannister', manufacturertitle: 'Cersei' },
];

export function MachinePercentageOrFixed() {
  const dispatch  = useDispatch()
  const navigate = useNavigate()
  const MachinePercentageOrFixed = useSelector(state => state.MachinePercentageOrFixed.value)

  useEffect(()=>{
    dispatch(getMachinePercentageOrFixed())
  },[])
  const handleView = (row) => {
    console.log('View:', row);
    // Implement view functionality here
  };

  const handleEdit = (row) => {
    navigate(`/admin/machine/percentageorfixed/${row.id}`)
    console.log('Edit:', row);
    // Implement edit functionality here
  };

  const handleDelete = (row) => {
    dispatch(deleteMachinePercentageOrFixed(row.id)).then(() => {
      dispatch(getMachinePercentageOrFixed())
    });
    console.log('Delete:', row);
    // Implement delete functionality here
  };
  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
 
 const columns = [
    
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Title',flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1 ,
      renderCell: (params) => (
        <div>
          {/* <button style={buttonStyle} onClick={() => handleView(params.row)}>View</button> */}
          <button style={buttonStyle1} onClick={() => handleEdit(params.row)}>Edit</button>
          <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
        </div>
      ),
    },
  ];

  return (
    
    <div style={{ height: 400, width: '100%' }}>
        <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> Machine Percentage Or Fixed  </h3>

          <button type="button" class="btn btn-gradient-success " onClick = {()=>navigate("/admin/machine/percentageorfixed/add")}>Add Machine Percentage Or Fixed</button>
        </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine Percentage Or Fixed </a></li>
              <li className="breadcrumb-item active" aria-current="page">All Machine Percentage Or Fixed </li>
            </ol>
          </nav>
        </div>
            <DataGrid
                rows={MachinePercentageOrFixed}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                sx={{ backgroundColor: "white" }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                slots={{
                    toolbar: CustomToolbar,
                  }}
            />
        </div>
  );
}

