import React, { Component,useState,useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import {addMachineModal} from "../Store/Features/Machinemodel"
import {useDispatch} from "react-redux"
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { getAnMachineModel,updateMachineModal } from '../Store/Features/Machinemodel';

export const AddMachineModel =()=>{
  const {slug} = useParams()
    const dispatch =useDispatch()
  const navigate = useNavigate()
//   state = {
//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
const [machineName,setMachineName] = useState("")


  useEffect(()=>{
    if(slug) {
      dispatch(getAnMachineModel(slug)).then((data)=>{
        setMachineName(data.payload.data.title)
      })
    }
  
  },[])

 

    const onSubmit = (e) =>{
      e.preventDefault()
     
      const data = {title: machineName}
      if(slug) {
        dispatch(updateMachineModal({slug,data})).then(()=>{
          navigate("/admin/machine/model")
        })
      }else {
        dispatch(addMachineModal(data)).then(()=>{
          navigate("/admin/machine/model")
        })
      }
     
    }
 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Machine Model</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine Model</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Machine Model</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{slug ? "Edit": "Add"} </h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Title</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Title" size="lg" value = {machineName} onChange = {e=>setMachineName(e.target.value)}/>
                  </Form.Group>
                 
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>navigate("/admin/machine/model")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}