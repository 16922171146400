import React, {useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {getManufacturer,deleteManufacturer} from "../Store/Features/Manufacture"
import {useDispatch,useSelector} from "react-redux"

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h4 className="card-title" style={{ margin: 20 }}>All Manufacturer </h4>
    </GridToolbarContainer>
  );
}

const rows = [
  { id: 1, title: 'Snow', manufacturertitle: 'Jon' },
  { id: 2, title: 'Lannister', manufacturertitle: 'Cersei' },
  { id: 3, title: 'Lannister', manufacturertitle: 'Jaime' },
];

export default function ManufacturerTable() {
  const dispatch  = useDispatch()
  const navigate = useNavigate()
  const manufacturer = useSelector(state => state.manufacturer.value)

  useEffect(()=>{
    dispatch(getManufacturer())
  },[])

  const handleView = (row) => {
    console.log('View:', row);
    // Implement view functionality here
  };

  const handleEdit = (row) => {
    navigate(`/admin/machine/manufacturer/${row.id}`)
    console.log('Edit:', row);
    // Implement edit functionality here
  };

  const handleDelete = (row) => {
    dispatch(deleteManufacturer(row.id)).then(() => {
      dispatch(getManufacturer())
    });

    console.log('Delete:', row);
    // Implement delete functionality here
  };
  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
 
 const columns = [
    
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'title', headerName: 'Title',flex: 1 },
    { field: 'manufacturer_title', headerName: 'Manufacturer Title', flex: 1  },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1 ,
      renderCell: (params) => (
        <div>
          {/* <button style={buttonStyle} onClick={() => handleView(params.row)}>View</button> */}
          <button style={buttonStyle1} onClick={() => handleEdit(params.row)}>Edit</button>
          <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
        </div>
      ),
    },
  ];

  return (

    <div style={{ height: 400, width: '100%' }}>
      <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> Manufacturer  </h3>
          <button type="button" className="btn btn-gradient-success " onClick = {()=>navigate("/admin/machine/manufacturer/add")}>Add Manufacturer</button>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Manufacturer </a></li>
            <li className="breadcrumb-item active" aria-current="page">All Manufacturer </li>
          </ol>
        </nav>
      </div>
      <DataGrid
        rows={manufacturer}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        sx={{ backgroundColor: "white" }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
