import React, { Component , useState, useEffect} from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import {addMachineCommisionperiod} from "../Store/Features/MachineCommisionPeriod"
import {useDispatch} from "react-redux"
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { getAnMachineCommisionperiod,updateMachineCommisionperiod } from '../Store/Features/MachineCommisionPeriod';

export const AddMachineCommisionPeriod =()=>{
  const {slug} = useParams()
  const dispatch =useDispatch()
  const navigate = useNavigate()
//   state = {
//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
const [MachineCommisionperiod,setMachineCommisionperiod] = useState("")
useEffect(()=>{
  if(slug) {
    dispatch(getAnMachineCommisionperiod(slug)).then((data)=>{
      setMachineCommisionperiod(data.payload.data.period_name)
    })
  }

},[])

const onSubmit = (e) =>{
  e.preventDefault()
 
  const data = {period_name:  MachineCommisionperiod}
  if(slug) {
    dispatch(updateMachineCommisionperiod({slug,data})).then(()=>{
      navigate("/admin/machine/commisionperiod")
    })
  }else {
    dispatch(addMachineCommisionperiod(data)).then(()=>{
      navigate("/admin/machine/commisionperiod")
    })
  }  
}
 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Machine Commision Period</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine Commision Period</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Machine Commision Period</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add </h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Period Name</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Period Name" value = {MachineCommisionperiod} onChange = {e=>setMachineCommisionperiod(e.target.value)} />
                  </Form.Group>
                  
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>navigate("/admin/machine/commisionperiod")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}
