import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Dashboard } from '../dashboard/Dashboard';
import { Login } from '../user-pages/Login';
import { SignUp } from '../user-pages/Register';
import { AllUsers } from '../users/AllUsers';
import { AddUsers } from '../users/AddUsers';
import { AllTransactions } from '../transactions/AllTransactions';
import { ViewTransaction } from '../transactions/ViewTransaction';
import { AddMachine } from '../Machine/AddMachine';
import { ViewMachine } from '../Machine/ViewMachine';
import { UpdateUser } from '../users/UpdateUsers';
import { AllOrders } from '../orders/AllOrders';
import { AddOrder } from '../orders/AddOrder';
import { UpdateOrder } from '../orders/UpdateOrder';
import { ProtectedRoute } from '../ProtectedRoutes/ProtectedRoutes';
import { UnProtectedRoutes } from '../UnProtectedRoutes/UnProtectedRoutes';
import { AddManufacturer } from "../Machine/Manufacturer "
import { MachineModel } from "../Machine/MachineModel"
import { MachineCurrency } from "../Machine/MachineCurrency"
import { MachineEstablishment } from "../Machine/MachineEstablishment"
import { MachineSector } from "../Machine/MachineSector"
import { MachineDistributor } from "../Machine/MachineDistributor"
import MachineCommisionPeriod from "../Machine/MachineCommisionPeriod"
import { MachinePaymentMethod } from "../Machine/MachinePaymentMethod"
import { MachinePercentageOrFixed } from "../Machine/MachinePercentageOrFixed";
import ManufacturerTable from "../Machine/ManufacturerDashboard";
import { AddMachineModel } from "../Machine/AddMachineModel";
import { AddMachineCurrency } from "../Machine/AddMachineCurrency";
import { AddMachineEstablishment } from "../Machine/AddMachineEstablishment";
import { AddMachineSector } from "../Machine/AddMachineSector";
import { AddMachineDistributor } from "../Machine/AddMachineDistributor";
import { AddMachineCommisionPeriod } from "../Machine/AddMachineCommisionPeriod"
import { AddMachinePaymentMethod } from "../Machine/AddMachinePaymentMethod"
import { AddMachinePercentageOrFixed } from "../Machine/AddMachinePercentageOrFixed"
import { Product } from "../Product/product"
import { ProductFamily } from "../Product/ProductFamily"
import { ProductCompany } from "../Product/productcompany"
import { ProductStratorCode } from "../Product/Productstratorcode"
import { AddProduct } from "../Product/Addproduct"
import { AddProductFamily } from "../Product/AddProductFamily"
import { AddProductCompany } from "../Product/Addproductcompany"
import { AddProductstratorcode } from "../Product/AddProductstratorcode"
import { Machine } from "../Machine/Machine"
import { AssignProduct } from "../Product/AssignProduct"
import { AssignProductStock } from "../Product/AddproductStock"
import { AllProductsStock } from "../Product/AllProductsStock"
import { ProductType } from "../Product/ProductType"
import { AddProductType } from "../Product/AddProductType"
import { ViewSingleMachineProducts } from "../Product/ViewSingleMachineProducts"
import { AllAssests } from "../MachinesAssets/AllAssets"
import { AddAsset } from "../MachinesAssets/AddAsset"
import { EditAsset } from "../MachinesAssets/EditAsset"
import { AddAssetCategory } from "../MachinesAssets/AddAssetCategory"
import { AddAssetStatus } from '../MachinesAssets/ AddAssetStatus';
import { AllCategory } from "../MachinesAssets/AllCategory"
import { AllAssetsStatus } from "../MachinesAssets/AllAssetsStatus"
import {AssetsTabs} from '../Assetstabs/assetstabs'
import {AssetsInfo} from '../Assetstabs/assetInfo'
export const AllRoutes = () => {
   return (
      <>
         <Routes>
            <Route path="/login" element={
               <Login />
            } />
            {/* <Route path="/admin/assetstabs" element={
               <AssetsTabs />
            } />
            <Route path="/admin/assetsInfo" element={
               <AssetsInfo />
            } /> */}

            <Route path="/signup" element={
               <SignUp />
            } />

            <Route path="/" element={
                
                  <AssetsTabs />
               
            } />
             <Route path="/admin/assetsInfo/:slug" element={
               <AssetsInfo />
            } />
             <Route path="/admin/dashboard" element={
               <ProtectedRoute>
                  <Dashboard />
               </ProtectedRoute>
            } />

         <Route path="/admin/assets" element={
               <ProtectedRoute>
                  <AllAssests />
               </ProtectedRoute>

            } />
            <Route path="/admin/assets/add/:slug?" element={
               <ProtectedRoute>
                  <AddAsset />
               </ProtectedRoute>

            } />
             <Route path="/admin/assets/edit/:slug" element={
               <ProtectedRoute>
                  <EditAsset />
               </ProtectedRoute>

            } />
            <Route path="/admin/assets/allcategories" element={
                           <ProtectedRoute>
                              <AllCategory />
                           </ProtectedRoute>

                        } />

            <Route path="/admin/assets/addcategory" element={
               <ProtectedRoute>
                  <AddAssetCategory />
               </ProtectedRoute>

            } />

            <Route path="/admin/assets/addcategory/:slug" element={
               <ProtectedRoute>
                  <AddAssetCategory />
               </ProtectedRoute>

            } />


            <Route path="/admin/assets/allassetstatus" element={
               <ProtectedRoute>
                  <AllAssetsStatus />
               </ProtectedRoute>

             } />

            <Route path="/admin/assets/addstatus" element={
               <ProtectedRoute>
                  <AddAssetStatus />
               </ProtectedRoute>

            } />

            <Route path="/admin/assets/addstatus/:slug" element={
               <ProtectedRoute>
                  <AddAssetStatus />
               </ProtectedRoute>

            } />

            <Route path="/admin/alltransactions" element={
               <ProtectedRoute>
                  <AllTransactions />
               </ProtectedRoute>

            } />

            <Route path="/admin/viewtransaction/:slug" element={
               <ProtectedRoute>
                  <ViewTransaction />
               </ProtectedRoute>

            } />

            <Route path="/admin/allusers" element={
               <ProtectedRoute>
                  <AllUsers />
               </ProtectedRoute>

            } />
            <Route path="/admin/adduser" element={
               <ProtectedRoute>
                  <AddUsers />
               </ProtectedRoute>

            } />

            <Route path="/admin/updateuser/:slug" element={
               <ProtectedRoute>
                  <UpdateUser />
               </ProtectedRoute>

            } />
            <Route path="/admin/allorders" element={
               <ProtectedRoute>
                  <AllOrders />
               </ProtectedRoute>

            } />
            <Route path="/admin/addorder/:slug?" element={
               <ProtectedRoute>
                  <AddOrder />
               </ProtectedRoute>

            } />

            <Route path="/admin/updateorder" element={
               <ProtectedRoute>
                  <UpdateOrder />
               </ProtectedRoute>

            } />


            <Route path="/admin/machine/add" element={
               <ProtectedRoute>
                  <AddMachine />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/manufacturer/add" element={
               <ProtectedRoute>
                  <AddManufacturer />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/model" element={
               <ProtectedRoute>
                  <MachineModel />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/Currency" element={
               <ProtectedRoute>
                  <MachineCurrency />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/establishment" element={
               <ProtectedRoute>
                  <MachineEstablishment />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/distributor" element={
               <ProtectedRoute>
                  <MachineDistributor />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/commisionperiod" element={
               <ProtectedRoute>
                  <MachineCommisionPeriod />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/sector" element={
               <ProtectedRoute>
                  <MachineSector />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/paymentmethod" element={
               <ProtectedRoute>
                  <MachinePaymentMethod />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/percentageorfixed" element={
               <ProtectedRoute>
                  <MachinePercentageOrFixed />
               </ProtectedRoute>

            } />

            <Route path="/admin/machine/manufacturer" element={
               <ProtectedRoute>
                  <ManufacturerTable />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/model/add" element={
               <ProtectedRoute>
                  <AddMachineModel />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/currency/add" element={
               <ProtectedRoute>
                  <AddMachineCurrency />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/establishment/add" element={
               <ProtectedRoute>
                  <AddMachineEstablishment />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/sector/add" element={
               <ProtectedRoute>
                  <AddMachineSector />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/distributor/add" element={
               <ProtectedRoute>
                  <AddMachineDistributor />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/commisionperiod/add" element={
               <ProtectedRoute>
                  <AddMachineCommisionPeriod />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/paymentmethod/add" element={
               <ProtectedRoute>
                  <AddMachinePaymentMethod />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/percentageorfixed/add" element={
               <ProtectedRoute>
                  <AddMachinePercentageOrFixed />
               </ProtectedRoute>
            } />




            // here starts edit routes
            <Route path="/admin/machine/:slug" element={
               <ProtectedRoute>
                  <AddMachine />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/view/:slug" element={
               <ProtectedRoute>
                  <ViewMachine />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/manufacturer/:slug" element={
               <ProtectedRoute>
                  <AddManufacturer />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/model/:slug" element={
               <ProtectedRoute>
                  <AddMachineModel />
               </ProtectedRoute>

            } />
            <Route path="/admin/machine/currency/:slug" element={
               <ProtectedRoute>
                  <AddMachineCurrency />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/establishment/:slug" element={
               <ProtectedRoute>
                  <AddMachineEstablishment />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/sector/:slug" element={
               <ProtectedRoute>
                  <AddMachineSector />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/distributor/:slug" element={
               <ProtectedRoute>
                  <AddMachineDistributor />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/commisionperiod/:slug" element={
               <ProtectedRoute>
                  <AddMachineCommisionPeriod />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/paymentmethod/:slug" element={
               <ProtectedRoute>
                  <AddMachinePaymentMethod />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/percentageorfixed/:slug" element={
               <ProtectedRoute>
                  <AddMachinePercentageOrFixed />
               </ProtectedRoute>
            } />
            <Route path="/admin/product/edit/:slug" element={
               <ProtectedRoute>
                  <AddProduct />
               </ProtectedRoute>
            } />
            //Ends edit routes

            <Route path="/admin/product" element={
               <ProtectedRoute>
                  <Product />
               </ProtectedRoute>
            } />
            <Route path="/admin/product/family" element={
               <ProtectedRoute>
                  <ProductFamily />
               </ProtectedRoute>
            } />
            <Route path="/admin/product/company" element={
               <ProtectedRoute>
                  <ProductCompany />
               </ProtectedRoute>
            } />
            <Route path="/admin/product/stratorcode" element={
               <ProtectedRoute>
                  <ProductStratorCode />
               </ProtectedRoute>
            } />
            <Route path="/admin/product/add/:slug?" element={
               <ProtectedRoute>
                  <AddProduct />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine/product/add/:machineId?" element={
               <ProtectedRoute>
                  <AddProduct />
               </ProtectedRoute>
            } />
            <Route path="/admin/product/family/add" element={
               <ProtectedRoute>
                  <AddProductFamily />
               </ProtectedRoute>
            } />
            <Route path="/admin/product/company/add" element={
               <ProtectedRoute>
                  <AddProductCompany />
               </ProtectedRoute>
            } /> Machine
            <Route path="/admin/product/stratorcode/add" element={
               <ProtectedRoute>
                  <AddProductstratorcode />
               </ProtectedRoute>
            } />
                  //assign

                  <Route path="/admin/assignproduct" element={
                     <ProtectedRoute>
                        <AssignProduct />
                     </ProtectedRoute>
                  } />
                  <Route path="/admin/assignproduct/:slug" element={
                     <ProtectedRoute>
                        <AssignProduct />
                     </ProtectedRoute>
                  } />
                  
                  <Route path="/admin/assignproductstock/:slug" element={
                     <ProtectedRoute>
                        <AssignProductStock />
                     </ProtectedRoute>
                  } />

                  <Route path="/admin/product/product-type" element={
                     <ProtectedRoute>
                        <ProductType />
                     </ProtectedRoute>
                  } />
                   <Route path="/admin/product/product-type/add" element={
                     <ProtectedRoute>
                        <AddProductType />
                     </ProtectedRoute>
                  } />
                  <Route path="/admin/product/product-type/edit/:slug" element={
                     <ProtectedRoute>
                        <AddProductType />
                     </ProtectedRoute>
                  } />

                  <Route path="/admin/product/allproducts-stock" element={
                     <ProtectedRoute>
                        <AllProductsStock />
                     </ProtectedRoute>
                  } />
                   <Route path="/admin/product/single-machineproducts/:slug" element={
                     <ProtectedRoute>
                        <ViewSingleMachineProducts />
                     </ProtectedRoute>
                  } />
                  //End assign
            //start edit product form
            <Route path="/admin/product/family/:slug" element={
               <ProtectedRoute>
                  <AddProductFamily />
               </ProtectedRoute>
            } />
            <Route path="/admin/product/company/:slug" element={
               <ProtectedRoute>
                  <AddProductCompany />
               </ProtectedRoute>
            } /> Machine
            <Route path="/admin/product/stratorcode/:slug" element={
               <ProtectedRoute>
                  <AddProductstratorcode />
               </ProtectedRoute>
            } />
            <Route path="/admin/machine" element={
               <ProtectedRoute>
                  <Machine />
               </ProtectedRoute>
            } />
         </Routes>
      </>
   );
}

