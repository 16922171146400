import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";
import { ToastContainer, toast } from 'react-toastify';


export const getTransactions = createAsyncThunk('getTransactions', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}transactions`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addTransaction = createAsyncThunk('addTransaction', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}transactions`, arg, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

// export const getAnOrder = createAsyncThunk('getAnOrder', async (arg, {
//     rejectWithValue
// }) => {

//     try {
//         const { data } = await axios.get(`${baseUrl}orders/${arg}`, {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("auth")}`
            
//             }
//         });

//         return data;
//     }
//     catch (error) {

//         return rejectWithValue(error.response.data.message);

//     }
// });
// export const updateOrder = createAsyncThunk('updateOrder', async (arg, {
//     rejectWithValue
// }) => {
//     console.log('data',)

//     try {
//         const { data } = await axios.put(`${baseUrl}orders/${arg.slug}`, arg.formData, {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("auth")}`,
                
//             }
//         });

//         return data;
//     }
//     catch (error) {

//         return rejectWithValue(error.response.data.message);

//     }
// });

export const deleteTransaction = createAsyncThunk('deleteOrder ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}transactions/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const transactionSlice = createSlice({
    name: 'transcation',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {

        builder.addCase(getTransactions.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getTransactions.fulfilled, (state, action) => {
            state.loading = false;
            state.value = action.payload.data;
        });
        builder.addCase(getTransactions.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            
        });
        


       
        builder.addCase(addTransaction.pending, (state, action) => {
            // toast.loading('Creating Transaction');
            state.loading = true;
        });
        builder.addCase(addTransaction.fulfilled, (state, action) => {
            toast.dismiss();
            // toast.success('Added Transaction data Sucessfully');
            state.loading = false;
         });
         builder.addCase(addTransaction.rejected, (state, action) => {
            toast.dismiss();
            // toast.error('Failed to add Transaction data');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(deleteTransaction.pending, (state, action) => {
            toast.loading('Deleting order Payment');
            state.loading = true;
        });
        builder.addCase(deleteTransaction.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Order Payment Sucessfully');
            state.loading = false;
         });
         builder.addCase(deleteTransaction.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to Delete Order Payment');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
         

        
    },
});


export default transactionSlice;


 