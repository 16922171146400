import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";
import { ToastContainer, toast } from 'react-toastify';

export const getCategories = createAsyncThunk('getCategories', async (arg, {
    rejectWithValue
}) => {
    try {
        const { data } = await axios.get(`${baseUrl}get-all-asset-categories`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const publicCategorySlice = createSlice({
    name: 'Categories',
    initialState: {
        value: [],
        loading: false,
        message: "",
    },
    reducers: {

    },

    extraReducers: (builder) => {

        builder.addCase(getCategories.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.value = action.payload.data;
        });
        builder.addCase(getCategories.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            
        });
        
    },
});


export default publicCategorySlice;


 