import React, { Component, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import bsCustomFileInput from "bs-custom-file-input";
import { addProduct } from "../Store/Features/Product/Product";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getAnProduct, updateProduct } from "../Store/Features/Product/Product";

import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CircularProgress from '@mui/material/CircularProgress';
import { getCategories } from "../Store/Features/MachinesAssets/MachinesAssets";
import { getAssetStatuses } from "../Store/Features/MachinesAssets/MachinesAssets";
import {
  addAsset,
  uploadAssetImage,
  getAnAsset,
  updateAsset,
} from "../Store/Features/MachinesAssets/MachinesAssets";
import { getMachine } from "../Store/Features/Machine";
import { baseUrl } from "../Configuration/config";

export const EditAsset = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [machines, setMachines] = useState([]);
  const [assetStatuses, setStatuses] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [apiCall_Loader, setapiCall_Loader] = useState(false);
  const [assetData, setAssetData] = useState({
    name: "",
    machine_id: "",
    category_id: "",
    serial_number: "",
    photos: [],
    status_id: "",
    notes: "",
  });
  const [images, setImages] = useState([]);

  useEffect(() => {
    dispatch(getCategories()).then((res) => {
      console.log("here is categories data...........", res.payload.data);
      setCategories(res.payload.data);
    });
    dispatch(getMachine()).then((res) => {
      console.log("here is machines data...........", res.payload.data);
      setMachines(res.payload.data);
    });
    dispatch(getAssetStatuses()).then((res) => {
      console.log("here is Asset Status data...........", res.payload.data);
      setStatuses(res.payload.data);
    });
  }, []);

 

  const submitImage = (e) => {
    e.preventDefault();
    handleFile(e);

    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    let temp = [...images];
    temp.push(e.target.files[0]);
    setImages(temp);
    console.log("here is image data", formData);

    dispatch(uploadAssetImage(formData)).then((data) => {
      console.log(data.payload);
      let tempData = [...assetData.photos];
      tempData.push(data.payload.name);
      console.log("here is image data res", data.payload.name);
      setAssetData({ ...assetData, photos: tempData });
      // setAssetData({...assetData.photos.push(data.payload.name)})
    });
  };
  const handleFile = (e) => {
    const objectURL = URL.createObjectURL(e.target.files[0]);
    let tempData = [...productImage];
    tempData.push(objectURL);
    setProductImage(tempData);
  };

  useEffect(() => {
    if (slug) {
      setapiCall_Loader(true)
      dispatch(getAnAsset(slug)).then((data) => {
        setapiCall_Loader(false)
        console.log("get an asset data", data);
        let imageFiles = [];
        let tempData = data.payload.data?.photos;
        let temp = [...images];
        tempData.map((img) => {
          temp.push(img);
          setImages(temp);
          imageFiles.push(img.file_name);
        });
        console.log("images file names", imageFiles);
        let tempAsset = {
          name: data.payload.data?.name,
          machine_id: data.payload.data?.machine_id,
          category_id: data.payload.data?.category?.id,
          serial_number: data.payload.data?.serial_number,
          photos: imageFiles,
          status_id: data.payload.data?.status_id,
          notes: data.payload.data?.notes,
        };
        // setProductImage(data.payload.data?.product_image?.original_url)
        console.log("tempAsset=====================================", tempAsset);
        setAssetData(tempAsset);
      });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    console.log("here is update assets values", assetData);
    let formData = new FormData();

    Object.keys(assetData).forEach((key) => {
      if (key != "photos") formData.append(key, assetData[key]);
    });

    assetData.photos.map((ele, i) => {
      formData.append("photos[]", ele.name);
    });
 

    if (slug) {
      console.log("Asset data photos here ............", assetData);
      dispatch(updateAsset({ slug, assetData })).then((resp) => {
        console.log("update asset resp-check 123............", resp);
        navigate("/admin/assets")
      });
    }else{
      dispatch(addAsset(assetData)).then((resp) => {
        console.log("add asset resp-check 123 add", resp);
        navigate("/admin/assets");
      });
    }
  };
  useEffect(() => {
    console.log("images AssetData", images,assetData);
  }, [images,assetData]);

  const handleImageDelete = (index)=> {
    let tempData = [...assetData.photos];
      tempData.splice(index,1);
    
      setAssetData({ ...assetData, photos: tempData });

    let temp = [...images];
    temp.splice(index,1);
    setImages(temp)

  }

  // machine-assets/2
  //get-machine-products/2
  return (
    <>
     {apiCall_Loader ? (
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    Loading
                    <form className="forms-sample">
                    <Box sx={{height:"20px",textAlign:'center'}} > 
                      <CircularProgress />
                      </Box >
                    </form>
                  </div>
                </div>
              </div>
            </div>
     ):(null)
            }
    { assetData.name.length > 0 ? (<div>
      <div className="page-header">
        <h3 className="page-title">Assets</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="!#" onClick={(event) => event.preventDefault()}>
                Assets
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Update Assets
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              Update{" "}
              {/* <p className="card-description"> Basic form layout </p> */}
              <form className="forms-sample">
            
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
               
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputUsername1">Asset Name</label>
                    <Form.Control
                      type="text"
                      id="exampleInputUsername1"
                      placeholder="Asset Name"
                      value={assetData.name}
                      onChange={(e) =>
                        setAssetData({ ...assetData, name: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Serial_number</label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Serial_number"
                      value={assetData.serial_number}
                      onChange={(e) =>
                        setAssetData({
                          ...assetData,
                          serial_number: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Box>
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                  <Form.Group style={{ width: "100%" }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-6">
                        <label
                          style={{ widt: "100%" }}
                          htmlFor="exampleInputUsername1"
                        >
                          Assets Images{" "}
                        </label>
                        <Form.Control
                          type="file"
                          multiple
                          id="exampleInputUsername1"
                          placeholder="Product Image "
                          onChange={(e) => {
                            submitImage(e);
                          }}
                        />
                      </div>
                      <div className="col-lg-12 col-sm-6">
                        {images.map((img,index) => {
                          return (
                            <div style={{marginLeft:'20px',position:"relative",marginBottom:'20px',marginTop:'34px',display:'inline-block' }}>
                              <div onClick = {()=>handleImageDelete(index)} style ={{position:"absolute",right:"-2px",padding:'3px',backgroundColor:'red',color:"white",borderRadius:'21px',fontSize:"14px",top:'-7px'}}>X</div>
                              <img
                                style={{ height: "70px", width: "70px",border:'1px solid lightgrey',padding:'10px' }}
                                src={getImagePath(img)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Form.Group>
                  {/* <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Category </label>
                    <select
                      value={assetData.category_id}
                      name="productType"
                      className="form-control"
                      onChange={(e) =>
                        setAssetData({
                          ...assetData,
                          category_id: e.target.value,
                        })
                      }
                    >
                      <option value={0}>Select the Following Option</option>
                      {categories?.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </Form.Group> */}
                </Box>
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Category </label>
                    <select
                      value={assetData.category_id}
                      name="productType"
                      className="form-control"
                      onChange={(e) =>
                        setAssetData({
                          ...assetData,
                          category_id: e.target.value,
                        })
                      }
                    >
                      <option value={0}>Select the Following Option</option>
                      {categories?.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </Form.Group>
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Machine Status</label>
                    {/* <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Type of Type Id" value={product.type_of_product_id} onChange={(e) => setProduct({ ...product, type_of_product_id: e.target.value })} /> */}
                    <select
                      value={assetData.status_id}
                      name="productType"
                      className="form-control"
                      onChange={(e) =>
                        setAssetData({
                          ...assetData,
                          status_id: e.target.value,
                        })
                      }
                    >
                      <option value={0}>Select the Following Option</option>
                      {assetStatuses?.map((item) => (
                        <option value={item.id}>{item?.name}</option>
                      ))}
                    </select>
                  </Form.Group>
                </Box>
                <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                  <Form.Group style={{ width: "100%" }}>
                    <label htmlFor="exampleInputEmail1">Machine</label>
                    {/* <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Type of Type Id" value={product.type_of_product_id} onChange={(e) => setProduct({ ...product, type_of_product_id: e.target.value })} /> */}
                    <select
                      value={assetData.machine_id}
                      name="productType"
                      className="form-control"
                      onChange={(e) =>
                        setAssetData({
                          ...assetData,
                          machine_id: e.target.value,
                        })
                      }
                    >
                      <option value={0}>Select the Following Option</option>
                      {machines?.map((item) => (
                        <option value={item.id}>
                          {item?.machine_model?.title}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                  {/* <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Machine Status</label>
                  <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Matrix Product" value={product.matrix_product} onChange={(e) => setProduct({ ...product, matrix_product: e.target.value })} />
                </Form.Group> */}
                  <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    className="ckeditor"
                    sx={{ height: "79px !important" }}
                    // onReady={ editor => {
                    //     // You can store the "editor" and use when it is needed.
                    //     console.log( 'Editor is ready to use!', editor );
                    // } }
                    data={assetData.notes}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setAssetData({ ...assetData, notes: data });
                    }}
                    // onBlur={ ( event, editor ) => {
                    //     console.log( 'Blur.', editor );
                    // } }
                    // onFocus={ ( event, editor ) => {
                    //     console.log( 'Focus.', editor );
                    // } }
                  />
                  </Form.Group>
                </Box>
                {/* <Box sx={{ display: "flex", gap: "10px", width: "100%",marginBottom:'40px' }}>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-6">
                    <CKEditor
                    editor={ClassicEditor}
                    className="ckeditor"
                    sx={{ height: "79px !important" }}
                   
                    data={assetData.notes}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setAssetData({ ...assetData, notes: data });
                    }}
                    
                  />
                    </div>
                  </div>
                
                
                </Box> */}
                <button
                  type="submit"
                  className="btn btn-gradient-primary mr-2"
                  onClick={onSubmit}
                >
                  Submit
                </button>
                <button className="btn btn-light" onClick={() => navigate(`/admin/assets`)}>Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>) : (null)
    }
    </>
  );
};

const editorConfiguration = {
  toolbar: ["bold", "italic"],
};

const resolveImahe = (path) => {
  function isValidUrl(path) {
    try {
      new URL(path);
      return path;
    } catch (err) {
      return;
    }
  }

  isValidUrl(path);
};

const getImagePath = (path) => {
  if (path.original_url) {
    console.log("original_path", path.original_url);
    return path.original_url;
  } else {
    console.log("yes", path);
    return handleFile(path);
  }
};

const handleFile = (e) => {
  console.log("here is file img url", e);
  const objectURL = URL.createObjectURL(e);
  console.log("here is file img url", objectURL);
  return objectURL;
};
