import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../Configuration/config";

import { toast } from 'react-toastify';
export const getMachinePercentageOrFixed = createAsyncThunk('getMachinePercentageOrFixed', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-percentage-or-fixeds`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addMachinePercentageOrFixed = createAsyncThunk('addMachinePercentageOrFixed', async (arg, {
    rejectWithValue
}) => {
    console.log('addMachinePercentageOrFixed', arg);
    try {
        const { data } = await axios.post(`${baseUrl}machine-percentage-or-fixeds`,arg,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const getAnMachinePercentageOrFixed= createAsyncThunk('getAnMachinePercentageOrFixed', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-percentage-or-fixeds/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateMachinePercentageOrFixed = createAsyncThunk('updateMachinePercentageOrFixed', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.put(`${baseUrl}machine-percentage-or-fixeds/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const deleteMachinePercentageOrFixed = createAsyncThunk('deleteMachinePercentageOrFixed ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}machine-percentage-or-fixeds/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const MachinePercentageOrFixedSlice = createSlice({
    name: 'MachinePercentageOrFixed',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getMachinePercentageOrFixed.pending, (state, action) => {
            //   toast.loading('Logging in');
            state.loading = true;
        });
        builder.addCase(getMachinePercentageOrFixed.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Logged In');
            state.loading = false;
            state.value = action.payload.data;



        });
        builder.addCase(getMachinePercentageOrFixed.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Promise Not Resolved');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(addMachinePercentageOrFixed.pending, (state, action) => {
              toast.loading('Adding Machine Percentage Or Fixed');
            state.loading = true;
        });
        builder.addCase(addMachinePercentageOrFixed.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Machine Percentage Or Fixed');
            state.loading = false;
            



        });
        builder.addCase(addMachinePercentageOrFixed.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed To Add Machine Percentage Or Fixed');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(updateMachinePercentageOrFixed.pending, (state, action) => {
            toast.loading('Updating Machine Percentage Or Fixed');
            state.loading = true;
        });
        builder.addCase(updateMachinePercentageOrFixed.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Machine Percentage Or Fixed');
            state.loading = false;




        });
        builder.addCase(updateMachinePercentageOrFixed.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to update Machine Percentage Or Fixed');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(deleteMachinePercentageOrFixed.pending, (state, action) => {
            toast.loading('Deleting Machine Percentage Or Fixed');
            state.loading = true;
        });
        builder.addCase(deleteMachinePercentageOrFixed.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Machine Percentage Or Fixed');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(deleteMachinePercentageOrFixed.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to delete Machine Percentage Or Fixed');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        // builder.addCase(logout.pending, (state, action) => {
        //   toast.loading('Logging Out...');
        //   state.loading = true;
        // });
        // builder.addCase(logout.fulfilled, (state, action) => {
        //   toast.dismiss();

        //   state.loading = false;

        //   state.loading = false;
        //   if (action.payload.message) {
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   }

        //   localStorage.removeItem("auth");
        //   localStorage.removeItem("role");
        //   toast.success('Logged out');

        // });
        // builder.addCase(logout.rejected, (state, action) => {
        //   toast.dismiss();
        //   toast.error('Promise rejected');
        //   if (action.payload == "Network Error") {
        //     localStorage.removeItem("auth");
        //     localStorage.removeItem("role");

        //     state.loading = false;
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   } else {
        //     state.loading = false;
        //     state.isSuccess = true;
        //     state.isAuthenticated = true;
        //   }

        // });
    },
});


export default MachinePercentageOrFixedSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };