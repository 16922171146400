import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ProgressBar } from 'react-bootstrap';
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, deleteUser, getSingleUser } from "../Store/Features/UsersSlice";
import { Typography } from "@mui/material"
import { useParams } from "react-router-dom";
import { getOrders, deleteOrder } from "../Store/Features/Order/Order";
import { getTransactions, deleteTransaction } from "../Store/Features/Transactions/Transactions";
import { getAnOrder } from "../Store/Features/Order/Order";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import "./transactions.css"

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <h4 className="card-title" style={{ margin: 20 }}>All Payments </h4>
        </GridToolbarContainer>
    );
}

const rows = [
    { id: 1, name: 'Snow', email: 'Jon' },
];
export const ViewTransaction = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { slug } = useParams();
    const [transactiondata, setTransactiondata] = useState();
    const [apiCall_Loader, setapiCall_Loader] = useState(false);
    // const Transactions = useSelector(state => state.TransactionSlice.value)
    // const Loading = useSelector(state => state.TransactionSlice.loading)

    useEffect(() => {
        if(slug){
            setapiCall_Loader(true)
          }
        dispatch(getAnOrder(slug)).then((res)=>{
            setapiCall_Loader(false)
            console.log('here is transactions data for invoice...........',res.payload.data)
            setTransactiondata(res.payload.data)
            
        })
    }, [])

    useEffect(() => {
        console.log('here is transactions data for invoice', transactiondata)
    }, [])

    const handleDelete = (row) => {
        dispatch(deleteTransaction(row.id)).then(() => {
            dispatch(getTransactions())
        });
    }
    //   const downloadpdf=()=>{
    //     fetch('SamplePDF.pdf').then(response => {
    //         response.blob().then(blob => {
    //             // Creating new object of PDF file
    //             const fileURL = window.URL.createObjectURL(blob);
    //             // Setting various property values
    //             let alink = document.createElement('a');
    //             alink.href = fileURL;
    //             alink.download = 'SamplePDF.pdf';
    //             alink.click();
    //         })
    //     })
    //   }
    const downloadPdfDocument = () => {
        var sample = 'Order_invoice'
        const input = document.getElementById('invoice');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                pdf.save(`${sample}.pdf`);
            })
    }

    const handleGeneratePdf = () => {
        // console.log('inside')
        // const doc = new jsPDF({
        //     format: 'a4',
        //     unit: 'px',
        // });

        // // Adding the fonts.

        // // doc.download = 'SamplePDF.pdf';
        // doc.html(Transactions, {
        //     async callback(doc) {
        //         await doc.save('document');
        //     },
        // });
        // let alink = document.createElement('a');
        //             alink.href = Transactions;
        //             alink.download = 'SamplePDF.pdf';
        //             alink.click();
    };

    const buttonStyle1 = {
        marginRight: '5px',
        padding: '5px 10px',
        backgroundColor: 'rgb(53 185 220)',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    };

    const buttonStyle2 = {
        marginRight: '5px',
        padding: '5px 10px',
        backgroundColor: '#DC3545',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    };

    const columns = [
        { field: 'id', headerName: 'Order-ID', flex: 1 },
        { field: 'amount', headerName: 'Total Payment', flex: 1 },
        { field: 'transaction_date', headerName: 'Transaction Date', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <div>
                    <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
                    <button style={buttonStyle1} onClick={downloadPdfDocument}  >Generate Pdf</button>
                </div>
            ),
        },
    ];

    return (
        <div style={{ height: 400, width: '100%' }} >
            <div className="page-header">
                <div className="flex-row-10">
                    <h3 className="page-title"> Payments  </h3>
                </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Payments </a></li>
                        <li className="breadcrumb-item active" aria-current="page">All Payments </li>
                    </ol>
                </nav>
            </div>
            {apiCall_Loader ? (
            <Box sx={{height:"20px",textAlign:'center'}} > 
                <CircularProgress />
                </Box >
            ):(null)
            }

           {transactiondata &&
            <div id="invoiceholder">
                <div id="invoice" class="effect2">
                    <div id="invoice-top">
                        <div class="logo">
                        <img className='logo' src={'/images/vapora.png'} />
                        </div>
                        <div class="info">
                            <h2>Vending Machine</h2>
                            <p>Machine Code: {transactiondata?.machine?.machine_code}
                            </p>
                        </div>
                        <div class="title">
                            <h1>Invoice #{transactiondata.id}</h1>
                            <p>Issued: {transactiondata.created_at}
                                {/* Payment Due: June 27, 2015 */}
                            </p>
                        </div>
                        
                    </div>

                    <div>

                        {/* <div id="invoice-mid">

                            <div class="clientlogo"></div>
                            <div class="info">
                                <h2>Client Name</h2>
                                <p>JohnDoe@gmail.com
                                    555-555-5555 </p>
                            </div>

                            <div id="project">
                                <h2>Project Description</h2>
                                <p>Proin cursus, dui non tincidunt elementum, tortor ex feugiat enim, at elementum enim quam vel purus. Curabitur semper malesuada urna ut suscipit.</p>
                            </div>

                        </div> */}

                        <div id="invoice-bot">

                            <div id="table">
                                <table>
                                    <tr class="tabletitle">
                                        <td class="item"><h2>Product Name</h2></td>
                                        <td class="Hours"><h2>Price</h2></td>
                                        <td class="Rate"><h2>Quantity</h2></td>
                                        <td class="subtotal"><h2>Total Payment</h2></td>
                                    </tr>
                                    {transactiondata.products.map(item => 
                                       <tr class="service">
                                       {/* <td class=" "><p class="itemtext"><img className='logo' src={item.product?.product_image?.original_url} /></p></td> */}
                                       <td class="tableitem"><p class="itemtext">{item.product?.product_name}</p></td>
                                       <td class="tableitem"><p class="itemtext">{item?.total_price}</p></td>
                                       <td class="tableitem"><p class="itemtext">{item?.quantity}</p></td>
                                       <td class="tableitem"><p class="itemtext">£ {item?.total_price}</p></td>
                                   </tr>
                                      )}
                                   

                                    {/* <tr class="service">
                                        <td class="tableitem"><p class="itemtext">Asset Gathering</p></td>
                                        <td class="tableitem"><p class="itemtext">3</p></td>
                                        <td class="tableitem"><p class="itemtext">$75</p></td>
                                        <td class="tableitem"><p class="itemtext">$225.00</p></td>
                                    </tr>

                                    <tr class="service">
                                        <td class="tableitem"><p class="itemtext">Design Development</p></td>
                                        <td class="tableitem"><p class="itemtext">5</p></td>
                                        <td class="tableitem"><p class="itemtext">$75</p></td>
                                        <td class="tableitem"><p class="itemtext">$375.00</p></td>
                                    </tr>

                                    <tr class="service">
                                        <td class="tableitem"><p class="itemtext">Animation</p></td>
                                        <td class="tableitem"><p class="itemtext">20</p></td>
                                        <td class="tableitem"><p class="itemtext">$75</p></td>
                                        <td class="tableitem"><p class="itemtext">$1,500.00</p></td>
                                    </tr>

                                    <tr class="service">
                                        <td class="tableitem"><p class="itemtext">Animation Revisions</p></td>
                                        <td class="tableitem"><p class="itemtext">10</p></td>
                                        <td class="tableitem"><p class="itemtext">$75</p></td>
                                        <td class="tableitem"><p class="itemtext">$750.00</p></td>
                                    </tr> */}

                                    {/* <tr class="service">
                                        <td class="tableitem"><p class="itemtext"></p></td>
                                        <td class="tableitem"><p class="itemtext">HST</p></td>
                                        <td class="tableitem"><p class="itemtext">13%</p></td>
                                        <td class="tableitem"><p class="itemtext">$419.25</p></td>
                                    </tr>


                                    <tr class="tabletitle">
                                        <td></td>
                                        <td></td>
                                        <td class="Rate"><h2>Total</h2></td>
                                        <td class="payment"><h2>$3,644.25</h2></td>
                                    </tr> */}

                                </table>
                            </div>
                            <div style={{ marginTop: "40px",marginBottom:'20px'}}>
                               <button style={buttonStyle1} onClick={downloadPdfDocument}  >Generate Pdf</button>
                            </div>
                        </div>
                        
                       
                    </div>
                </div>
               
            </div>}
        </div>



    );

}



