import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { getUsers , deleteUser , getSingleUser } from "../Store/Features/UsersSlice";
import {Typography} from "@mui/material"
import { useParams } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h4 className="card-title" style={{ margin: 20 }}>All Users </h4>
    </GridToolbarContainer>
  );
}

const rows = [
  { id: 1, name: 'Snow', email: 'Jon' },
];
export function AllUsers() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { slug } = useParams();
  const AllUsers = useSelector(state => state.users.value)
  const Loading = useSelector(state => state.users.loading)




  useEffect(() => {
    dispatch(getUsers())
  }, [])

  const handleView = (row) => {
    console.log('View:', row);
    // Implement view functionality here
  };

  const handleEdit = (row) => {
    // navigate(`/allusers${row.id}`)
    // console.log('Edit:', row);
    // Implement edit functionality here
    dispatch(getSingleUser(slug)).then((data) => {
      // dispatch(getUsers())
      console.log('single user here',slug)
   });
  };

  const handleDelete = (row) => {
    dispatch(deleteUser(row.id)).then(() => {
       dispatch(getUsers())
    });
   
     
  };
  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };


  // export const AllUsers=()=>{

  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();
  //   const [Users, setUsers] = useState([]);

  //   const usersList = () => {
  //       dispatch(getUsers()).then((data) => {
  //         console.log('here is user data...................',data)
  //         setUsers(data.payload.data)
  //       }).catch((err)=>{
  //         console.log('here is user data err',err)
  //       })
  //   }



  //   useEffect(() => {
  //     usersList()


  //  }, [])

  const columns = [

    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'roles', headerName: 'Roles', flex: 1,renderCell:(params) => {
     
    
        return (
          <Typography>{params.row.roles[0].title}</Typography>
             )
    
  } },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <div>
          {/* <button style={buttonStyle} onClick={() => handleView(params.row)}>View</button> */}
          <button style={buttonStyle1} onClick={() => navigate("/admin/updateuser/"+params.row.id)}>Edit</button>
          <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
        </div>
      ),
    },
  ];



  //   return (
  //  <>



  //      <div>
  //       <div className="page-header">
  //         <h3 className="page-title"> Users </h3>
  //         <nav aria-label="breadcrumb">
  //           <ol className="breadcrumb">
  //             <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Users</a></li>
  //             <li className="breadcrumb-item active" aria-current="page">All Users</li>
  //           </ol>
  //         </nav>
  //       </div>
  //       <div className="row">
  //        <div className="col-lg-12 grid-margin stretch-card">
  //           <div className="card">
  //             <div className="card-body">
  //               <h4 className="card-title">All Users</h4>
  //               {/* <p className="card-description"> Add className <code>.table</code></p> */}
  //               <div className="table-responsive">
  //                 <table className="table">
  //                   <thead>
  //                     <tr>
  //                       <th>Id</th>
  //                       <th>Name</th>
  //                       <th>Email</th>
  //                       <th>Role</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                   {Users?.map((user, index) => (
  //                     <tr>
  //                       <td>{user.id}</td>
  //                       <td>{user.name}</td>
  //                       <td>{user.email}</td>
  //                       <td>{user.roles[0].title}</td>
  //                       {/* <td><label className="badge badge-danger">Pending</label></td> */}
  //                     </tr>
  //                     ))}
  //                   </tbody>
  //                 </table>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>


  //  </>
  //   )
  // }
  return (

    <div style={{ height: 400, width: '100%' }}>
      <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> Users  </h3>

          <button type="button" className="btn btn-gradient-success " onClick={() => navigate("/admin/adduser")}>Add Users</button>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Users </a></li>
            <li className="breadcrumb-item active" aria-current="page">All Users </li>
          </ol>
        </nav>
      </div>
             <DataGrid
             rows={AllUsers}
             columns={columns}
             loading = {Loading}
             initialState={{
               pagination: {
                 paginationModel: { page: 0, pageSize: 5 },
               },
             }}
             sx={{ backgroundColor: "white" }}
             pageSizeOptions={[5, 10]}
             checkboxSelection
             slots={{
               toolbar: CustomToolbar,
             }}
           />
    </div>
  );
}


