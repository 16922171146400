import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../Configuration/config";

import { toast } from 'react-toastify';
export const getUsers = createAsyncThunk('getUsers', async (arg, {
  rejectWithValue
}) => {
  console.log('inside getusers')
  try {
    const { data } = await axios.get(`${baseUrl}users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`
      }
    });
    console.log('inside getusers',data,localStorage.getItem("token"))
    return data;
  }
  catch (error) {
    console.log('error in  inside getusers',error)
    return rejectWithValue(error.response.data.message);

  }
});

export const addUser= createAsyncThunk('addUser', async (arg, {
  rejectWithValue
}) => {
  console.log('addUser', arg);
  try {
      const { data } = await axios.post(`${baseUrl}users`,arg,{
          headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`
          }
      });

      console.log('here is add user data',data)
      return data;
  }
  catch (error) {

      return rejectWithValue(error.response.data.message);

  }
});

export const getSingleUser = createAsyncThunk('getSingleUser', async (arg, {
  rejectWithValue
}) => {

  try {
      const { data } = await axios.get(`${baseUrl}users/${arg}`, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`
          }
      });
      console.log('here is single user data ',data)
      return data;
  }
  catch (error) {

      return rejectWithValue(error.response.data.message);

  }
});
export const updateUser = createAsyncThunk('updateUser', async (arg, {
  rejectWithValue
}) => {

  try {
      const { data } = await axios.put(`${baseUrl}users/${arg.slug}`, arg.data, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`
          }
      });
      console.log('here is update user data ',data)
      return data;
  }
  catch (error) {
      console.log('here is update error ',error)
      return rejectWithValue(error.response.data.message);

  }
});

export const deleteUser = createAsyncThunk('deleteUser', async (arg, {
  rejectWithValue
}) => {

  try {
      const { data } = await axios.delete(`${baseUrl}users/${arg}`, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`
          }
      });

      return data;
  }
  catch (error) {

      return rejectWithValue(error.response.data.message);

  }
});


 
export const userSlice = createSlice({
  name: 'users',
  initialState: {
    value: '',
    loading: false,
    message: "",
    isSuccess: false,
    isAuthenticated: false,
    role: null
  },
  reducers: {

  },

extraReducers: (builder) => {
  builder.addCase(getUsers.pending, (state, action) => {
  //   toast.loading('Logging in');
    state.loading = true;
  });
  builder.addCase(getUsers.fulfilled, (state, action) => {
    // toast.dismiss();
    // toast.success('Logged In');
    let users = action.payload.data.filter((item)=>{
      return item.id != localStorage.getItem("user_id")
    })
    state.value = users
    state.loading = false;
  });
  builder.addCase(getUsers.rejected, (state, action) => {
    // toast.dismiss();
    // toast.error('Promise Not Resolved');
    state.loading = false;
    state.message = action.payload;
    state.isSuccess = false;
  });

  //Add user

  builder.addCase(addUser.pending, (state, action) => {
      toast.loading('Adding User');
      state.loading = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      toast.dismiss()
      toast.success('User Added Sucessfully');
      state.value = action.payload.data
      state.loading = false;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      toast.error('Error While Adding User',addUser.rejected);
      state.loading = false;
      state.message = action.payload;
      state.isSuccess = false;
    });

 //Delete user

 builder.addCase(deleteUser.pending, (state, action) => {
  toast.loading('deleting User');
  state.loading = true;
});
builder.addCase(deleteUser.fulfilled, (state, action) => {
  toast.dismiss()
  toast.success('User Deleted Sucessfully');
  state.loading = false;
});
builder.addCase(deleteUser.rejected, (state, action) => {
  toast.error('Error While deleting User');
  state.loading = false;
  state.message = action.payload;
});

builder.addCase(updateUser.pending, (state, action) => {
  toast.loading('Updating User');
  state.loading = true;
});
builder.addCase(updateUser.fulfilled, (state, action) => {
  toast.dismiss()
  toast.success('User Updated Sucessfully');
  state.loading = false;
});
builder.addCase(updateUser.rejected, (state, action) => {
  toast.dismiss();
  toast.error('Error While Updating User');
  state.loading = false;
  state.message = action.payload;
});

  //end



    // builder.addCase(logout.pending, (state, action) => {
    //   toast.loading('Logging Out...');
    //   state.loading = true;
    // });
    // builder.addCase(logout.fulfilled, (state, action) => {
    //   toast.dismiss();

    //   state.loading = false;

    //   state.loading = false;
    //   if (action.payload.message) {
    //     state.isSuccess = false;
    //     state.isAuthenticated = false;
    //   }

    //   localStorage.removeItem("auth");
    //   localStorage.removeItem("role");
    //   toast.success('Logged out');

    // });
    // builder.addCase(logout.rejected, (state, action) => {
    //   toast.dismiss();
    //   toast.error('Promise rejected');
    //   if (action.payload == "Network Error") {
    //     localStorage.removeItem("auth");
    //     localStorage.removeItem("role");

    //     state.loading = false;
    //     state.isSuccess = false;
    //     state.isAuthenticated = false;
    //   } else {
    //     state.loading = false;
    //     state.isSuccess = true;
    //     state.isAuthenticated = true;
    //   }

    // });
  },
});


export default userSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };