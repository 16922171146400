import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../Configuration/config";

import { toast } from 'react-toastify';
 
export const getMachineProducts = createAsyncThunk('getMachineProducts', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}get-machine-products/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });
        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);
    }
});

// export const getMachineAssets = createAsyncThunk('getMachineAssets', async (arg, {
//     rejectWithValue
// }) => {

//     try {
//         const { data } = await axios.get(`${baseUrl}machine-assets/${arg}`, {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("auth")}`
//             }
//         });
//         return data;
//     }
//     catch (error) {

//         return rejectWithValue(error.response.data.message);
//     }
// });


export const machineProductsSlice = createSlice({
    name: 'MachineProducts',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getMachineProducts.pending, (state, action) => {
            //   toast.loading('Adding Machine');
            state.loading = true;
        });
        builder.addCase(getMachineProducts.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Added Machine');
            state.loading = false;
            state.value = action.payload.data;



        });
        builder.addCase(getMachineProducts.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Failed to add machine');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
      
    }
});


export default machineProductsSlice;

 