import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../Configuration/config";

import { toast } from 'react-toastify';
export const getMachineDistributor = createAsyncThunk('getMachineDistributor', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-distributors`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addMachineDistributor= createAsyncThunk('addMachineDistributor', async (arg, {
    rejectWithValue
}) => {
    console.log('addMachineModal', arg);
    try {
        const { data } = await axios.post(`${baseUrl}machine-distributors`,arg,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnMachineDistributor = createAsyncThunk('getAnMachineDistributor', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}machine-distributors/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateMachineDistributor = createAsyncThunk('updateMachineDistributor', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.put(`${baseUrl}machine-distributors/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const deleteMachineDistributor = createAsyncThunk('deleteMachineDistributor ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}machine-distributors/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const machineDistributorSlice = createSlice({
    name: 'MachineDistributor',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getMachineDistributor.pending, (state, action) => {
            //   toast.loading('Logging in');
            state.loading = true;
        });
        builder.addCase(getMachineDistributor.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Logged In');
            state.loading = false;
            state.value = action.payload.data;



        });
        builder.addCase(getMachineDistributor.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Promise Not Resolved');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(addMachineDistributor.pending, (state, action) => {
              toast.loading('Adding Machine Distributor');
            state.loading = true;
        });
        builder.addCase(addMachineDistributor.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Machine Distributor');
            state.loading = false;
            



        });
        builder.addCase(addMachineDistributor.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed Machine Distributor');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(updateMachineDistributor.pending, (state, action) => {
            toast.loading('Updating Machine Distributor');
            state.loading = true;
        });
        builder.addCase(updateMachineDistributor.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Machine Distributor');
            state.loading = false;




        });
        builder.addCase(updateMachineDistributor.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to update Machine Distributor');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(deleteMachineDistributor.pending, (state, action) => {
            toast.loading('Deleting Machine Distributor');
            state.loading = true;
        });
        builder.addCase(deleteMachineDistributor.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Machine Distributor');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(deleteMachineDistributor.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to delete Machine Distributor');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        // builder.addCase(logout.pending, (state, action) => {
        //   toast.loading('Logging Out...');
        //   state.loading = true;
        // });
        // builder.addCase(logout.fulfilled, (state, action) => {
        //   toast.dismiss();

        //   state.loading = false;

        //   state.loading = false;
        //   if (action.payload.message) {
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   }

        //   localStorage.removeItem("auth");
        //   localStorage.removeItem("role");
        //   toast.success('Logged out');

        // });
        // builder.addCase(logout.rejected, (state, action) => {
        //   toast.dismiss();
        //   toast.error('Promise rejected');
        //   if (action.payload == "Network Error") {
        //     localStorage.removeItem("auth");
        //     localStorage.removeItem("role");

        //     state.loading = false;
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   } else {
        //     state.loading = false;
        //     state.isSuccess = true;
        //     state.isAuthenticated = true;
        //   }

        // });
    },
});


export default machineDistributorSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };