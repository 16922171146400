import React from 'react';

import { Navigate, useLocation } from "react-router-dom";
import Navbar from '../shared/Navbar';
import Sidebar from '../shared/Sidebar';
import SettingsPanel from '../shared/SettingsPanel';
import Footer from '../shared/Footer';
import { ToastContainer, toast } from 'react-toastify';
export const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem("auth");

  let location = useLocation().pathname;
  // console.log('here is user inside protected routes', location, user)

  if (!user || user == null) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  return<>
  <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper">
      <Sidebar />
      <div className="main-panel">
        <div className="content-wrapper">
          {children}
          <SettingsPanel />
        </div>
        <Footer />
      </div>
    </div>
    <ToastContainer />
  </div>;
  </> 

};

