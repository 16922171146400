import React, { Component, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import { addProduct } from "../Store/Features/Product/Product"
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom"
import { getAnProduct, updateProduct,uploadImage } from '../Store/Features/Product/Product';
import { getProductCompany } from '../Store/Features/Product/ProductCompany';
import { getProductStrataCodeName } from '../Store/Features/Product/ProductStrataCodeName';
import { getProductFamily } from '../Store/Features/Product/ProductFamily';
import { getProductType } from "../Store/Features/ProductType/ProductType";
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

export const AddProduct = () => {
  const { slug } = useParams()
  const { machineId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const productCompany = useSelector(state => state.productCompany.value)
  const productStrataCodeName = useSelector(state => state.productStrataCodeName.value)
  const productFamily = useSelector(state => state.productFamily.value)
  const ProductType = useSelector(state => state.ProductTypeSlice.value)
  useEffect(() => {
    dispatch(getProductCompany());
    dispatch(getProductStrataCodeName());
    dispatch(getProductFamily());
    dispatch(getProductType());
  }, [])


  //   state = {
  //     startDate: new Date()
  //   };

  // const  handleChange = (date) => {
  //     this.setState({
  //       startDate: date
  //     });
  //   };
  const [manufacturerName, setManufacturerName] = useState("")
  const [manufacturerTitle, setManufacturerTitle] = useState("")
  const [productImage, setProductImage] = useState()
  const [product, setProduct] = useState({
    product_name: "",
    product_code: "",
    product_code_in_machine: "",
    barcode: "",
    product_image: "",
    type_of_product_id: "",
    product_code_company: "",
    units_from_matrix_product: "",
    matrix_product: "",
    distributor_id: "",
    currency_id: "",
    purchase_price: "",
    segment: "",
    retail_price: "",
    rounding_unit: "",
    company_id: "",
    family_id: "",
    strator_code_id: "",
   

  })
  const submitImage = (e) => {
    e.preventDefault()
    handleFile(e)
    let formData = new FormData();
    formData.append("file", e.target.files[0])
    dispatch(uploadImage(formData)).then((data)=>{
      console.log(data.payload)
      setProduct({...product,product_image: data.payload.name})
    })


  }
  const handleFile = (e) => {
    const objectURL = URL.createObjectURL(e.target.files[0]);
    console.log('here is file img url',objectURL)
    setProductImage( objectURL)
  }

  useEffect(() => {
    if (slug) {
      dispatch(getAnProduct(slug)).then((data) => {
        console.log('data', data);
        let tempProduct = {
          product_name: data.payload.data?.product_name,
          product_code: data.payload.data?.product_code,
          product_code_in_machine: data.payload.data?.product_code_in_machine,
          barcode: data.payload.data?.barcode,
          product_image: data.payload.data?.product_image?.file_name,
          type_of_product_id: data.payload.data?.type_of_product_id,
          product_code_company: data.payload.data?.product_code_company,
          units_from_matrix_product: data.payload.data?.units_from_matrix_product,
          matrix_product: data.payload.data?.matrix_product,
          distributor_id: data.payload.data?.distributor_id,
          currency_id: data.payload.data?.currency_id,
          purchase_price: data.payload.data?.purchase_price,
          segment: data.payload.data?.segment,
          retail_price: data.payload.data?.retail_price,
          rounding_unit: data.payload.data?.rounding_unit,
          company_id: data.payload.data?.company?.id,
          family_id: data.payload.data?.family?.id,
          strator_code_id: data.payload.data?.strator_code?.id,
        }
        setProductImage(data.payload.data?.product_image?.original_url)
        setProduct(tempProduct);
    },

  )} },[])



const onSubmit = (e) => {
  e.preventDefault()


  let formData = new FormData();
  product['machine_id']=machineId
  console.log('here is machine id ',product)
  Object.keys(product).forEach((key) => {
    formData.append(key, product[key])
  });
  // dispatch(addManufacturer(data)).then(()=>{
  //   navigate("/machine/manufacturer")
  // })
  if (slug) {
    formData.append('_method','PUT');
    dispatch(updateProduct({ slug, formData:product })).then(() => {
      navigate("/admin/product")
    })
  } else {
    dispatch(addProduct(product)).then((data) => {
      console.log(data.payload)
      navigate("/admin/product")
    })
  }
}


return (
  <div>
    <div className="page-header">
      <h3 className="page-title">Product</h3>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Product</a></li>
          <li className="breadcrumb-item active" aria-current="page">Add Product</li>
        </ol>
      </nav>
    </div>
    <div className="row">
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Add </h4>
            {/* <p className="card-description"> Basic form layout </p> */}
            <form className="forms-sample">
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputUsername1">Product Name</label>
                  <Form.Control type="text" id="exampleInputUsername1" placeholder="Product Name" value={product.product_name} onChange={(e) => setProduct({ ...product, product_name: e.target.value })} />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Product Code</label>
                  <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Product Code" value={product.product_code} onChange={(e) => setProduct({ ...product, product_code: e.target.value })} />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputUsername1">Product Code In Machine</label>
                  <Form.Control type="text" id="exampleInputUsername1" placeholder="Product Code In Machine" value={product.product_code_in_machine} onChange={(e) => setProduct({ ...product, product_code_in_machine: e.target.value })} />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Barcode</label>
                  <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Barcode" value={product.barcode} onChange={(e) => setProduct({ ...product, barcode: e.target.value })} />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                <div className="row">
                <div className="col-sm-6">
                  <label style={{widt:"100%"}} htmlFor="exampleInputUsername1">Product Image </label>
                  <Form.Control type="file" id="exampleInputUsername1" placeholder="Product Image " onChange={(e) => {submitImage(e)}} />
                   </div>
                  <div className="col-sm-6">
                  <img style = {{height:"50px",width:"50px" }} src ={productImage} />
                  </div>
                  </div>
                  
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Type of Product Id </label>
                  {/* <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Type of Type Id" value={product.type_of_product_id} onChange={(e) => setProduct({ ...product, type_of_product_id: e.target.value })} /> */}
                  <select value={product?.type_of_product_id}   name="productType" className="form-control" onChange={(e) => setProduct({ ...product, type_of_product_id: e.target.value })}>
                    <option value={0} >Select the Following Option</option>
                    {ProductType?.map(item => (
                      <option value={item.id}>{item?.title}</option>))}
                  </select>
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputUsername1">Product Code Company</label>
                  <Form.Control type="text" id="exampleInputUsername1" placeholder="Product Code Company" value={product.product_code_company} onChange={(e) => setProduct({ ...product, product_code_company: e.target.value })} />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Matrix Product</label>
                  <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Matrix Product" value={product.matrix_product} onChange={(e) => setProduct({ ...product, matrix_product: e.target.value })} />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputUsername1">Units From Matrix Product</label>
                  <Form.Control type="text" id="exampleInputUsername1" placeholder="Units From Matrix Product" value={product.units_from_matrix_product} onChange={(e) => setProduct({ ...product, units_from_matrix_product: e.target.value })} />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Distributor Id</label>
                  <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Distributor Id" value={product.distributor_id} onChange={(e) => setProduct({ ...product, distributor_id: e.target.value })} />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputUsername1">Currency Id </label>
                  <Form.Control type="text" id="exampleInputUsername1" placeholder="Currency Id" value={product.currency_id} onChange={(e) => setProduct({ ...product, currency_id: e.target.value })} />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Purchase Price</label>
                  <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Purchase Price" value={product.purchase_price} onChange={(e) => setProduct({ ...product, purchase_price: e.target.value })} />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputUsername1">Segment</label>
                  <Form.Control type="text" id="exampleInputUsername1" placeholder="Segment" value={product.segment} onChange={(e) => setProduct({ ...product, segment: e.target.value })} />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Retail Price</label>
                  <Form.Control type="text" className="form-control" id="exampleInputEmail1" placeholder="Retail Price" value={product.retail_price} onChange={(e) => setProduct({ ...product, retail_price: e.target.value })} />
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputUsername1">Rounding Unit</label>
                  <Form.Control type="text" id="exampleInputUsername1" placeholder="Rounding Unit" value={product.rounding_unit} onChange={(e) => setProduct({ ...product, rounding_unit: e.target.value })} />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>

                  <label htmlFor="exampleInputEmail1">Company Id</label>
                  <select type="text" className="form-control" id="exampleInputEmail1" placeholder="Company Id" value={product.company_id} onChange={(e) => setProduct({ ...product, company_id: e.target.value })} >
                  <option value={0}>Select Company Id</option>
                    {productCompany.map(item => {
                      return <option value={item.id}>{item.company_name}</option>
                    })}
                  </select>
                </Form.Group>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputUsername1">Family Id </label>
                  <select className="form-control" type="text" id="exampleInputUsername1" placeholder="Family Id" value={product.family_id} onChange={(e) => setProduct({ ...product, family_id: e.target.value })} >
                  <option value={0}>Select Family Id</option>
                    {productFamily.map(item => {
                      return <option value={item.id}>{item.family_name}</option>
                    })}
                  </select>
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <label htmlFor="exampleInputEmail1">Strator Code Id </label>
                  <select type="text" className="form-control" id="exampleInputEmail1" placeholder="Strator Code Id" value={product.strator_code_id} onChange={(e) => setProduct({ ...product, strator_code_id: e.target.value })}>
                  <option value={0}>Select Strator Code Id</option>
                    {productStrataCodeName.map(item => {
                      return <option value={item.id}>{item.strator_code_name}</option>
                    })}
                  </select>
                </Form.Group>
              </Box>
              <button type="submit" className="btn btn-gradient-primary mr-2" onClick={onSubmit}>Submit</button>
              <button className="btn btn-light" onClick = {()=>navigate("/admin/product")}>Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
)

}

const resolveImahe = (path) => {
  function isValidUrl(path) {
    try {
      new URL(path);
      return path;
    } catch (err) {
      return ;
    }
  }
}