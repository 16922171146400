import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";
import { ToastContainer, toast } from 'react-toastify';


export const getCategories = createAsyncThunk('getCategories', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}asset-categories`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnCategory = createAsyncThunk('getAnCategory', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}asset-categories/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateCategory = createAsyncThunk('updateCategory', async (arg, {
    rejectWithValue
}) => {
    console.log('data',)

    try {
        const { data } = await axios.put(`${baseUrl}asset-categories/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`,
                
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const deleteCategory = createAsyncThunk('deleteCategory ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}asset-categories/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

 
//     rejectWithValue
// }) => {

//     try {
//         const { data } = await axios.post(`${baseUrl}new-products/media`, arg ,{
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("auth")}`
            
//             }
//         });

//         return data;
//     }
//     catch (error) {

//         return rejectWithValue(error.response.data.message);

//     }
// });

export const categorySlice = createSlice({
    name: 'Categories',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {

        builder.addCase(getCategories.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.value = action.payload.data;
        });
        builder.addCase(getCategories.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            
        });


        builder.addCase(updateCategory.pending, (state, action) => {
            toast.loading('Updating Asset Category');
            state.loading = true;
        });
        builder.addCase(updateCategory.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Asset Category Sucessfully');
            state.loading = false;
         });
         builder.addCase(updateCategory.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to Update Asset Category');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(deleteCategory.pending, (state, action) => {
            toast.loading('Deleting Asset Category');
            state.loading = true;
        });
        builder.addCase(deleteCategory.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Category Sucessfully');
            state.loading = false;
         });
         builder.addCase(deleteCategory.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to Delete Asset Category');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
         
        


       
         
         

        
    },
});


export default categorySlice;


 