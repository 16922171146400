import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";
import { ToastContainer, toast } from 'react-toastify';


export const getProductCompany = createAsyncThunk('getProductCompany', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}product-companies`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addProductCompany = createAsyncThunk('addProductCompany', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}product-companies`, arg, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnProductCompany = createAsyncThunk('getAnProductCompany', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}product-companies/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateProductCompany = createAsyncThunk('updateProductCompany', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.put(`${baseUrl}product-companies/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const deleteProductCompany = createAsyncThunk('deleteProductCompany ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}product-companies/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});


export const productCompanySlice = createSlice({
    name: 'Manufacturer',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getProductCompany.pending, (state, action) => {
            //   toast.loading('Logging in');
            state.loading = true;
        });
        builder.addCase(getProductCompany.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Logged In');
            state.loading = false;
            state.value = action.payload.data;



        });
        builder.addCase(getProductCompany.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Promise Not Resolved');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(addProductCompany.pending, (state, action) => {
            toast.loading('Adding Product Company');
            state.loading = true;
        });
        builder.addCase(addProductCompany.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Product Company ');
            state.loading = false;




        });
        builder.addCase(addProductCompany.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to add Product Company');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(updateProductCompany.pending, (state, action) => {
            toast.loading('Updating Product Company');
            state.loading = true;
        });
        builder.addCase(updateProductCompany.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Product Company');
            state.loading = false;
        });
        builder.addCase(updateProductCompany.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to update Product Company');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(deleteProductCompany.pending, (state, action) => {
            toast.loading('Deleting Product Company');
            state.loading = true;
        });
        builder.addCase(deleteProductCompany.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Product Company');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(deleteProductCompany.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to delete Product Company');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });


        // builder.addCase(logout.pending, (state, action) => {
        //   toast.loading('Logging Out...');
        //   state.loading = true;
        // });
        // builder.addCase(logout.fulfilled, (state, action) => {
        //   toast.dismiss();

        //   state.loading = false;

        //   state.loading = false;
        //   if (action.payload.message) {
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   }

        //   localStorage.removeItem("auth");
        //   localStorage.removeItem("role");
        //   toast.success('Logged out');

        // });
        // builder.addCase(logout.rejected, (state, action) => {
        //   toast.dismiss();
        //   toast.error('Promise rejected');
        //   if (action.payload == "Network Error") {
        //     localStorage.removeItem("auth");
        //     localStorage.removeItem("role");

        //     state.loading = false;
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   } else {
        //     state.loading = false;
        //     state.isSuccess = true;
        //     state.isAuthenticated = true;
        //   }

        // });
    },
});


export default productCompanySlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };