import React, { Component, useState ,useEffect} from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import {addMachinePaymentMethod} from "../Store/Features/MachinePaymentMethod"
import {useDispatch} from "react-redux"
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { getAnMachinePaymentMethod,updateMachinePaymentMethod } from '../Store/Features/MachinePaymentMethod';

export const AddMachinePaymentMethod =()=>{
  const dispatch =useDispatch()
  const {slug} = useParams()
  const navigate = useNavigate()
//   state = {
//     startDate: new Date()
//   };
 
// const  handleChange = (date) => {
//     this.setState({
//       startDate: date
//     });
//   };
const [MachinePaymentMethod,setMachinePaymentMethod] = useState("")
useEffect(()=>{
  if(slug) {
    dispatch(getAnMachinePaymentMethod(slug)).then((data)=>{
      setMachinePaymentMethod(data.payload.data.payment_method_name)
    })
  }

},[])

const onSubmit = (e) =>{
  e.preventDefault()
 
  const data = {payment_method_name:  MachinePaymentMethod}
  if(slug) {
    dispatch(updateMachinePaymentMethod({slug,data})).then(()=>{
      navigate("/admin/machine/paymentmethod")
    })
  }else {
    dispatch(addMachinePaymentMethod(data)).then(()=>{
      navigate("/admin/machine/paymentmethod")
    })
  }  
}  
 
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Machine Payment Method</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Machine Payment Method</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Machine Payment Method</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add </h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Payment Method Name</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Payment Method Name"  value = {MachinePaymentMethod} onChange = {e=>setMachinePaymentMethod(e.target.value)} />
                  </Form.Group>
                  
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light" onClick = {()=>navigate("/admin/machine/paymentmethod")}>Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}