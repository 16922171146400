import React, { Component,useEffect,useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import { addProductFamily,updateProductFamily,getAnProductFamily } from '../Store/Features/Product/ProductFamily';
import { addAssetStatus} from '../Store/Features/MachinesAssets/MachinesAssets';
import { updateAssetStatus,getAnStatus} from '../Store/Features/MachinesAssets/AllAssetsStatus';
import { useParams,useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from "react-redux";
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { Category } from '@mui/icons-material';
export const AddAssetStatus =()=>{


const dispatch = useDispatch()
const Navigate = useNavigate()
const {slug} = useParams()
const [status,setStatus] = useState("")
const [apiCall_Loader, setapiCall_Loader] = useState(false);
const assetsStatus = useSelector(state => state.AssetsStatus.value)
const loading = useSelector(state => state.AssetsStatus.loading)

// useEffect(()=>{
//   dispatch(getCategories()).then((resp)=>{
//     console.log('getCategories',resp)
//   })
// },[])


useEffect(()=>{
   console.log('here is upadte assets status data',assetsStatus)
},[assetsStatus])




useEffect(()=>{
  if(slug) {
    setapiCall_Loader(true)
    dispatch(getAnStatus(slug)).then((data)=>{
      setapiCall_Loader(false)
      setStatus(data.payload.data.name)
    })
  }

},[])

const onSubmit = (e) =>{
  e.preventDefault()
 
  const data = {name:status}
  console.log('inside',data)
  if(slug) {
    setapiCall_Loader(true)
    dispatch(updateAssetStatus({slug,data})).then((data)=>{
      setapiCall_Loader(false)
      console.log('updater asset data',data)
      Navigate("/admin/assets/allassetstatus")
    })
  }else {
    setapiCall_Loader(true)
    dispatch(addAssetStatus(data)).then((data)=>{
      setapiCall_Loader(false)
      Navigate("/admin/assets/allassetstatus")
    })
  }
}
   
 
    return (
      <>
       {apiCall_Loader ? (
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    Loading
                    <form className="forms-sample">
                    <Box sx={{height:"20px",textAlign:'center'}} > 
                      <CircularProgress />
                      </Box >
                    </form>
                  </div>
                </div>
              </div>
            </div>
     ):(null)
            }
     
      <div>
        <div className="page-header">
          <h3 className="page-title">Asset Status</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Asset Asset Status</a></li>
              <li className="breadcrumb-item active" aria-current="page">Add Asset Status</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add </h4>
                {/* <p className="card-description"> Basic form layout </p> */}
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Add Asset Status</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Status Name" value = {status} onChange={e=> setStatus(e.target.value)}  />
                  </Form.Group>
                  
              
                  <button type="submit" className="btn btn-gradient-primary mr-2" onClick = {onSubmit}>Submit</button>
                  <button className="btn btn-light">Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  
}