import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";
import { ToastContainer, toast } from 'react-toastify';


export const getProduct = createAsyncThunk('getProduct', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}products`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getMachineProducts = createAsyncThunk('getMachineProducts', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}get-machine-products/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const addProduct = createAsyncThunk('addProduct', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}products`, arg, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnProduct = createAsyncThunk('getAnProduct', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}products/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateProduct = createAsyncThunk('updateProduct', async (arg, {
    rejectWithValue
}) => {
    console.log('data',)

    try {
        const { data } = await axios.put(`${baseUrl}products/${arg.slug}`, arg.formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`,
                
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const deleteProduct = createAsyncThunk('deleteProduct ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}products/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const uploadImage = createAsyncThunk('UploadImage', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}products/media`, arg ,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const productSlice = createSlice({
    name: 'Manufacturer',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getProduct.pending, (state, action) => {
            //   toast.loading('Logging in');
            state.loading = true;
        });
        builder.addCase(getProduct.fulfilled, (state, action) => {
            // toast.dismiss();
            // toast.success('Logged In');
            state.loading = false;
            state.value = action.payload.data;



        });
        builder.addCase(getProduct.rejected, (state, action) => {
            // toast.dismiss();
            // toast.error('Promise Not Resolved');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(addProduct.pending, (state, action) => {
            toast.loading('Adding Product');
            state.loading = true;
        });
        builder.addCase(addProduct.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Product ');
            state.loading = false;




        });
        builder.addCase(addProduct.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to add Product');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(updateProduct.pending, (state, action) => {
            toast.loading('Updating Product');
            state.loading = true;
        });
        builder.addCase(updateProduct.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Product');
            state.loading = false;
        });
        
        builder.addCase(updateProduct.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to update Product');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(deleteProduct.pending, (state, action) => {
            toast.loading('Deleting Product');
            state.loading = true;
        });
        builder.addCase(deleteProduct.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Product');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(deleteProduct.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to delete Product');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        builder.addCase(uploadImage.pending, (state, action) => {
            toast.loading('Uploading Product Image');
            state.loading = true;
        });
        builder.addCase(uploadImage.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Uploaded Product Image');
            state.loading = false;
            // Remove the deleted manufacturer from the state
            state.value = state.value.filter((manufacturer) => manufacturer.id !== action.payload);
        });
        builder.addCase(uploadImage.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Uploading Product Image');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
        

        // builder.addCase(logout.pending, (state, action) => {
        //   toast.loading('Logging Out...');
        //   state.loading = true;
        // });
        // builder.addCase(logout.fulfilled, (state, action) => {
        //   toast.dismiss();

        //   state.loading = false;

        //   state.loading = false;
        //   if (action.payload.message) {
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   }

        //   localStorage.removeItem("auth");
        //   localStorage.removeItem("role");
        //   toast.success('Logged out');

        // });
        // builder.addCase(logout.rejected, (state, action) => {
        //   toast.dismiss();
        //   toast.error('Promise rejected');
        //   if (action.payload == "Network Error") {
        //     localStorage.removeItem("auth");
        //     localStorage.removeItem("role");

        //     state.loading = false;
        //     state.isSuccess = false;
        //     state.isAuthenticated = false;
        //   } else {
        //     state.loading = false;
        //     state.isSuccess = true;
        //     state.isAuthenticated = true;
        //   }

        // });
    },
});


export default productSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };