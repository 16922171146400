import React, {useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {getProduct,deleteProduct} from "../Store/Features/Product/Product"
import {deleteAssets} from '../Store/Features/MachinesAssets/MachinesAssets';
import {getAssetStatuses,deleteAssetsStatus} from '../Store/Features/MachinesAssets/AllAssetsStatus';
import {useDispatch,useSelector} from "react-redux"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { width } from '@mui/system';



function CustomToolbar() {
    return (
      <GridToolbarContainer>
         <h4 className="card-title" style = {{margin: 20}}>All Assets Status</h4>
      </GridToolbarContainer>
    );
  }

const rows = [
  { id: 1, title: 'Snow', manufacturertitle: 'Jon' },
  { id: 2, title: 'Lannister', manufacturertitle: 'Cersei' },
  { id: 3, title: 'Lannister', manufacturertitle: 'Jaime' },
];


export function AllAssetsStatus() {
  const dispatch  = useDispatch()
  const navigate = useNavigate()
  const assetsStatuses = useSelector(state => state.AssetsStatus.value)
  const loading = useSelector(state => state.AssetsStatus.loading)

  useEffect(()=>{
    dispatch(getAssetStatuses()).then((resp)=>{
      console.log('getAssetStatuses',resp)
    })
  },[])


  useEffect(()=>{
     console.log('here is assets data',assetsStatuses)
  },[assetsStatuses])

 

//   const handleActions=(event,row)=>{
//      if(event.target.value=='view'){
//         handleView(row)
//      }else if(event.target.value=='edit'){
//       handleEdit(event,row)
//      }else if(event.target.value=='delete'){
//       handleDelete(row)
//      }else{

//      }
//   }

  const handleView = (row) => {
    console.log('View:', row);
  };

  const handleEdit = (row) => {
    navigate(`/admin/assets/addstatus/${row.id}`)
    console.log('Edit:', row);
  };

  const handleDelete = (row) => {
    dispatch(deleteAssetsStatus(row.id)).then(() => {
      dispatch(getAssetStatuses())
    });
  };

  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
 
 const columns = [
    
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Asset Status', flex: 1  },
     
    {
        field: 'actions',
        headerName: 'Actions',
        flex: 1 ,
        renderCell: (params) => (
          <div>
            <button style={buttonStyle1} onClick={() => handleEdit(params.row)}>Edit</button>
            <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
          </div>
        ),
      },
     
  ];

  return (
    
    <div style={{ height: 400, width: '100%' }}>
        <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> All Assets Status  </h3>

          <button type="button" class="btn btn-gradient-success " onClick = {()=>navigate("/admin/assets/addstatus")}>Add Asset Status</button>
        </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Assets </a></li>
              <li className="breadcrumb-item active" aria-current="page">All Assets Status </li>
            </ol>
          </nav>
        </div>
            <DataGrid
                rows={assetsStatuses}
                columns={columns}
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                sx={{ backgroundColor: "white" }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                slots={{
                    toolbar: CustomToolbar,
                  }}
            />
        </div>
  );
}