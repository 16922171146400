import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { baseUrl } from "../../../Configuration/config";

import { toast } from 'react-toastify';

export const getProductType = createAsyncThunk('getProductType', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}product-types`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
 
export const addProductType = createAsyncThunk('addProductType', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.post(`${baseUrl}product-types`, arg, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const getAnProductType = createAsyncThunk('getAnProductType', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.get(`${baseUrl}product-types/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});
export const updateProductType = createAsyncThunk('updateProductType', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.put(`${baseUrl}product-types/${arg.slug}`, arg.data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const deleteProductType = createAsyncThunk('deleteProductType ', async (arg, {
    rejectWithValue
}) => {

    try {
        const { data } = await axios.delete(`${baseUrl}product-types/${arg}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth")}`
            }
        });

        return data;
    }
    catch (error) {

        return rejectWithValue(error.response.data.message);

    }
});

export const productTypeSlice = createSlice({
    name: 'Machine',
    initialState: {
        value: [],
        loading: false,
        message: "",


    },
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(getProductType.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getProductType.fulfilled, (state, action) => {
            state.loading = false;
            state.value = action.payload.data;
        });
        builder.addCase(getProductType.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
        });

        builder.addCase(addProductType.pending, (state, action) => {
            toast.loading('Adding Product Type');
            state.loading = true;
        });
        builder.addCase(addProductType.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Added Product Type Sucessfully');
            state.loading = false;
         });
         builder.addCase(addProductType.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed while Adding Product Type');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(updateProductType.pending, (state, action) => {
            toast.loading('Updating Product Type');
            state.loading = true;
        });
        builder.addCase(updateProductType.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Updated Product Type Sucessfully');
            state.loading = false;
         });
         builder.addCase(updateProductType.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed while Updating Product Type');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });

        builder.addCase(deleteProductType.pending, (state, action) => {
            toast.loading('Deleting Product Type');
            state.loading = true;
        });
        builder.addCase(deleteProductType.fulfilled, (state, action) => {
            toast.dismiss();
            toast.success('Deleted Product Type Sucessfully');
            state.loading = false;
         });
         builder.addCase(deleteProductType.rejected, (state, action) => {
            toast.dismiss();
            toast.error('Failed to Delete Product Type');
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = false;
        });
       
    },
});


export default productTypeSlice;


// const checkAdminRoles = (roles) => {
//   const checkAdmin = roles.some(item => {
//     return item.id == 1;
//   });
//   if (checkAdmin) {
//     return 1;
//   }
//   else {
//     return roles[0].id;
//   }
// };