import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { getUsers , deleteUser , getSingleUser } from "../Store/Features/UsersSlice";
import {Typography} from "@mui/material"
import { useParams } from "react-router-dom";
import { getOrders,deleteOrder } from "../Store/Features/Order/Order";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h4 className="card-title" style={{ margin: 20 }}>All Orders </h4>
    </GridToolbarContainer>
  );
}

const rows = [
  { id: 1, name: 'Snow', email: 'Jon' },
];
export const AllOrders = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { slug } = useParams();
  const Orders = useSelector(state => state.OrderSlice.value)
  const Loading = useSelector(state => state.OrderSlice.loading)

  useEffect(() => {
    dispatch(getOrders())
  }, [])
  
  useEffect(() => {
     console.log('all orders data',Orders)
  }, [])

  const handleDelete = (row) => {
    dispatch(deleteOrder(row.id)).then(() => {
       dispatch(getOrders())
    });
  }

    const buttonStyle2 = {
      marginRight: '5px',
      padding: '5px 10px',
      backgroundColor: '#DC3545',
      color: 'white',
      border: 'none',
      borderRadius: '3px',
      cursor: 'pointer',
    };

  const columns = [
    { field: 'id', headerName: 'Order-ID', flex: 1 },
    { field: 'machine_id', headerName: 'Machine-ID', flex: 1 },
    { field: 'total', headerName: 'Total Payment', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <div>
          <button style={buttonStyle2} onClick={() => handleDelete(params.row)}>Delete</button>
        </div>
      ),
    },
  ];
  
  return (

    <div style={{ height: 400, width: '100%' }}>
      <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title"> Orders  </h3>
          <button type="button" className="btn btn-gradient-success " onClick={() => navigate("/admin/addorder")}>Add Order</button>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Orders </a></li>
            <li className="breadcrumb-item active" aria-current="page">All Orders </li>
          </ol>
        </nav>
      </div>
      
             <DataGrid
             rows={Orders}
             columns={columns}
             loading = {Loading}
             initialState={{
               pagination: {
                 paginationModel: { page: 0, pageSize: 20 },
               },
             }}
             sx={{ backgroundColor: "white" }}
             checkboxSelection
             slots={{
               toolbar: CustomToolbar,
             }}
           />
    </div>
  );
 
}



