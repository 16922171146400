import React, {useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {getProduct,deleteProduct} from "../Store/Features/Product/Product"
import {getAnMachine} from "../Store/Features/Machine"
import {useDispatch,useSelector} from "react-redux"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useParams } from "react-router-dom";
import Select from '@mui/material/Select';
import { getMachineProducts} from "../Store/Features/Product/Product";

import { width } from '@mui/system';


function CustomToolbar() {
    return (
      <GridToolbarContainer>
         <h4 className="card-title" style = {{margin: 20}}>Machine Stock</h4>
      </GridToolbarContainer>
    );
  }
 
export function ViewSingleMachineProducts() {
  const dispatch  = useDispatch()
  const navigate = useNavigate()
  const { slug } = useParams();
  const product = useSelector(state => state.product.value)
  const Machine = useSelector(state => state.Machine.value)

  useEffect(()=>{
    dispatch(getMachineProducts(slug))
  },[])

  useEffect(()=>{
    console.log('here is view product data ',product)
  },[])

 

  const handleActions=(event,row)=>{
     if(event.target.value=='view'){
        handleView(row)
     }else if(event.target.value=='edit'){
      handleEdit(event,row)
     }else if(event.target.value=='delete'){
      handleDelete(row)
     }else{

     }
  }

  const handleView = (row) => {
    console.log('View:', row);
  };

  const handleEdit = (e,row) => {
    console.log('handle click event:', e.target.value,row.id);
    navigate(`/admin/product/edit/${row.id}`)
    console.log('Edit:', row);
  };

  const handleDelete = (row) => {
    dispatch(deleteProduct(row.id)).then(() => {
      dispatch(getProduct())
    });
  };

//   const handleChangeQuanity = (e,id) => {
//     let temp = [...selected]
//     const tempStock = [...stock];

//     const i = selected.findIndex(item => item.product_id == id)
//     temp[i].quantity = e;
//     tempStock[i] = e;
//  //    temp[i].total_price = temp[i].price * e;
//     setSelected(temp);
//  //    let total=0
//  //    selected.map((item) => {total = parseFloat(total) + parseFloat(item.total_price)});
//  //    setTotalPayment(total)
// }

  const buttonStyle = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle1 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#17A2B8',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const buttonStyle2 = {
    marginRight: '5px',
    padding: '5px 10px',
    backgroundColor: '#DC3545',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
 
 const columns = [
    
    { field: 'product_image', headerName: 'Product Image',flex: 1,renderCell: (params) => {
     return( <a style= {{cursor:"pointer"}} href = {params.row.product_image?.original_url} target = "_blank"  >
      <img style = {{height:"100px",width:"100px"}} src={params.row.product_image?.original_url} /> </a>)
    }   },
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'product_name', headerName: 'Product Name', flex: 1  },
    { field: 'purchase_price', headerName: 'Purchase Price', flex: 1  },
    { field: 'retail_price', headerName: 'Sale Price', flex: 1  }
    
  ];

  return (
    
    <div style={{ height: 400, width: '100%' }}>
        <div className="page-header">
        <div className="flex-row-10">
          <h3 className="page-title">Machine Stock  </h3>
        </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Products </a></li>
              <li className="breadcrumb-item active" aria-current="page">Machine Stock </li>
            </ol>
          </nav>
        </div>
            <DataGrid
                rows={product}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                sx={{ backgroundColor: "white" }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                slots={{
                    toolbar: CustomToolbar,
                  }}
            />
        </div>
  );
}